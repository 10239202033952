import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {getDeltaresUserDetails} from "../Api/ProjectRequests";
import {CancelToken} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import {useCacheState} from 'ibis-design-system/lib/Providers/HttpCacheProvider';
import {request} from "../Utils/request";
import {IUserDto} from "../Api/Dtos/IUserDto";
import PersonaWithCard from "ibis-design-system/lib/components/core/PersonaWithCard";
import {IBaseProps} from "../Interfaces/IBaseProps";
import {changePermissionForPersonOnEntity, getPermissionForPersonOnEntity} from "../Api/TenderRequests";
import {useUserPermissions} from "ibis-design-system/lib/Providers/LoginProvider";
import {PERMISSIONS} from "../Constants";

interface IPersonCardProps extends IBaseProps {
    contactPersonId: string;
    userId: string;
    tenderId: string;
    entityType: string;
    deletePerson?: (contactPersonId: string, personId: string) => void;
    userContext: any;
    showOptions?: boolean;
    functionDescription?: string;
}

export const PersonCard: React.FunctionComponent<IPersonCardProps> = (props) => {

    const permissions = useUserPermissions(); // Comes from the shared ibis-design-system -> loginProvider. See Chat page for more details.
    const cache = useCacheState();
    const [user, setUser] = useState<IUserDto>();

    useEffect(() => {
        fetchUser();
    }, []);

    const fetchUser = async (): Promise<void> => {
        const source = CancelToken.source();
        const cancelToken = source.token;
        const {result, error} = await request<IUserDto>(getDeltaresUserDetails(props.userId, cancelToken, cache));

        if (error || !result) {
            console.log(`Something went wrong trying to fetch user details for user with id: ${props.userId}`);
            return;
        }
        setUser(result);
    }

    const getCurrentPermissionCallback = useCallback(() => {
        return getPermissionForPersonOnEntity(props.tenderId, props.entityType, permissions.userId);
    }, [props.tenderId]);

    const changePermissionForPersonOnEntityCallback = useCallback((permissionKey) => {
            changePermissionForPersonOnEntity(props.tenderId, props.entityType, permissions.userId, permissionKey);
        },
        [props.tenderId]
    );

    return (
        <div style={props.style} className={props.className}>
            <PersonaWithCard
                text={user?.name}
                secondaryText={user?.jobTitle}
                imageUrl={user?.photo}
                imageInitials={user && user?.givenName.substr(0, 1) + user?.surName.substr(0, 1)}
                moreOptions={
                    props.deletePerson !== undefined && user?.id && props.showOptions ?
                        [
                            {
                                key: 'delete',
                                text: 'Delete person',
                                onClick: () => props.deletePerson ? props.deletePerson(props.contactPersonId, user.id) : undefined,
                            },
                        ]
                        :
                        null
                }
                primaryEmail={user?.email}
                primaryPhone={user?.mobile}
                otherFunctionDescription={props.functionDescription}
                contactInformation={[
                    {accountType: 'Mobile', value: user?.mobile},
                    {accountType: 'Phone', value: user?.phone},
                ]}
                permissions={
                    props.userContext?.permissions?.includes('set-permissions') && props.showOptions ?
                        {
                            label: 'Permission',
                            options: PERMISSIONS,
                            getCurrentPermission: getCurrentPermissionCallback,
                            onChanged: (change: any) =>
                                changePermissionForPersonOnEntityCallback(change?.key),
                        }
                        : null
                }           
            />         
        </div>
        
    );
};