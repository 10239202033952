import React, {useState} from "react";
import { GreyBox } from "../../GreyBox";
import Dropdown from 'ibis-design-system/lib/components/inputFields/Dropdown';
import TextInput from 'ibis-design-system/lib/components/inputFields/TextInput';
import Flex from 'ibis-design-system/lib/components/core/Flex';
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import Button from "ibis-design-system/lib/components/core/Button";
import {IMinimalTender, TenderPicker} from "./TenderPicker";
import {IConnectionCreateDto} from "../../../Api/Dtos/IConnectionCreateDto";
import {IConnectionDto} from "../../../Api/Dtos/IConnectionDto";
import {request} from "../../../Utils/request";
import {createConnectionData, getMinimalTender} from "../../../Api/TenderRequests";
import {useTenderConnectionType} from "../../../Hooks/GlobalConfigContext";

// TODO move this to the settings app, from where it can be fetched and exposed using React Context.

const Sdiv = styled.span`
  width: 123px;
`
const Mdiv = styled.span`
  width: 216px;
  margin-left: 20px;
`
const Ldiv = styled.span`
  width: 320px;
  margin-left: 20px;
`

const INIT_CONNECTION: IConnectionCreateDto ={
    id: "",
    tenderId: "",
    note: "",
}

interface IOption {key: string; text: string; title: string; disabled: boolean; value: any}

interface IAddConnectionProps {
    tenderId: string;
    // Triggered when a user clicks cancel. Used to close the form.
    cancel: () => void;
    // Triggered when a new connection has been created. Used to close the form.
    onAdded: () => void;
}

export const AddConnection: React.FunctionComponent<IAddConnectionProps> = (props) => {

    const tenderConnectionTypes = useTenderConnectionType();
    const [newConnection, setNewConnection] = useState<IConnectionCreateDto>(INIT_CONNECTION);

    const findChanged = (changed: IOption): void => {
        const tmpConnection = {...newConnection};
        tmpConnection.type = tenderConnectionTypes.find( t => t.id === changed.key);
        return setNewConnection(tmpConnection);
    }

    const updateLinkedTenders = (tenders: string[] | undefined) => {
        const tmpNewConnection = {...newConnection};
        if (!tenders || !tenders.length) {
            tmpNewConnection.id = "";
        } else {
            tmpNewConnection.id = tenders[0];
        }
        setNewConnection(tmpNewConnection);
    };

    const updateNote = (value: string): void => {
        const tmpNewConnection = {...newConnection};
        tmpNewConnection.note = value;
        setNewConnection(tmpNewConnection);
    }

    // Placeholder until the real request replaces it.
    const getTenders = async (searchInput: string): Promise<IMinimalTender[]> => {

        if (!searchInput) { return []; }

        const {result, error} = await request<{result: IMinimalTender[]}>(getMinimalTender(searchInput, {take: 7, skip: 0}));

        if (!result || !result.result || error) {
            console.log("An error occurred attempting to fetch minimal tenders");
            return [];
        }

        return result.result;
    };

    const createNewConnection = async (): Promise<void> => {
        if (!newConnection.id || !newConnection.type || !newConnection.type.label) { return; }

        const tmpNewConnecion = {...newConnection};
        tmpNewConnecion.tenderId = props.tenderId;
        const {result, error} = await request<IConnectionDto | undefined>(createConnectionData(props.tenderId, tmpNewConnecion));

        if (!result || error) {
            console.error(`Something went wrong attempting to create a new Connection for tender with ID ${newConnection.tenderId}`);
            return;
        }

        props.onAdded();
    };

    return (
        <GreyBox>
            <Flex>
                <Sdiv>
                    <Dropdown
                        label="Type"
                        options={tenderConnectionTypes}
                        onChanged={ (change: IOption) => findChanged(change)}
                        multiSelect={false}
                        selected={[newConnection.type ? newConnection.type.id : ""]}
                    />
                </Sdiv>
                <Mdiv>
                    <TenderPicker
                        label="Tender"
                        fetchTenders={getTenders}
                        onChange={(tenders: string[] | undefined) => updateLinkedTenders(tenders)}
                    />
                </Mdiv>
                <Ldiv>
                    <TextInput
                        label="Note"
                        placeholder="Relational note"
                        onChange={(e: string) => updateNote(e)}
                    />
                </Ldiv>
            </Flex>

            <Flex mt={3} mb={1}>
                <Button variant="primary" onClick={() => createNewConnection()}>
                    Add connection
                </Button>
                <span style={{marginLeft: 8}}><Button onClick={props.cancel}>Cancel</Button></span>
            </Flex>
        </GreyBox>
    );
};
