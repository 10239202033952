import React, { useState } from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import Flex from 'ibis-design-system/lib/components/core/Flex';
import {IFoundation} from "../../../Interfaces/IFoundation";
import { IContextualMenuProps } from "office-ui-fabric-react/lib/components/ContextualMenu";
import NumberInput from "ibis-design-system/lib/components/core/TextField";
import {isNumber} from "../../../Utils/isNumber";
import { IProject,  ProjectBudgetsDeltares } from "../../../Api/Dtos/IProjectDto";
import Settings from "../../../Settings";
import {HttpPut} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import { formatBudgetWithCommas } from "../../../Utils/formatBudgetWithCommas";
import { useCurrencies } from "../../../Hooks/GlobalConfigContext";

const Card = styled.div`
    border-top: ${(props: IFoundation) => `1px solid ${props.theme.colors.neutralQuaternaryAlt}`};
    padding: 0 10px 0 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;
const Percentage = styled.div`
  color: #00000054;
  font-size: ${(props: IFoundation) => props.theme.fontSizes.xxs};
`
const Text = styled.div``;


interface IParticipantCardProps extends IBaseProps {
    project: IProject;
    setProject: Function;
    label: string;
    currency: string;
    currencyAmount?: string;
    personAmount: number;
    currencyPercentage?: number;
    menuProps?: IContextualMenuProps;
    IBudgetTotal?: ProjectBudgetsDeltares;
    updatePartyTotals?: (newTotals: ProjectBudgetsDeltares) => void;
    conversionData: any;
    selectedCurrencyLabel:any;
}


export const TotalCardAssociate: React.FunctionComponent<IParticipantCardProps> = (props) => {
    const [budgetAssociates, setBudgetAssociates] = useState<ProjectBudgetsDeltares>();

    async function updateProjectBudgetTotalFunction(projectId: string, valueEUR: number, persons: number, id: string) {
        try {
          const response = await HttpPut(`${Settings.apiUrls.projects}/projects/BudgetDeltares/${projectId}`, {
            id: id,
            valueEUR: valueEUR,
            persons: persons,
          });
        } catch (error) {
          console.error(error);
        }
      }
    
    const handleChange = (value: string) => {
        if (isNumber(value)) {
            setBudgetAssociates({ ...budgetAssociates!, valueEUR: parseFloat(value)});
        }
    }  
      
    const handleChangePersons = (Persons: string) => {
            if (isNumber(Persons)) {
                setBudgetAssociates({ ...budgetAssociates!, persons: parseFloat(Persons)});
            }  
    };

    const handleUpdate = () => {
        updateProjectBudgetTotalFunction(props.project.id, budgetAssociates!.valueEUR, budgetAssociates!.persons, budgetAssociates!.id)
        .then(() => {
            setBudgetAssociates(undefined);
            props.updatePartyTotals!(budgetAssociates!);
        });
    };
    const currencies = useCurrencies();

    const getSelectedCurrencyLabel = () => {
        if (!props.project || !props.project.currency) return "-";
        const cu = currencies.find( c => c.id === props.project.currency);
        if (cu) return cu.isO4217;
        return "-";
    };
    const selectedCurrencyLabel = getSelectedCurrencyLabel();

    const projectBudgetTotalValueEUR = props.project.projectBudgetTotal ? props.project.projectBudgetTotal.valueEUR : 0;
    const projectBudgetsDeltaresValueEUR = props.project.projectBudgetsDeltares ? props.project.projectBudgetsDeltares.valueEUR : 0;
    const TotalAssociate = projectBudgetTotalValueEUR - projectBudgetsDeltaresValueEUR;
    
    const exchangeRate = props.conversionData ? props.conversionData.inverseExchangeRate : null;
    const conversionTotal = exchangeRate === null ? 0 : exchangeRate;
    const totalAmount = TotalAssociate * conversionTotal;
    
    return (
        <Card style={{position: "relative", ...props.style}} className={props.className}>
            <Flex>
                <Flex style={{flex: 2, alignItems: "center"}}>
                    <Label>{props.label}</Label>
                </Flex>
                <Flex style={{flex: 2, alignSelf: "flex-end", justifyContent: "flex-end", alignItems: "center", height: 32}}>
                    <Flex style={{flex: 1}}>
                        <Flex style={{flex: 1, alignItems: "center"}}>
                            <div>
                                <div style={{width: 131}}>

                                        <div style={{display: "flex", marginLeft: 3}}>
                                            <Text>{props.currency}</Text>
                                            {budgetAssociates?
                                        <NumberInput
                                            placeholder="amount"
                                            value={budgetAssociates ? formatBudgetWithCommas(budgetAssociates.valueEUR) : ""}
                                            onChange={(value: any) => handleChange(value!)}
                                            onBlur={handleUpdate}
                                        />
                                        :
                                        <Text style={{marginLeft: 27}}>{props.currencyAmount }</Text>
                                        }
                                        </div>
                                </div>
                            </div>
                            { props.currencyPercentage !== undefined && <Percentage style={{marginLeft: '10%'}}>{props.currencyPercentage.toFixed(2)} %</Percentage> }
                        </Flex>
                    </Flex>
                    { String(props.selectedCurrencyLabel).valueOf() !== "EUR" && String(props.selectedCurrencyLabel).valueOf() !== '-' && props.selectedCurrencyLabel !== undefined ?
                    <Flex style={{flex: 1}}>
                        <Flex style={{flex: 1, alignItems: "center"}}>
                            <div>
                                <div style={{width: 131}}>

                                        <div style={{display: "flex", marginLeft: 3}}>
                                            <Text>{props.selectedCurrencyLabel}</Text>
                                            {budgetAssociates?
                                        <NumberInput
                                            placeholder="amount"
                                            value={budgetAssociates ? formatBudgetWithCommas(budgetAssociates.valueEUR) : ""}
                                        />
                                        :
                                        <Text style={{marginLeft: 27}}>{totalAmount.toFixed(2)}</Text>
                                        }
                                        </div>
                                </div>
                            </div>
                        </Flex>
                    </Flex>
                    : <></>}
                    <Flex ml={3} mr={3} stye={{flex: 1}}>
                        <div>
                            <div style={{width: 131}}>
                                <div style={{display: "flex", marginLeft: 3, textAlign: "right"}}>
                                    <Text style={{
                                        textAlign: "right",
                                        width: "100%",
                                        marginRight: 38
                                    }}>{budgetAssociates?
                                        <NumberInput
                                            placeholder="amount"
                                            value={budgetAssociates ? budgetAssociates.persons.toString() : ""}
                                            onChange={(value: any) => handleChangePersons(value!)}
                                            onBlur={handleUpdate}
                                        />
                                        :
                                        <Text style={{marginLeft: 27}}>{props.personAmount}</Text>
                                        }
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    );
};