import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IFoundation} from "../Interfaces/IFoundation";
import React, {FunctionComponent, useMemo} from "react";
import {ITenderOverviewDto} from "../Api/Dtos/ITenderOverviewDto";
import {getLinkedProject} from "../Utils/getLinkedProjects";

export const LoisCode = styled.span`
  color: ${(props: IFoundation) => props.theme.colors.themePrimary};
`

interface ITenderTitleProps {
    item: ITenderOverviewDto
}

export const TenderTitle: FunctionComponent<ITenderTitleProps> = (props) => {

    const isProject = props.item.linkedTender !== undefined;

    const tenderTitle = useMemo(() => {
        if (props.item.assignmentTitle) return props.item.assignmentTitle;
        if (props.item.linkedProject && props.item.linkedProject.projectTitle) return props.item.linkedProject.projectTitle
    },[props.item]);

    if (isProject) {
        const project = getLinkedProject(props.item);

        return (
            <div>
                <LoisCode>{project?.maconomyId}</LoisCode>: <span>{project?.projectTitle}</span>
            </div>
        )
    }

    return (
        <div>
            <LoisCode>{props.item.loisCode}</LoisCode>: <span>{tenderTitle}</span>
        </div>
    );
}