/**
 * Returns true if passed value is valid JSON.
 * @param value - any
 */
export const isJson = (value: any): boolean => {
    try {
        const json = JSON.parse(value);
        return true;
    } catch (e) {
        return false;
    }
};
