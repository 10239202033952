import React, {useEffect, useState} from "react";
import {request} from "../../Utils/request";
import {getCompany} from "../../Api/TenderRequests";
import {ICompanyDto} from "../../Api/Dtos/ICompanyDto";
import PersonaWithCard from "ibis-design-system/lib/components/core/PersonaWithCard";

interface IFinancierOverviewProps {
    companyId: string;
    financier?: boolean;
}

export const PartyOverview: React.FunctionComponent<IFinancierOverviewProps> = (props) => {

    const [company, setCompany] = useState<ICompanyDto>();

    useEffect(() => {
        fetchCompany(props.companyId);
    }, [props.companyId]);

    const fetchCompany = async (companyId: string) => {
        const {result, error} = await request(getCompany(companyId));

        if (error) return;

        setCompany(result);
    }

    if (!company) return <div />;

    let secondaryText;
    if (props.financier) {
        secondaryText = company.addresses && company.addresses[0] && company.addresses[0].country
    } else {
        secondaryText = ""
    }

    return (
        <PersonaWithCard
            a
            text={company.name}
            secondaryText={secondaryText}
            imageInitials={company.name && company.name.length > 0 && company.name.substr(0, 1)}
            style={{paddingBottom: '10px'}}
        />
    );
};
