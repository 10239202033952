import React, {useMemo} from "react";
import {IPartyDto} from "../../../Api/Dtos/IPartyDto";
import LatestActivities from "./LatestActivities";
import {getLinkedProject} from "../../../Utils/getLinkedProjects";
import {getFinanciersFromProject} from "../../../Utils/getFinanciersFromProject";

/**
 * This component implements the Activity component as copied from the Company Client application on 19-01-2022.
 */
export const ActivityRoot: React.FunctionComponent<any> = (props) => {

    const financier: IPartyDto | undefined = useMemo(() => {
        const project = getLinkedProject(props.item);
        const financiers = getFinanciersFromProject(project);
        if (financiers && financiers.length) {
            return financiers[0];
        }
        return undefined;
    }, [props.item]);

    if (!financier) {
        return <div>Please add a financier on the budget tab to see it's latest activities.</div>
    }

    return (
        <LatestActivities id={financier.companyId} {...props} />
    );
};
