
export interface ILeadType {
    key: number;
    text: string;
    abbreviation: string;
}

export const getLeadTypes = (): ILeadType[]  => {
    return [
        { key: 2, text: 'Biodata Technical Proposal', abbreviation: "BTP" },
        { key: 1, text: 'Simplified Technical Proposal', abbreviation: "STP" },
        { key: 0, text: 'Full Technical Proposal', abbreviation: "FTP" },
    ]
}