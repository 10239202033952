import React, { useEffect, useState } from "react";
import Settings from '../Settings';
import {getUsers} from "../Misc";
import {useCacheState} from "ibis-design-system/lib/Providers/HttpCacheProvider";
import { HttpPost } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import { MessagesContainer } from 'ibis-design-system/lib/components/patterns/Messages';
import { UserContext } from 'ibis-design-system/lib/Providers/LoginProvider';
import {isAdmin, sendLog} from "../Api/ProjectRequests";

interface IChatProps {
    id: string;
    title: string;
    setMessage: any;
    userContext: any;
}

export const Chat: React.FunctionComponent<IChatProps> = (props) => {
    const [isAdminUser, setIsAdminUser] = useState(false);
    useEffect(() => {
        async function getAdminInfo() {
            try {
                const result = await isAdmin();
                // Accessing properties of the result object
                const permissionRole = result.permissionRole;

                // Use the retrieved data
                if(permissionRole === "full-admin"){
                    setIsAdminUser(true);
                }
            } catch (error) {
                // Handle errors if the Promise rejects
                console.error('Error:', error);
                // If an error occurs, assume user is not an admin
                setIsAdminUser(false);
            }
        }

        getAdminInfo();
    }, []); 

    // The null check is to fix the bug where the userContext array is modified to a null value.
    const isContributor = props.userContext?.permissions?.includes('contribute') === true || props.userContext?.permissions === null;
    
    const httpCache = useCacheState();

    function notifyMessage() {
        // Notify the notification service that a Entity is created
        const notification = {
            entityId: props.id,
            action: 0,
            modifiedField: 'messages',
            message: `A new message has been added to ${props.title}`,
        };
        // Just send it and then ignore it. Nothing happens when it completes and nothing happens when it fails
        HttpPost(`${Settings.apiUrls.cci}/companies/notifications`, notification);
    }
    return (
        <UserContext.Consumer>
            {(user: any) => {
                return (
                <MessagesContainer
                    isAdmin={isAdminUser}
                    itemId={props.id}
                    itemTitle={props.title}
                    userId={user.user.id}
                    // prettier-ignore
                    url={`${Settings.apiUrls.tenders}/tenders/${props.id}/messages`}
                    usersBaseUrl={Settings.apiUrls.singleSignOn}
                    getUsers={(distinctUsers: any) => getUsers(distinctUsers, httpCache)}
                    onSuccessfullySend={(response: any) => {
                        props.setMessage('success', response, 2);
                        notifyMessage();
                    }}
                    onError={(error: any) => {
                        props.setMessage('error', error);
                    }}
                    onTimeout={(error: any, url: any) => {
                        props.setMessage(
                            'error',
                            `Couldn't reach the server. The developers have been notified.`
                        );
                        sendLog(
                            JSON.stringify(error),
                            `Server timeout on url: "${url}"`
                        );
                    }}
                    onUnknownError={(error: any, url: any) => {
                        props.setMessage(
                            'error',
                            `An unknown error occurred... Our developers have been notified`
                        );
                        sendLog(
                            JSON.stringify(error),
                            `Unknown error on url: "${url}"`
                        );
                    }}
                    disabled={!isContributor}
                />
                )}}
        </UserContext.Consumer>
    );
};
