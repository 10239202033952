import React, {useMemo} from "react";
import {
    IStyleFunctionOrObject,
    ITextFieldStyleProps,
    ITextFieldStyles,
    Label,
    TextField
} from "office-ui-fabric-react";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {ProjectMergeTitle} from "./ProjectMergeTitle";
import {ListMergeView} from "./ListMergeView";
import {IMergeProjectDto} from "../../Api/Dtos/IMergeProjectsDto";
import {ProjectMergeLocations} from "./ProjectMergeLocations";
import {currencyToString} from "../../Utils/currencyToString";

const Block = styled.div`
  width: 465px;
  padding: 15px;
  margin-top: 48px;
`

interface ISelectedValuesProps {
    selectedValues: IMergeProjectDto;
    clientAvailable: boolean;
    longestProjectTitle: string;
}

export const SelectedValues: React.FunctionComponent<ISelectedValuesProps> = (props) => {

    const keywords = useMemo<string[]>(() => {
        if (!props.selectedValues || !props.selectedValues.keywords) { return []; }
        return props.selectedValues.keywords.map( keyword => keyword.label);
    }, [props.selectedValues]);

    const units = useMemo<string[]>(() => {
        if (!props.selectedValues || !props.selectedValues.units) { return []; }
        return props.selectedValues.units.map( unit => unit.abbreviation + " - " + unit.name);
    }, [props.selectedValues]);

    const departments = useMemo<string[]>(() => {
        if (!props.selectedValues || !props.selectedValues.departments) { return []; }
        return props.selectedValues.departments.map( department => department.abbreviation + " - " + department.name);
    }, [props.selectedValues]);

    const programs = useMemo<string[]>(() => {
        if (!props.selectedValues || !props.selectedValues.program) { return []; }
        return props.selectedValues.program.map( program => program.label);
    }, [props.selectedValues]);

    const TextFieldStyles = useMemo<IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>>(() => {
        return {field: {color: "black"}, root: {marginTop: 13}};
    }, [props.selectedValues])

    const labelRender = (text: string): JSX.Element => {
        return <Label style={{color: "black"}}>{text}</Label>
    }

    return (
        <Block>
            <div style={{position: "relative"}}>
                <ProjectMergeTitle style={{color: "white"}} selected>{props.longestProjectTitle}</ProjectMergeTitle>
                <ProjectMergeTitle style={{position: "absolute", top: 0}} selected>{props.selectedValues.projectTitle}</ProjectMergeTitle>
            </div>
            <TextField borderless disabled placeholder="Select start date" value={props.selectedValues.startDate ? props.selectedValues.startDate : ''} styles={TextFieldStyles} onRenderLabel={() => labelRender("Start date")} />
            <TextField borderless disabled placeholder="Select an end date" value={props.selectedValues.endDate ? props.selectedValues.endDate : ''} styles={TextFieldStyles} onRenderLabel={() => labelRender("End date")}/>
            <TextField borderless disabled placeholder="Select a deadline" value={props.selectedValues.projectDeadline ? props.selectedValues.projectDeadline : ''} styles={TextFieldStyles} onRenderLabel={() => labelRender("Deadline")} />
            <TextField borderless disabled placeholder="Select a currency" value={currencyToString(props.selectedValues.currency)} styles={TextFieldStyles} onRenderLabel={() => labelRender("Currency")} />
            <TextField
                borderless
                disabled
                placeholder={props.clientAvailable ? "Select a client" : "No clients available for merge."}
                value={props.selectedValues.client?.name ? props.selectedValues.client?.name : ''}
                styles={TextFieldStyles}
                onRenderLabel={() => labelRender("Client")}
            />

            <div style={{marginTop: 13}}>
                <ListMergeView result label="Keywords" values={keywords} />
                <ListMergeView result label="Units" values={units} />
                <ListMergeView result label="Departments" values={departments} />
                <ListMergeView result label="Programs" values={programs} />
                <ProjectMergeLocations result locations={props.selectedValues.location} />
            </div>
        </Block>
    );
};
