import React from "react";
import {ILocationDto} from "../../Api/Dtos/ILocationDto";
import {SemiBold} from "../SemiBold";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import Flex from "ibis-design-system/lib/components/Atoms/Flex";
import {Checkbox} from "office-ui-fabric-react";
import {IFoundation} from "../../Interfaces/IFoundation";

export const LocationCard = styled.div`
  background: ${(props: IFoundation) => props.theme.colors.white};
  padding: 10px 32px;
`

interface IProjectMergeLocationsProps {
    locations: ILocationDto[] | undefined;
    // If true, the result view is activated. The TextField looks different and the checkbox isn't visible.
    result?: boolean;
}

export const ProjectMergeLocations: React.FunctionComponent<IProjectMergeLocationsProps> = (props) => {
    return (
        <Flex>
            {!props.result && <Checkbox checked={props.locations !== undefined} disabled={props.locations === undefined} styles={{root: {marginTop: 4, marginBottom: 6}}} /> }
            <div style={{width: "100%"}}>
                <SemiBold style={{marginBottom: 8}}>
                    Locations
                </SemiBold>
                { !props.locations ? props.result ? <div>No locations available for merge.</div> : null
                    :
                    props.locations.map( (location: ILocationDto, index: number) => {
                        return (
                            <LocationCard style={{marginTop: index > 0 ? 2 : 0}}>
                                <div>{location.city}</div>
                                <div>{location.country}</div>
                                <div>{location.region}</div>
                            </LocationCard>
                        )
                    })
                }
            </div>
        </Flex>
    );
};
