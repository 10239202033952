import {
    HttpGet,
    HttpPost,
    HttpPut,
    HttpDelete,
    CancelToken,
    HttpPatch,
} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

import Settings from '../Settings';
import {mapEntityTypeToCollectionType} from "../Constants";

export { CancelToken };

function notifyCompanyAdd(id, message) {
    const notification = {
        entityId: id,
        action: 0,
        message: message,
    };
    // Just send it and then ignore it. Nothing happens when it completes and nothing happens when it fails
    HttpPost(`${Settings.apiUrls.cci}/companies/notifications`, notification);
}

export async function postTask(companyId, data) {
    console.log("Posting task");
    console.log(data);
    return HttpPost(`${Settings.apiUrls.cci}/companies/${companyId}/tasks`, data);
}

export async function getOpportunity(id, cache, cancelToken) {
    const url = `${Settings.apiUrls.cci}/opportunities/${id}`;
    if (cache && cache[url]) return cache[url];
    else
        return HttpGet(url, cancelToken).then((response) => {
            if (cache) cache[url] = response;
            return response;
        });
}

export async function getCurrentOpportunityStage(id, cache, cancelToken) {
    const url = `${Settings.apiUrls.cci}/opportunities/${id}/current-stage`;
    if (cache && cache[url]) return cache[url];
    else
        return HttpGet(url, cancelToken).then((response) => {
            if (cache) cache[url] = response;
            return response;
        });
}

// Section Start: Http requests for overview

export async function getLatestActivities(companyId, cancelToken, take) {
    if (take)
        return HttpGet(
            `${Settings.apiUrls.cci}/companies/${companyId}/latest-activities?take=${take}`,
            cancelToken
        );
    else
        return HttpGet(
            `${Settings.apiUrls.cci}/companies/${companyId}/latest-activities`,
            cancelToken
        );
}

// Section End

// Section Start: Http requests for edit

// Section End

// Section Start: Http requests for tasks

export async function deleteTask(id, companyId) {
    return HttpDelete(`${Settings.apiUrls.cci}/companies/${companyId}/tasks/${id}`);
}

export async function patchTask(id, companyId, patches) {
    return HttpPatch(`${Settings.apiUrls.cci}/companies/${companyId}/tasks/${id}`, patches);
}

export async function putTask(id, companyId, data) {
    return HttpPut(`${Settings.apiUrls.cci}/companies/${companyId}/tasks/${id}`, data);
}

// Section end

// Section Start: Http requests for Activities

export async function getActivities(companyId, cancelToken) {
    return HttpGet(`${Settings.apiUrls.cci}/companies/${companyId}/activities-tasks`, cancelToken);
}

export async function postActivity(companyId, data) {
    return HttpPost(`${Settings.apiUrls.cci}/companies/${companyId}/activities`, data);
}

export async function deleteActivity(id, companyId) {
    return HttpDelete(`${Settings.apiUrls.cci}/companies/${companyId}/activities/${id}`);
}

export async function patchActivity(id, companyId, patches) {
    return HttpPatch(`${Settings.apiUrls.cci}/companies/${companyId}/activities/${id}`, patches);
}

export async function putActivity(id, companyId, data) {
    return HttpPut(`${Settings.apiUrls.cci}/companies/${companyId}/activities/${id}`, data);
}

// Section end

// Section Start: Http requests for people
export async function addCompanyTeam(companyId, teamId, notifyMessage) {
    return HttpPost(`${Settings.apiUrls.cci}/companies/${companyId}/teams/${teamId}`, {}).then(
        (response) => {
            notifyCompanyAdd(companyId, notifyMessage);
            return response;
        }
    );
}

export async function removeCompanyTeam(companyId, teamId, cancelToken, notifyMessage) {
    return HttpDelete(
        `${Settings.apiUrls.cci}/companies/${companyId}/teams/${teamId}`,
        cancelToken
    ).then((response) => {
        notifyCompanyAdd(companyId, notifyMessage);
        return response;
    });
}
// Section end

let getContactsFromCompanySource = null;
export async function getContactsFromCompany(companyId) {
    if (getContactsFromCompanySource !== null) getContactsFromCompanySource.cancel();
    getContactsFromCompanySource = CancelToken.source();
    return HttpGet(
        `${Settings.apiUrls.cci}/companies/${companyId}/persons`,
        getContactsFromCompanySource.token
    );
}

export async function postFileToFileService(file) {
    return HttpPost(`${Settings.apiUrls.fileService}/files`, file);
}

// export async function getOpportunity(id, onError) {
//     const url = `${Settings.apiUrls.cci}/opportunities/${id}`;
//     return HttpGet(url).catch((error) => {
//         switch (error.code) {
//             case 499:
//                 break;
//             case 403:
//                 onError('error', `You have no permission to retrieve this opportunity ${id}`);
//                 break;
//             case 404:
//                 onError('error', 'The Opportunity does not exist anymore');
//                 break;
//             case 408:
//             case 502:
//             case 503:
//             case 504:
//                 onError('timeout', error, url);
//                 break;
//             default:
//                 onError('unknown', error, url);
//                 break;
//         }
//     });
// }

let getOpportunitiesSource = null;
export async function getOpportunities(input, onError, onTimeout, onUnknownError) {
    if (getOpportunitiesSource) getOpportunitiesSource.cancel();
    getOpportunitiesSource = CancelToken.source();
    const url = `${Settings.apiUrls.cci}/opportunities/details/search?take=10&skip=0&term=${input}`;
    return HttpGet(url, getOpportunitiesSource.token)
        .then((response) => response.result)
        .catch((error) => {
            switch (error.code) {
                case 499:
                    break;
                case 403:
                    onError(`You have no permission to search for opportunities`);
                    break;
                case 408:
                case 502:
                case 503:
                case 504:
                    onError('timeout', error, url);
                    break;
                default:
                    onError('unknown', error, url);
                    break;
            }
        });
}

export async function getTeam(teamId, onError, cancelToken = null, cache) {
    const url = `${Settings.apiUrls.cci}/teams/${teamId}`;
    if (cache && cache[url]) return cache[url];
    else {
        return HttpGet(url, cancelToken).then((response) => {
            if (cache) cache[url] = response;
            return response;
        });
    }
}

// ContactPersona start
export async function getContact(id, cache, cancelToken) {
    const url = `${Settings.apiUrls.cci}/persons/${id}`;
    if (cache && cache[url]) return Promise.resolve(() => cache[url]);
    else
        return HttpGet(url, cancelToken).then((response) => {
            if (cache) cache[url] = response;
            return response;
        });
}

export function getContactIconUrl(contactId) {
    return `${Settings.apiUrls.cci}/proxy/persons/${contactId}/icon`;
}
// ContactPersona end

export async function getPermissionForPersonOnEntity(entityId, type, userId) {
    return HttpGet(
        `${Settings.apiUrls.cci}/${mapEntityTypeToCollectionType(
            type
        )}/${entityId}/permissions/${userId}`
    );
}

export async function changePermissionForPersonOnEntity(entityId, type, userId, role) {
    return HttpPut(
        `${Settings.apiUrls.cci}/${mapEntityTypeToCollectionType(type)}/${entityId}/permissions`,
        {
            userId: userId,
            permissionRole: role,
            specialPermissions: [],
        }
    );
}

export async function getDocumentTypes() {
    return HttpGet(`${Settings.apiUrls.globalConfig}/enums/documentTypes`);
}

export async function patchDocumentType(documentId, documentType) {
    return HttpPatch(`${Settings.apiUrls.tenders}/${documentId}/${documentType}`);
}