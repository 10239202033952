import React, { useEffect, useState } from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {IProjectConnectionDto} from "../../../Api/Dtos/IConnectionDto";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IFoundation} from "../../../Interfaces/IFoundation";
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import {shortenString} from "../../../Utils/shortenString";
import {IButtonStyles, IconButton, IContextualMenuProps} from "office-ui-fabric-react";
import { IMinimalProject } from "./ProjectPicker";
import { getMinimalProject } from "../../../Api/TenderRequests";
import { request } from "../../../Utils/request";
import Settings from "../../../Settings";

const Card = styled.div`
    background-color: ${(props: IFoundation) => props.theme.colors.white};
    padding: 12px 10px;
    
    :hover {
        background-color: ${(props: IFoundation) => props.theme.colors.neutralLight};
        cursor: pointer;
    }
`;
const Code = styled.div`
    color: ${(props: IFoundation) => props.theme.colors.themePrimary};
    font-weight: ${(props: IFoundation) => props.theme.fontWeights.bold};
`;
const Title = styled.div`
    font-weight: ${(props: IFoundation) => props.theme.fontWeights.bold};
`;
const Note = styled.div`
    margin-right: 17px;
`;

interface IConnectionProps extends IBaseProps {
    connection: IProjectConnectionDto;
    onRemove: () => void;
}

const customSplitButtonStyles: IButtonStyles = {
    icon: {
        color: "black"
    }
};

export const Connection: React.FunctionComponent<IConnectionProps> = (props) => {
    const getProjects = async (projectId: string): Promise<IMinimalProject[]> => {
        if (!projectId) { return []; }
        const {result, error} = await request<{result: IMinimalProject[]}>(getMinimalProject(projectId, {take: 7, skip: 0}));
        if (!result || !result.result || error) {
          console.log("An error occurred attempting to fetch minimal projects");
          return [];
        }
        return result.result;
    };
      
    const [projects, setProjects] = useState<IMinimalProject[]>([]);

    useEffect(() => {
        fetchProjects().then((fetchedProjects) => {
          setProjects(fetchedProjects);
        });
    }, []);
      
      async function fetchProjects() {
        const projects = await getProjects(props.connection.title);
        return projects;
    }
      
    const projectIds = projects.map((x) => x.id)[0];
      
    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'mergeView',
                text: 'Show in mergeview',
                onClick: () => console.log("Not yet implemented."),
            },
            {
                key: 'unlink',
                text: 'Unlink',
                onClick: () => props.onRemove(),
            }
        ],
    };
    
    return (
        <Card>
            <Flex alignItems="flex-start" justifyContent="space-between">
                <Flex>
                    <Box>
                        <Flex>
                            <a href={`${Settings.uiUrls.projects}/entity/${projectIds}/project/overview`} target="_blank" style={{"textDecoration": "none"}}>
                                <Code>{props.connection.code}</Code>
                                <Title>{props.connection.title}</Title>
                            </a>
                        </Flex>

                        <div>{props.connection.type.label}</div>
                    </Box>
                </Flex>

                <Flex>
                    <Note title={props.connection.note}>{shortenString(props.connection.note, 22)}</Note>
                    <IconButton styles={customSplitButtonStyles} iconProps={{ iconName: 'More' }} menuProps={menuProps} menuIconProps={{style: {display: "none"}}} />
                </Flex>
            </Flex>
        </Card>
    );
};