import {IActionArrayItem} from "../Interfaces/IActionArrayItem";
import {createActionArrayItem} from "./createActionArrayItem";
import { isProject } from "./isProject";
import { selectedEntity } from "./selectedEntity";

export const createActionArray = (
    selection: any,
    entities: any,
    updateFollowing: any,
    updateExportList: any,
    archiveItem: any,
    setExportMenuIsOpen: (isOpen: boolean) => void,
    downloadJson: any,
    openArchiveDialog: () => void,
    openUnArchiveDialog: () => void,
    selectAllEntities: Function,
    isFiltered: boolean,
  ): IActionArrayItem[] => {
    
    const selected = selectedEntity();
    const selectedEntities = selected.getSelection();
    
    const allUserContextSelected = selectedEntities?.every((x: any) => x.userContext?.isSelected === true);
    const allUserContextNotSelected = selectedEntities?.every((x: any) => x.userContext?.isSelected === false);

    const renderExportButtonText = () => {
        if (allUserContextSelected) return "Remove from export list";
        else if (allUserContextNotSelected) return "Add to export list";
        else return "Add to export list";
    };

    const renderExportButtonType = () => {
        if (allUserContextSelected) return "ExportMirrored";
        else if (allUserContextNotSelected) return "Export";
        else return "Export";
    };

    if (!selection || selection.length < 1) return [];
    let actionArray = [];
    

    let isFollowingAtleastOneItem = false;
    for (let i = 0; i < selection.length; i++) {
        if (entities) {
            const item = entities[selection[i]];

            if (item?.isFollowing) {
                isFollowingAtleastOneItem = true;
                break;
            }
        }
    }
    
    let isSelectedAtleastOneItem = false;
    for (let i = 0; i < selection.length; i++) {
        if (entities) {
            const item = entities[selection[i]];

            if (item?.userContext.isSelected) {
                isSelectedAtleastOneItem = true;
                break;
            }
        }
    }

    let isArchived = false;
    for (let i = 0; i < selection.length; i++) {
        if (entities) {
            const item = entities[selection[i]];
            if (item?.state === 2) {
                isArchived = true;
                break;
            }
        }
    }

    const downloadProject = async (): Promise<void> => {
        if (!selection || !selection.length ||  !entities) return;
        const ids: string[] = [];
        const assignmentTitles: string[] = [];
    
        for (const selected of selection) {
            ids.push(entities[selected].id);
            assignmentTitles.push(entities[selected].assignmentTitle);
        }
    
        await downloadJson(ids, assignmentTitles);
    }
    
    const data = selectedEntities?.map((x: any) => x?.stages);
    const newestStages: string[] = [];
    if(isProject()){
    data?.forEach((array: any) => {
      let maxDate = "";
      let newestStage = "";
    
      array?.forEach((obj: any) => {
        if (!maxDate || obj.date > maxDate) {
          maxDate = obj.date;
          newestStage = obj.stage;
        }
      });
    
      newestStages.push(newestStage);
    });
    
    }
    const isPublished = newestStages.every(stage => Number(stage) === 6);
    const isPending = newestStages.every(stage => Number(stage) === 5 || Number(stage) === 4 || Number(stage) === 3);

    
    if(isProject()){
        if(isPublished || isPending){
    actionArray.push(createActionArrayItem(
        "Code",
        "Export to Json",
        selection ? selection.length === 0 : true,
        () => downloadProject()
    ));
    } }
    
    if(isProject()){
        if(isPublished || isPending){
        actionArray.push(createActionArrayItem(
            "wordDocument",
            "Export to Word",
            false,
            () => setExportMenuIsOpen(true)
        ));
         } }
        

        if(isProject()){
            actionArray.push(createActionArrayItem(
                isArchived ? "Unarchive" : "Archive",
                isArchived ? "Unarchive" : "Archive",
                selection ? selection.length === 0 : true,
                () => archiveItem ? archiveItem() : null,
            ));
        }
        else{
        actionArray.push(
            createActionArrayItem(
                isArchived ? "Unarchive" : "Archive",
                isArchived ? "Unarchive" : "Archive",
                selection ? selection.length === 0 : true,
                () => {
                    if (!isArchived) {
                        return openArchiveDialog();
                    }
                    
                        return openUnArchiveDialog();
                }
            )
        );
        }
    actionArray.push(createActionArrayItem(
        !isFollowingAtleastOneItem ? "FavoriteList" : "Unfavorite",
        !isFollowingAtleastOneItem ? "Start following" : "Stop following",
        selection ? selection.length === 0 : true,
        () => updateFollowing(),
        "following"
    ));
    if(isProject() && (isPublished || isPending)){ 
        actionArray.push(createActionArrayItem(
            renderExportButtonType(),
            renderExportButtonText(),
            selection ? selection.length === 0 : true,
            () => updateExportList(),
            "selected"
        ));
    }
    actionArray.push(createActionArrayItem(
        "multiSelect",
        selection?.length < entities?.length ? "Select all" : "Deselect all",
        false,
        () => selectAllEntities()
    ));
    return actionArray;
}