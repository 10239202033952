import React from "react";
import {
    IBasePickerStyleProps, IBasePickerStyles,
    IBasePickerSuggestionsProps,
    IStyleFunctionOrObject, ITag, TagPicker
} from "office-ui-fabric-react";
import {Label} from "../../Label";
import {request} from "../../../Utils/request";

export interface IMinimalTender {
    id: string;
    assignmentTitle: string;
    loisCode: string;
}

interface ITenderPickerProps {
    /**
     * A function which fetches the tenders that will be displayed as selectable options.
     * Takes a string (Tender title, or LOIS code) as search input, and passes additional options for limiting the amount of search results.
     */
    fetchTenders: (searchInput: string) => Promise<IMinimalTender[]>;
    // When the list of selected tenders changes, this function is fired. It returns a list of all selected tenders. Value will be undefined if no tenders are selected.
    onChange: (tenders: string[] | undefined) => void;
    // The label displayed above the input field.
    label?: string;
    // Only styles the overlying div, not the input.
    className?: string;
    // Only styles the overlying div, not the input.
    style?: React.CSSProperties;
}

export const TenderPicker: React.FunctionComponent<ITenderPickerProps> = (props) => {

    const getTextFromItem = (item: ITag) => item.name;

    const tendersToTags = (tenders: IMinimalTender[]): ITag[] => tenders.map<ITag>( tender => ({key: tender.id, name: tender.loisCode + " " + tender.assignmentTitle }));
    const tagsToTenders = (tags: ITag[]): string[] => tags.map<string>( tag => tag.key as string);

    const filterSuggestedTags = async (filterText: string): Promise<ITag[]> => {

        const {result, error} = await request<IMinimalTender[]>(props.fetchTenders(filterText));

        if (!result || !result.length || error) {
            console.error("An error occurred attempting to fetch tenders in the Tender Picking component.");
            return [];
        }

        return tendersToTags(result);
    };

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested tenders',
        noResultsFoundText: 'No tenders found',
    };

    const style: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> | undefined = {
        text: {
            borderLeft: "none",
            borderTop: "none",
            borderRight: "none",
        }
    }

    return (
        <div style={props.style} className={props.className}>
            <Label>{props.label}</Label>
            <TagPicker
                styles={style}
                removeButtonAriaLabel="Remove"
                onResolveSuggestions={filterSuggestedTags}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={pickerSuggestionsProps}
                itemLimit={1}
                onChange={(tags: ITag[] | undefined) => tags ? props.onChange(tagsToTenders(tags)) : undefined}
            />
        </div>
    );
};

// TagPicker props for displaying a custom search result tag.
//
// onRenderSuggestionsItem={(p) => (
//     <div>
//         <div>{p.name}</div>
//         <div>Subtext</div>
//     </div>
// )}

