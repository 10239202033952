import React, { useMemo } from "react";
import {
    IBasePickerStyleProps, IBasePickerStyles,
    IBasePickerSuggestionsProps,
    IStyleFunctionOrObject, ITag, TagPicker
} from "office-ui-fabric-react";
import {Label} from "./Label";
import {IDepartmentDto} from "../Api/Dtos/IDepartmentDto";
import {useDepartments} from "../Hooks/GlobalConfigContext";
import {tagUpdate} from "../Utils/tagUpdates";
import {IUpdateSdgResponse} from "../Interfaces/IUpdateSdgResponse";

interface IDepartmentPickerProps {
    // Triggered when the user selects or removes a department. Passes an object that can be directly fed to Patch
    onChange: (changed: IUpdateSdgResponse | undefined) => void;
    // If a department is present, display it's name in the input field.
    selectedDepartments: string[];
    // The field for the path used in the onChange "path" attribute.
    field?: string;
    // The label displayed above the input field.
    label?: string;
    // Only styles the overlying div, not the input.
    className?: string;
    // Only styles the overlying div, not the input.
    style?: React.CSSProperties;
}

export const DepartmentPicker: React.FunctionComponent<IDepartmentPickerProps> = (props) => {

    const departments = useDepartments();

    const getTextFromItem = (item: ITag) => item.name;

    const findDepartment = (departmentId: string): IDepartmentDto => departments.find( d => d.id === departmentId) as IDepartmentDto;
    const departmentToTags = (department: IDepartmentDto): ITag => ({key: department.id, name: department.abbreviation + " - " + department.name });
    const departmentsToTags = (departments: IDepartmentDto[]): ITag[] => departments.map<ITag>( department => (departmentToTags(department)));

    const idToDepartmentToTags = (departmentId: string): ITag | undefined => {       
        const department = findDepartment(departmentId);
        if(department == undefined){ return}
        return departmentToTags(department);
    }

    const tagsToDepartment = (tags: ITag[]): IDepartmentDto[] => departments.filter( department => tags.find( tag => tag.key === department.id ));

    const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.some(compareTag => compareTag.key === tag.key);
    };

    const filterSuggestedTags = async (filterText: string, tagList?: ITag[]): Promise<ITag[]> => {
        if (!departments || !departments.length) { return []; }

        const newTags = departmentsToTags(departments);

        return filterText ? newTags.filter( tag => tag.name.toLowerCase().includes(filterText.toLowerCase()) && !listContainsTagList(tag, tagList)) : [];
    };

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested departments',
        noResultsFoundText: 'No departments found',
    };

    const style: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> | undefined = {
        text: {
            borderLeft: "none",
            borderTop: "none",
            borderRight: "none",
        }
    }

    let item = useMemo(() => props.selectedDepartments?.map( departmentId => idToDepartmentToTags(departmentId)).filter( d => d ) as ITag[], [props.selectedDepartments.length]);

    return (
        <div>
            <Label>{props.label}</Label>
            <TagPicker
                styles={style}
                removeButtonAriaLabel="Remove"
                onResolveSuggestions={filterSuggestedTags}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={pickerSuggestionsProps}
                itemLimit={40}
                // onItemSelected={(selectedItem?: ITag | undefined) => sendSelectedToProps(selectedItem)}
                selectedItems={props.selectedDepartments ? item : []}
                onChange={ (items: ITag[] | undefined) => props.onChange(tagUpdate(items, props.selectedDepartments, props.field)) }
            />
        </div>
    );
};

// TagPicker props for displaying a custom search result tag.
//
// onRenderSuggestionsItem={(p) => (
//     <div>
//         <div>{p.name}</div>
//         <div>Subtext</div>
//     </div>
// )}