import {IUpdateSdgResponse} from "../Interfaces/IUpdateSdgResponse";
import {EOP} from "../Interfaces/IPatchParams";
import {ITag} from "office-ui-fabric-react";

export const tagUpdate = (updatedTags: ITag[] | undefined, selectedTagsKeys: string[], field?: string): IUpdateSdgResponse | undefined => {

    if (!updatedTags || !updatedTags?.length) {
        if (selectedTagsKeys && selectedTagsKeys.length) {
            return {
                op: EOP.Remove,
                path: `${field}/${selectedTagsKeys[0]}`
            };
        }
        return;
    }

    let updatedTagsKeys = updatedTags.map<string>( sTag => sTag.key as string);

    // A keyword has been added. Find it and create a patch.
    if (updatedTagsKeys.length > selectedTagsKeys.length) {

        const addedTag = updatedTagsKeys.filter(e=> !selectedTagsKeys.includes(e));

        if (addedTag && addedTag.length) {
            return {
                op: EOP.Add,
                value: addedTag[0],
                path: `${field}/-`
            };
        }
    }

    // Find out which one has been removed.
    if (selectedTagsKeys.length > updatedTagsKeys.length) {
        const removedTag = selectedTagsKeys.filter(e=> !updatedTagsKeys.includes(e));

        if (removedTag && removedTag.length) {
            return {
                op: EOP.Remove,
                path: `${field}/${removedTag[0]}`
            };
        }
    }
}