import React, {useEffect, useMemo, useState} from "react";
import {IBasePickerSuggestionsProps, ITag, TagPicker} from '@fluentui/react/lib/Pickers';
import {IKeywordDto} from "../Api/Dtos/IKeywordDto";
import {IBasePickerStyleProps, IBasePickerStyles, IStyleFunctionOrObject} from "office-ui-fabric-react";
import {Label} from "./Label";
import {IUpdateSdgResponse} from "../Interfaces/IUpdateSdgResponse";
import {EOP} from "../Interfaces/IPatchParams";
import {tagUpdate} from "../Utils/tagUpdates";

interface IKeywordPickerProps {
    // A list of all keywords a user can select.
    keywords: IKeywordDto[];
    // An array of the selected keywords.
    selectedKeywords: string[];
    // Fired when a user selects a keyword.
    onChange: (changed: IUpdateSdgResponse | undefined) => void;
    // The field for the path used in the onChange "path" attribute.
    field?: string;
    // The label displayed above the input field.
    label?: string;
}

const keywordToTag = (keyword: IKeywordDto): ITag => ({ key: keyword.id, name: keyword.label});

export const KeywordPicker: React.FunctionComponent<IKeywordPickerProps> = (props) => {

    const [tags, setTags] = useState<ITag[]>([]);

    useEffect(() => {
        if (!props.keywords) {
            setTags([]);
            return;
        }

        setTags(props.keywords.map( keyword => keywordToTag(keyword)));
    }, [props.keywords])

    const selectedKeywords = useMemo(() => {
        if (!props.selectedKeywords || !props.selectedKeywords.length) return [];
        let fullKeywords: IKeywordDto[] = [];
        props.selectedKeywords.forEach( kw => {
            let full = props.keywords.find( fkw => fkw.id === kw)
            if (full) fullKeywords.push(full);
        });
        return fullKeywords.map( keyword => keywordToTag(keyword));
    }, [props.selectedKeywords]);

    const getTextFromItem = (item: ITag) => item.name;

    const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.some(compareTag => compareTag.key === tag.key);
    };

    const filterSuggestedTags = (filterText: string, tagList?: ITag[]): ITag[] => {
        return filterText
            ? tags.filter(
                tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
            )
            : [];
    };

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested keywords',
        noResultsFoundText: 'No keywords found',
    };

    const style: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> | undefined = {
        text: {
            borderLeft: "none",
            borderTop: "none",
            borderRight: "none",
        }
    }

    return (
        <div>
            <Label>{props.label}</Label>
            <TagPicker
                styles={style}
                removeButtonAriaLabel="Remove"
                onResolveSuggestions={filterSuggestedTags}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={pickerSuggestionsProps}
                itemLimit={30}
                selectedItems={selectedKeywords}
                onChange={(items?: ITag[] | undefined) => props.onChange(tagUpdate(items, props.selectedKeywords, props.field))}
            />
        </div>
    );
};
