import React, {useEffect, useMemo, useState} from "react";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {PeopleList} from "../Components/PeopleList";
import {EPersonRole, IAddPersonDto} from "../Api/Dtos/IAddPersonDto";
import {IPersonDto} from "../Api/Dtos/IPersonDto";
import {request} from "../Utils/request";
import {deleteContact, getContacts, postContact, postSystemTask, postTimeLineData} from "../Api/TenderRequests";
import {dividePeopleByRole, IPeopleInRoles} from "../Utils/dividePeopleByRole";
import {ITenderOverviewDto} from "../Api/Dtos/ITenderOverviewDto";
import { IAddingTimeLineProgress } from "../Api/Dtos/IAddTimelineProgress";
import { isProject } from "../Utils/isProject";
import { PatchParams } from "../Interfaces/PatchParams";

const Content = styled.div`
    margin-left: 30px;
    margin-top: 30px;
    width: 100%;
    display: flex;
`

const ContactRow = styled.div`
    flex: 1;
    flex-direction: column;
`

interface IPeopleProps {
    tender: ITenderOverviewDto;
    user: any;
    patch: PatchParams;
}

export const People: React.FunctionComponent<IPeopleProps> = (props) => {
    const [people, setPeople] = useState<IPeopleInRoles>();
    const id = useMemo( () => props.tender.linkedTender ? props.tender.linkedTender : props.tender.id, []);

    useEffect(() => {
        fetchPeople();
    }, []);

    const fetchPeople = async (): Promise<void> => {
        if (!props.tender || !props.tender.id) return;
        const { result, error } = await request<IPersonDto[]>(getContacts(id));

        if (error || !result) {
            console.log(`An error occurred attempting to fetch contacts for tender with ID ${props.tender.id}`);
            return;
        }
        
        const peopleByRole = dividePeopleByRole(result);

        setPeople(peopleByRole);
    }

    /**
     * Add a person to the Tender. If a type is provided, a Task is send to the added user.
     * @param person - The person that will be added to the tender.
     * @param type - (Optional) The role of the person added to the tender. A Task based on this role will be send to the user.
     */
    const addPerson = async (person: IAddPersonDto, type?: "regionalcoordinator" | "departmenthead" | "tenderlead"): Promise<void> => {
        const { result, error } = await request<IAddPersonDto>(postContact(person));
    
        if (error) {
            console.log(`An error occurred attempting to post a contact for tender with ID ${props.tender.id}`);
            return;
        }
    
        if (type === "tenderlead") {
            const payload: IAddingTimeLineProgress = {
                stage: "TenderLeadAssigned",
                deadline: new Date().toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true }),
                date: new Date().toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true }),
                type: 0
            };
        
            await postTimeLineData(id, payload);
        }
        
        if (type === "departmenthead") {
            const payload: IAddingTimeLineProgress = {
                stage: "SentToDepartmentManager",
                deadline: new Date().toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true }),
                date: new Date().toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true }),
                type: 1
            };
        
            await postTimeLineData(id, payload);
        }
               
        fetchPeople();
    }

    const deletePerson = async (contactPersonId: string, userReference: string): Promise<void> => {
        deleteContact(id, contactPersonId, userReference).then( result => {
            fetchPeople();
        }).catch( e => console.log(`An error occurred attempting to delete a contact: ${userReference}, for tender with ID ${id}`));
    }

    if (!props.tender || !props.tender.id)        if(props.tender.secret){
        return <div>You do not have permission</div>
    }
    else{
    return <div>loading...</div>
    } 

    return (
        <Content>
            <ContactRow>
                <PeopleList
                    label={"Regional coordinator(s)"}
                    role={EPersonRole.RegionalCoordinator}
                    people={people?.RegionalCoordinator}
                    addPerson={(person: IAddPersonDto) => addPerson(person, "regionalcoordinator")}
                    tenderId={id}
                    entityType={props.tender.type}
                    deletePerson={deletePerson}
                    userContext={props.tender.userContext}
                    showOptions={true}
                    isOverview={false}
                />

                <PeopleList
                    style={{marginTop: 30}}
                    label={"Department head"}
                    role={EPersonRole.DepartmentHead}
                    people={people?.DepartmentHead}
                    addPerson={(person: IAddPersonDto) => addPerson(person, "departmenthead")}
                    tenderId={id}
                    entityType={props.tender.type}
                    deletePerson={deletePerson}
                    userContext={props.tender.userContext}
                    isOverview={false}
                    showOptions={true}
                />

                {isProject() ?
                    <PeopleList
                        style={{marginTop: 30}}
                        label={"Project team"}
                        role={EPersonRole.ProjectTeam}
                        people={people?.ProjectTeam}
                        externalTeamMembers={props.tender.linkedProject?.externalTeamMembers}
                        addPerson={(person: IAddPersonDto) => addPerson(person)}
                        tenderId={id}
                        entityType={props.tender.type}
                        deletePerson={deletePerson}
                        userContext={props.tender.userContext}
                        showOptions={true}
                        isOverview={false}
                        patch={props.patch}
                        showFunctionDescriptionInput
                        isProjectTeam
                        
                    /> 
                : null}

                {/*<PeopleList*/}
                {/*    style={{marginTop: 30}}*/}
                {/*    label={"Unit head"}*/}
                {/*    role={EPersonRole.UnitHead}*/}
                {/*    people={people?.UnitHead}*/}
                {/*    addPerson={(person: IAddPersonDto) => addPerson(person)}*/}
                {/*    tenderId={id}*/}
                {/*    entityType={props.tender.type}*/}
                {/*    deletePerson={deletePerson}*/}
                {/*    userContext={props.tender.userContext}*/}
                {/*    showOptions={true}*/}
                {/*/>*/}
            </ContactRow>
            <ContactRow>
                <PeopleList
                    label={"Tender lead"}
                    role={EPersonRole.TenderLead}
                    people={people?.TenderLead}
                    addPerson={(person: IAddPersonDto) => addPerson(person, "tenderlead")}
                    tenderId={id}
                    entityType={props.tender.type}
                    deletePerson={deletePerson}
                    userContext={props.tender.userContext}
                    showOptions={true}
                    isOverview={false}
                />

                <PeopleList
                    style={{marginTop: 30}}
                    label={"Project lead"}
                    role={EPersonRole.ProjectLead}
                    people={people?.ProjectLead}
                    addPerson={(person: IAddPersonDto) => addPerson(person)}
                    tenderId={id}
                    entityType={props.tender.type}
                    deletePerson={deletePerson}
                    userContext={props.tender.userContext}
                    showOptions={true}
                    isOverview={false}
                />

                <PeopleList
                    style={{marginTop: 30}}
                    label={"Other contact(s)"}
                    role={EPersonRole.OtherContacts}
                    people={people?.OtherContacts}
                    addPerson={(person: IAddPersonDto) => addPerson(person)}
                    tenderId={id}
                    entityType={props.tender.type}
                    deletePerson={deletePerson}
                    userContext={props.tender.userContext}
                    showOptions={true}
                    showFunctionDescriptionInput
                    isOverview={false}
                />
            </ContactRow>
        </Content>
    );
};
