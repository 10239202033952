import React from 'react';
import Dialog, {
    DialogType,
} from 'ibis-design-system/lib/components/core/Dialog';
import Button from 'ibis-design-system/lib/components/core/Button';

import { selectedEntity } from '../../Utils/selectedEntity';
import { isProject } from '../../Utils/isProject';
import Flex from 'ibis-design-system/lib/components/core/Flex';


export function OpenUnarchiveDialog({ isOpen, handleClose, onUnArchive }: { isOpen: boolean, handleClose: () => void, onUnArchive: () => void }) {
    const selectedEntities = selectedEntity().getSelection();
    
    return (
        <Dialog
            isOpen={isOpen}
            handleClose={handleClose}
            title={'Unarchive  ' + selectedEntities?.map((x:any) => x.loisCode)}
        >
            <Flex>
                Are you sure you want to unarchive "{isProject() ? selectedEntities?.map((x: any) => x.projectTitle) : selectedEntities?.map((x: any) => x.assignmentTitle)}"
            </Flex>
            <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', top:'57px'}}>
            <div className="button-container">
                <Button
                    variant="primary"
                    onClick={async () => {
                        onUnArchive();
                        handleClose();
                    }}
                >
                    Unarchive
                </Button>
            </div>
                <Button style={{ margin: 20 }} onClick={handleClose}>Cancel</Button>
            </div>
        </Dialog>
    );
}
