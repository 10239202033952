import React from "react";
import WatchAbleScrollContainer from 'ibis-design-system/lib/components/watchAbleComponents/WatchAbleScrollContainer';
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import Editview from 'ibis-design-system/lib/components/core/Editview';
import {TenderGeneral} from "../Components/TenderTab/TenderGeneral";
import {Source} from "../Components/TenderTab/Source";
import {Connections} from "../Components/TenderTab/Connections/Connections";
import {AddProgress} from "../Components/TenderTab/AddProgress";
import {ITenderOverviewDto} from "../Api/Dtos/ITenderOverviewDto";
import {isProject} from "../Utils/isProject";
import {PatchParams} from "../Interfaces/PatchParams";
import Location from "../Components/Location";
import DefaultDetailview from 'ibis-design-system/lib/components/core/DefaultDetailview/DefaultDetailview';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';

interface ITenderProps {
    item: ITenderOverviewDto;
    patch: PatchParams;
}

export const Tender: React.FunctionComponent<ITenderProps> = (props) => {

    const id = isProject() ? props.item.linkedTender as string : props.item.id;
   
    const subjects = [
        {
            id: 'general',
            name: 'General',
            // @ts-ignore
            component: <TenderGeneral tender={props.item} permissions={props.item.userContext.permissions} tenderId={id} patch={props.patch} />,
            showHeader: false,
        },
        {
            id: 'source',
            name: 'Source',
            component: <Source permissions={props.item.userContext.permissions} tenderId={id} tender={props.item} patch={props.patch} />,
            showHeader: true,
        },
        {
            id: 'progress',
            name: 'Progress',
            component: <AddProgress permissions={props.item.userContext.permissions} tenderId={id} item={props.item} />,
            showHeader: true,
        },
        {
            id: 'location',
            name: 'Location',
            component: <Location permissions={props.item.userContext.permissions} tender={props.item} tenderId={isProject() ? props.item.linkedTender as string : props.item.id} patch={props.patch} variant="country"/>,
            showHeader: true,
        },
        {
            id: 'connections',
            name: 'Connections',
            component: <Connections permissions={props.item.userContext.permissions} style={{paddingBottom: 150}} tenderId={id} />,
            showHeader: true,
        },
    ];
    const subjectsTender = [
        {
            id: 'general',
            name: 'General',
            // @ts-ignore
            component: <TenderGeneral tender={props.item} permissions={props.item.userContext.permissions} tenderId={id} patch={props.patch} />,
            showHeader: false,
        },
        {
            id: 'source',
            name: 'Source',
            component: <Source permissions={props.item.userContext.permissions} tenderId={id} tender={props.item} patch={props.patch} />,
            showHeader: true,
        },
        {
            id: 'location',
            name: 'Location',
            component: <Location permissions={props.item.userContext.permissions} tender={props.item}  tenderId={isProject() ? props.item.linkedTender as string : props.item.id} patch={props.patch} variant="country"/>,
            showHeader: true,
        },
        {
            id: 'connections',
            name: 'Connections',
            component: <Connections permissions={props.item.userContext.permissions} style={{paddingBottom: 150}} tenderId={id} />,
            showHeader: true,
        },
    ];
    const subjectsTenders = isProject() ? subjectsTender : subjects ;

    if (!props.item || !props.item.linkedProject) {
        if(props.item.secret){
            return <Box flex="1 1 auto" p={3} bg="light" ><DefaultDetailview title="You do not have permission" icon="LockSolid"><p></p></DefaultDetailview></Box>
        }
        else{
        return <div>loading...</div>
        }
    }

    return (
        <div style={{width: "100%"}}>
        <WatchAbleScrollContainer>
            {/* this calculates the max width of the editfield by deducting the width of the navigation (200px) and padding (60px) */}
            <Flex width="calc(100% - 260px)" pl={3}>
                <Editview subjects={subjectsTenders} />
            </Flex>
        </WatchAbleScrollContainer>
        </div>
    );
};

