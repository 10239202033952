import React from "react";
import {IBaseProps} from "../../Interfaces/IBaseProps";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import ChoiceGroup from "ibis-design-system/lib/components/inputFields/ChoiceGroup";
import Flex from 'ibis-design-system/lib/components/core/Flex';
import {RangeInput} from "../RangeInput/RangeInput";
import Dropdown from "ibis-design-system/lib/components/inputFields/Dropdown";
import {MaconomyNumberInput} from "../MaconomyNumberInput";
import {useFinancingFrameworks, useFinancingType, useSelectingMethods} from "../../Hooks/GlobalConfigContext";
import {IMultiselectOption, IOption} from "../ProjectTab/Connections/AddConnection";
import {getLeadTypes} from "../../Utils/getLeadTypes";
import {IProject} from "../../Api/Dtos/IProjectDto";
import {EOP} from "../../Interfaces/IPatchParams";
import {EPermission} from "../../Api/Dtos/EPermission";
import {hasPermission} from "../../Utils/hasPermission";
import {PatchParams} from "../../Interfaces/PatchParams";
import { postMaconomyNumber } from "../../Api/TenderRequests";

const SmallBox = styled.div`
    width: 261px;
`

const SMBox = styled.div`
  margin-top: 30px;
  width: 277px;
`

const MediumBox = styled.div`
    width: 421px;
    margin-top: 30px;
`

const MaconomyBox = styled.div`
    width: 100%;
`;

interface IFinanceGeneralProps extends IBaseProps {
    tenderId: string;
    project: IProject;
    patch: PatchParams;
    userPermissions: EPermission[];
}

export const FinanceGeneral: React.FunctionComponent<IFinanceGeneralProps> = (props) => {

    const financingTypes = useFinancingType();
    const selectingMethods = useSelectingMethods();
    const financingFrameworks = useFinancingFrameworks();

    return (
        <div style={props.style} className={props.className}>
            <MaconomyBox>
                <MaconomyNumberInput project={props.project} maconomyNumber={props.project.maconomyId} onChange={(value: string) => {
                    postMaconomyNumber(value, props.project.id)}
                    }/>
            </MaconomyBox>

            <MediumBox>
                <ChoiceGroup
                    label="Lead type"
                    selected={props.project.leadType !== undefined ? props.project.leadType : undefined}
                    onChange={ (value: {abbreviation: string, key: number, text: string}) => {
                        props.patch([
                            {
                                op: EOP.Replace,
                                value: value.key,
                                path: `/linkedProject/leadType`,
                            }
                        ], props.project.linkedTender)
                    } }
                    options={getLeadTypes()}
                    disabled={!hasPermission(props.userPermissions)}
                />
            </MediumBox>

            <Flex>
                <SMBox>
                    <RangeInput
                        disabled={!hasPermission(props.userPermissions)}
                        label="Proposal technical weightage"
                        value={props.project.proposalWeightageTechnical ? props.project.proposalWeightageTechnical : 0}
                        onChange={(value: number) => props.patch([
                            {
                                op: EOP.Replace,
                                value: value,
                                path: `/linkedProject/proposalWeightageTechnical`,
                            }
                        ], props.project.linkedTender)}
                    />
                </SMBox>
                <SMBox style={{marginLeft: 14}}>
                    <RangeInput
                        disabled={!hasPermission(props.userPermissions)}
                        label="Work carried out by Deltares (%)"
                        value={props.project.workCarriedOutByDeltares ? props.project.workCarriedOutByDeltares : 0}
                        onChange={(value: number) => props.patch([
                            {
                                op: EOP.Replace,
                                value: value,
                                path: `/linkedProject/workCarriedOutByDeltares`,
                            }
                        ], props.project.linkedTender)}
                        // danger
                        // errorMessage="Value not in range with budget."
                    />
                </SMBox>
            </Flex>

            <Flex mt={1}>
                <SmallBox>
                    <Dropdown
                        disabled={!hasPermission(props.userPermissions)}
                        label="Selecting method"
                        placeholder="Select the selecting method"
                        options={selectingMethods}
                        multiSelect={false}
                        selected={props.project.selectingMethod}
                        onChanged={(sel: IOption) => {
                            props.patch([
                                {
                                    op: EOP.Replace,
                                    value: sel ? sel.key : null,
                                    path: `/linkedProject/selectingMethod`,
                                }
                            ], props.project.linkedTender)
                        }}
                    />
                </SmallBox>
                <SmallBox style={{marginLeft: 30}}>
                    <Dropdown
                        disabled={!hasPermission(props.userPermissions)}
                        label="Financing type"
                        placeholder="Select how the lead is financed"
                        options={financingTypes}
                        multiSelect={false}
                        selected={props.project.financingType}
                        onChanged={(sel: IOption) => {
                            props.patch([
                                {
                                    op: EOP.Replace,
                                    value: sel ? sel.key : null,
                                    path: `/linkedProject/financingType`,
                                }
                            ], props.project.linkedTender)
                        }}
                    />
                </SmallBox>
            </Flex>

        </div>
    );
};
