import {ITenderGeneralDto} from "./Dtos/ITenderGeneralDto";
import {ITenderSourceDto} from "./Dtos/ITenderSourceDto";
import {IProjectGeneralDto} from "./Dtos/IProjectGeneralDto";
import {IDeltaresProgramDto} from "./Dtos/IDeltaresProgramDto";
import {IOperationalGoalDto} from "./Dtos/IOperationalGoalDto";
import {IDeltaresProgramGoalCreateDto} from "./Dtos/IDeltaresProgramGoalCreateDto";
import {IDeltaresProgramCreateDto} from "./Dtos/IDeltaresProgramCreateDto";
import {ITimeLineDto} from "./Dtos/ITimeLineDto";
import {CancelToken, HttpGet, HttpPost, HttpPut, HttpDelete} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import Settings from "../Settings";
import {IAddingTimeLineProgress} from "./Dtos/IAddTimelineProgress";
import {IConnectionDto, IProjectConnectionCreateDto, IProjectConnectionDto} from "./Dtos/IConnectionDto";
import {IConnectionCreateDto} from "./Dtos/IConnectionCreateDto";
import {IMinimalTender} from "../Components/TenderTab/Connections/TenderPicker";
import {ITenderFollowersDto} from "./Dtos/ITenderFollowersDto";
import {IMinimalProject} from "../Components/ProjectTab/Connections/ProjectPicker";
import {ICreatePartyDto} from "./Dtos/ICreatePartyDto";
import {IPartyDto} from "./Dtos/IPartyDto";
import {IUpdatePartyDto} from "./Dtos/IUpdatePartyDto";
import {IPartyTotalsUpdateDto} from "./Dtos/IPartyTotalsUpdateDto";
import {IPartyTotals} from "./Dtos/IPartyTotals";
import {IPersonDto} from "./Dtos/IPersonDto";
import {IAddPersonDto} from "./Dtos/IAddPersonDto";
import {mapEntityTypeToCollectionType} from "../Constants";
import {SystemTaskDto} from "./Dtos/SystemTaskDto";
import {INotificationDto} from "./Dtos/INotificationDto";
import {request} from "../Utils/request";
import {IProject} from "./Dtos/IProjectDto";
import { IProjectExportListDto } from "./Dtos/IProjectExportListDto";


const TENDERS = "tenders";
const PROJECTS = "projects";
const PROJECT_MERGE = "ProjectMerge";
const ENTITY = "entity";

/**
 * On app initiation it's possible for Settings to be null.
 * The app doesn't start doing anything until the value is filled, but to prevent this from causing errors we're returning an empty string in case it's still empty.
 */
const BASE_URL = () => {
    if (Settings && Settings.apiUrls) {
        return Settings.apiUrls.tenders
    }

    return "";
}

/**
 * This tender contains all relations, including the main Project with relations.
 *
 * If a tender does not contain a linkedProjects object, it means no project exists for this Tender.
 * In this case a new project will be created.
 */
export const getTenderById = async (tenderId: string) => {
    const tender = await HttpGet(`${BASE_URL()}/${TENDERS}/${tenderId}`);
    if (!tender) return undefined;
    if (!tender.linkedProject) {
        const { result, error } = await request(HttpPost(`${BASE_URL()}/${PROJECTS}/${tenderId}/project/create`));
        if (!result || error) {
            console.log("Could not create a new project for tender with id", tenderId);
            return tender;
        }
        tender.linkedProject = result;
    }

    return tender;
}

// This tender contains all relations, including the main Project with relations.
export const getProjectById = async (tenderId: string) => {
    return await HttpGet(`${BASE_URL()}/${PROJECTS}/${tenderId}/projects/general`);
}

/**
 * Fetches data displayed in the General section of the Tender tab.
 * @param tenderId - Id (guid) of the tender you wish to fetch General data for.
 */
export const getTenderGeneralData = async (tenderId: string): Promise<ITenderGeneralDto | undefined> => {
    return await HttpGet(`${BASE_URL()}/${TENDERS}/${tenderId}/tender/general`);
}

// ticket IBIS-314 https://issuetracker.deltares.nl/browse/IBIS2-314
export const updateTenderGeneralData = async (tenderId: string, body: ITenderGeneralDto): Promise<ITenderGeneralDto | undefined> => {
    return await HttpPut(`${BASE_URL()}/${ENTITY}/${tenderId}/tender/updategeneral`, body);
}

/**
 * Fetches data that is fed to the <TimeLine /> component, in order to fill the timeline with events.
 * @param tenderId - Id (guid) of the tender you wish to fetch time line data for.
 */
export const getTimeLineData = async (tenderId: string): Promise<ITimeLineDto[] | undefined> => {
    return await HttpGet(`${BASE_URL()}/${TENDERS}/${tenderId}/tender/timeline`);
}

export const postTimeLineData = async (tenderId: string, body: IAddingTimeLineProgress): Promise<ITimeLineDto | undefined> => {
    // @ts-ignore
    // delete body.objectives;
    return await HttpPost(`${BASE_URL()}/${TENDERS}/${tenderId}/tender/createtimeline`, body);
}

/**
 * Fetches data displayed in the Source section of the Tender tab.
 * @param id - Id (guid) of the tender you wish to fetch Source data for.
 */
export const getTenderSourceData = async (id: string): Promise<ITenderSourceDto | undefined> => {
    return await HttpGet(`${BASE_URL()}/${TENDERS}/${id}/tender/source`);
}

// ticket IBIS-319 https://issuetracker.deltares.nl/browse/IBIS2-319
export const updateTenderSourceData = async (tenderId: string, body: ITenderSourceDto): Promise<ITenderSourceDto | undefined> => {
    return await HttpPut(`${BASE_URL()}/${ENTITY}/${tenderId}/updateTenderSource`, body);
}

export const getConnectionData = async (tenderId: string): Promise<IConnectionDto[] | undefined> => {
    return await HttpGet(`${BASE_URL()}/${TENDERS}/${tenderId}/tender/connections`);
}

export const getProjectConnectionData = async (tenderId: string): Promise<IProjectConnectionDto[] | undefined> => {
    return await HttpGet(`${BASE_URL()}/${PROJECTS}/${tenderId}/project/connections`);
}

export const createConnectionData = async (tenderId: string, body: IConnectionCreateDto): Promise<IConnectionDto | undefined> => {
    return await HttpPost(`${BASE_URL()}/${ENTITY}/${tenderId}/createTenderConnections`, body);
}

export const createProjectConnectionData = async (tenderId: string, body: IProjectConnectionCreateDto): Promise<IProjectConnectionDto | undefined> => {
    return await HttpPost(`${BASE_URL()}/${ENTITY}/${tenderId}/createProjectConnections`, body);
}

// Response type to be determined.
export const removeConnectionData = async (tenderId: string, connectedTenderId: string): Promise<void> => {
    return await HttpDelete(`${BASE_URL()}/${ENTITY}/${tenderId}/deleteTenderConnections?connectedTenderId=${connectedTenderId}`);
}

export const deleteProjectConnection = async (projectId: string, connectionId: string): Promise<void> => {
    return await HttpDelete(`${BASE_URL()}/${PROJECTS}/deleteProjectConnections?id=${connectionId}&projectId=${projectId}`);
}

// ticket IBIS-324 https://issuetracker.deltares.nl/browse/IBIS2-324
export const getMinimalTender = async (searchInput: string, searchOptions: {take: number, skip: number}): Promise<{result: IMinimalTender[]}> => {
    return await HttpGet(`${BASE_URL()}/${TENDERS}/tender/minimal?searchInput=${searchInput}&take=${searchOptions.take}&skip=${searchOptions.skip}`);
}

// ticket CREATE TICKET
export const getMinimalProject = async (searchInput: string, searchOptions: {take: number, skip: number}): Promise<{result: IMinimalProject[]}> => {
    return await HttpGet(`${BASE_URL()}/${PROJECTS}/project/minimal?searchInput=${searchInput}&take=${searchOptions.take}&skip=${searchOptions.skip}`);
}

let getCompaniesSource: any = null;
export async function getCompanies(input: string, personId = null, cache: any) {
    if (getCompaniesSource !== null) getCompaniesSource.cancel();
    getCompaniesSource = CancelToken.source();

    let url = `${Settings.apiUrls.cci}/companies/details/search?take=10&skip=0&term=${input}`;
    if (personId) url += `&excludePerson=${personId}`;
    if (cache && cache[url]) {
        return cache[url];
    } else {
        return HttpGet(url, getCompaniesSource.token)
            .then((response: any) => {
                if (cache) cache[url] = response.result;
                return response.result;
            })
            .catch((error: any) => {
                // Canceled
                if (error.code === 499) return;
            });
    }
}

export async function getCompany(companyId: string, cancelToken = null) {
    if (!companyId) return;
    return await HttpGet(`${Settings.apiUrls.cci}/companies/${companyId}`, cancelToken);
}
export const getProjectGeneralData = async (tenderId: string): Promise<IProjectGeneralDto | undefined> => {
    return await HttpGet(`${BASE_URL()}/${PROJECTS}/${tenderId}/projects/general`);
}

export const updateProjectGeneralData = async (tenderId: string, body: any): Promise<any | undefined> => {
    return await HttpPut(`${BASE_URL()}/${ENTITY}/${tenderId}/projects/updategeneral?projectId=${body.id}`, body);
}

export const createProjectParty = async (party: ICreatePartyDto): Promise<IPartyDto> => {
    return await HttpPost(`${BASE_URL()}/${PROJECTS}/budgetParty`, party);
}

export const updateProjectParty = async (projectId: string, partyId: string, party: IUpdatePartyDto): Promise<IPartyDto> => {
    return await HttpPut(`${BASE_URL()}/${PROJECTS}/${projectId}/budgetParty/${partyId}`, party);
}
export const updateProjectBudgetTotal = async (projectId: string): Promise<IProject> => {
    return await HttpPut(`${BASE_URL()}/${PROJECTS}/BudgetTotals/${projectId}`);
}
export const createProjectTotals = async (projectId: string, partyTotals: IPartyTotalsUpdateDto): Promise<IPartyTotals> => {
    const source = CancelToken.source();
    const cancelToken = source.token;
    return await HttpPost(`${BASE_URL()}/${PROJECTS}/BudgetTotals/${projectId}`, partyTotals, cancelToken);
}

export const updateProjectTotals = async (projectId: string, partyTotals: IPartyTotalsUpdateDto): Promise<IPartyTotals> => {
    const source = CancelToken.source();
    const cancelToken = source.token;
    return await HttpPut(`${BASE_URL()}/${PROJECTS}/BudgetTotals/${projectId}`, partyTotals, cancelToken);
}

export const deleteProjectParty = async (projectId: string, partyId: string): Promise<IPartyDto> => {
    return await HttpDelete(`${BASE_URL()}/${PROJECTS}/${projectId}/budgetParty/${partyId}`);
}

export const getPrograms = async (projectId: string): Promise<IDeltaresProgramDto[]> => {
    return await HttpGet(`${BASE_URL()}/${PROJECTS}/program/${projectId}`);
};

export const createProgram = async (projectId: string, body: IDeltaresProgramCreateDto): Promise<IDeltaresProgramDto> => {
  return await HttpPost(`${BASE_URL()}/${PROJECTS}/program/${projectId}`, body);
};

export const updateDeltaresProgram = async (projectId: string, programId: string, body: IDeltaresProgramCreateDto): Promise<IDeltaresProgramDto> => {
    return await HttpPut(`${BASE_URL()}/${PROJECTS}/${projectId}/program/${programId}`, body);
};

export const deleteDeltaresProgram = async (projectId: string, programId: string): Promise<IDeltaresProgramDto> => {
    return await HttpDelete(`${BASE_URL()}/${PROJECTS}/${projectId}/program/${programId}`);
};

export const createProgramGoal = async (programId: string, newGoal: IDeltaresProgramGoalCreateDto): Promise<IOperationalGoalDto> => {
    return await HttpPost(`${BASE_URL()}/${PROJECTS}/program/${programId}/goal`, newGoal);
}

export const updateProgramGoal = async (projectId: string, goalId: string, updatedGoal: IDeltaresProgramGoalCreateDto): Promise<IOperationalGoalDto> => {
    return await HttpPut(`${BASE_URL()}/${PROJECTS}/${projectId}/program/goal/${goalId}`, updatedGoal);
}

export const deleteProgramGoal = async (projectId: string, goalId: string): Promise<IOperationalGoalDto> => {
    return await HttpDelete(`${BASE_URL()}/${PROJECTS}/${projectId}/program/goal/${goalId}`);
};

/**
 *  Sends a Maconomy id.
 * The response lets you know if a merge is available for that maconomy id.
 * @param maconomyId - Maconomy id we want to find merge options for.
 */
export const checkProjectMergeStatus = async (maconomyId: string): Promise<{maconomyId?: string, available: boolean}> => {
    return await HttpGet(`${BASE_URL()}/${PROJECT_MERGE}/checkIfMergeAvailable?maconomyId=${maconomyId}`);
}

export const getProjectsForMerge = async (tenderId: string): Promise<any> => {
    return await HttpGet(`${BASE_URL()}/${PROJECT_MERGE}/allProjectsForMerge?maconomyId=${tenderId}`);
}

export const applyProjectMerge = async (tenderId: string, project: any): Promise<IProject> => {
    return await HttpPost(`${BASE_URL()}/${PROJECT_MERGE}/createMergedProject/${tenderId}`, project);
}

export const followTender = async (tenderId: string, isFollowing: boolean): Promise<ITenderFollowersDto> => {
    if (!isFollowing) return await HttpPut(`${BASE_URL()}/${TENDERS}/${tenderId}/follow`);
    return await HttpPut(`${BASE_URL()}/${TENDERS}/${tenderId}/unfollow/`)
}

export const followProject = async (projectId: string, isFollowing: boolean): Promise<ITenderFollowersDto> => {
    if (!isFollowing) return await HttpPut(`${BASE_URL()}/${PROJECTS}/${projectId}/follow`);
        return await HttpPut(`${BASE_URL()}/${PROJECTS}/${projectId}/unfollow`);
}

export const unfollowProject = async (projectId: string): Promise<ITenderFollowersDto> => {
    return await HttpPut(`${BASE_URL()}/${PROJECTS}/${projectId}/unfollow`);
}

export const updateExportList = async (projectId: string, isSelected: boolean): Promise<IProjectExportListDto> => {
    if (!isSelected) return await HttpPut(`${BASE_URL()}/project/${projectId}/select`);
    return await HttpPut(`${BASE_URL()}/projects/${projectId}/deselect`);
}

export const removeFromExportList = async (projectId: string): Promise<IProjectExportListDto> => {
    return await HttpPut(`${BASE_URL()}/projects/${projectId}/deselect`);
}
export async function doesMaconomyIdExist(maconomyId: string, cancelToken: any) {
    return HttpGet(`${BASE_URL()}/${PROJECTS}/maconomy/${maconomyId}/exists`, cancelToken);
}

export async function getMaconomyListForProject(projectId: string) {
    return HttpGet(`${BASE_URL()}/${PROJECTS}/${projectId}/maconomyIds`);
}

export async function postMaconomyNumber(maconomyId: string, projectId: string) {
    return HttpPost(`${BASE_URL()}/${PROJECTS}/${projectId}/maconomyId/${maconomyId}`);
}

export async function deleteMaconomyNumber(maconomyId: string, projectId: string) {
    return HttpDelete(`${BASE_URL()}/${PROJECTS}/${projectId}/maconomyId/${maconomyId}`);
}

export async function getConversionToEuro(currency: string, date: string, amount: number, cancelToken: any): Promise<any> {
    return HttpGet(
        `${Settings.apiUrls.globalConfig}/data/currencies/conversion-info?target=EUR&source=${currency}&conversionDate=${date}`,
        cancelToken
    );
}

export const getContacts = async (tenderId: string): Promise<IPersonDto[]> => {
    return HttpGet(`${BASE_URL()}/${TENDERS}/${tenderId}/users`, CancelToken.source().token);
}

export const postContact = async (person: IAddPersonDto): Promise<IAddPersonDto> => {
    return HttpPost(`${BASE_URL()}/tender/contacts`, person);
}

export const deleteContact = async (tenderId: string, contactPersonId: string, userReference: string): Promise<void> => {
    return HttpDelete(`${BASE_URL()}/${TENDERS}/${tenderId}/users/${contactPersonId}/${userReference}`);
}

export async function changePermissionForPersonOnEntity(entityId: string, type: string, userId: string, role: string) {
    return HttpPut(
        `${Settings.apiUrls.tenders}/${mapEntityTypeToCollectionType(type)}/${entityId}/permissions`,
        {
            userId: userId,
            permissionRole: role,
            specialPermissions: [],
        }
    );
}

export async function getPermissionForPersonOnEntity(entityId: string, type: string, userId: string) {
    return HttpGet(
        `${Settings.apiUrls.tenders}/${mapEntityTypeToCollectionType(
            type
        )}/${entityId}/permissions/${userId}`
    );
}

export const createNewTender = async (): Promise<any> => {
    return HttpPost(`${Settings.apiUrls.tenders}/${TENDERS}/create`);
}

export const createNewTenderWithoutLois = async (): Promise<any> => {
    return HttpPost(`${Settings.apiUrls.tenders}/${TENDERS}/create?newLoisCode=false`);
}

export const postSystemTask = async (tenderId: string, task: SystemTaskDto): Promise<any> => {
    return HttpPost(`${Settings.apiUrls.tenders}/${TENDERS}/${tenderId}/tasks`, task);
}

export const postNotification = async (notification: INotificationDto): Promise<any>=> {
    HttpPost(`${Settings.apiUrls.tenders}/${TENDERS}/notifications`, notification);
}

export const updatePermissionMode = async (mode: string, id: string) => {
    return HttpPut(`${Settings.apiUrls.tenders}/tenders/${id}/permission-mode`, {mode});
}

export const archiveProject = async (projectId: string) => {
    return HttpPut(`${Settings.apiUrls.tenders}/projects/${projectId}/archive`);
}
export const UnArchiveProject = async (projectId: string) => {
    return HttpPut(`${Settings.apiUrls.tenders}/projects/${projectId}/unarchive`);
}
export const archiveTender = async (tenderId: string) => {
    return HttpPut(`${Settings.apiUrls.tenders}/tenders/${tenderId}/archive`);
}
export const UnArchiveTender = async (tenderId: string) => {
    return HttpPut(`${Settings.apiUrls.tenders}/tenders/${tenderId}/unarchive`);
}
export const AddInternalStage = async (tenderId: string, stage: number) => {
    return HttpPost(`${Settings.apiUrls.tenders}/tender/${tenderId}/internalStage/${stage}`);
}
export const AddExternalStage = async (tenderId: string, stage: number) => {
    return HttpPost(`${Settings.apiUrls.tenders}/tender/${tenderId}/externalStage/${stage}`);
}
export const AddProjectStage = async (projectId: string, stage: number) => {
    return HttpPost(`${Settings.apiUrls.projects}/project/${projectId}/stage/${stage}`);
}

export async function fetchUsers(id:string){
    return HttpGet(`${Settings.apiUrls.singleSignOn}/users/${id}`);    
}
