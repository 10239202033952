import React from "react";
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import {IFoundation} from "../../Interfaces/IFoundation";
import {IBaseProps} from "../../Interfaces/IBaseProps";
import {EPermission} from "../../Api/Dtos/EPermission";
import {hasPermission} from "../../Utils/hasPermission";
import {ITenderOverviewDto} from "../../Api/Dtos/ITenderOverviewDto";
import {PatchParams} from "../../Interfaces/PatchParams";
import {EOP} from "../../Interfaces/IPatchParams";

interface ISourceProps extends IBaseProps {
    tenderId: string;
    permissions: EPermission[];
    tender: ITenderOverviewDto;
    patch: PatchParams;
}

const LinkText = styled.a`
    color: ${(props: IFoundation) => props.theme.colors.themePrimary};
    font-size: ${(props: IFoundation) => props.theme.fontSizes.s};
    margin-left: 39px;
    align-self: flex-end;
    margin-bottom: 7px;
    cursor: pointer;
    text-decoration: none;
`
const NoLinkText = styled.span`
    color: ${(props: IFoundation) => props.theme.colors.neutralSecondary};
    font-size: ${(props: IFoundation) => props.theme.fontSizes.s};
    margin-left: 39px;
    align-self: flex-end;
    margin-bottom: 7px;
    cursor: pointer;
`

export const Source: React.FunctionComponent<ISourceProps> = (props) => {

    return (
        <div style={props.style} className={props.className}>
            <Flex mt={3}>
                <Box style={{width: 420}}>
                    <TextInput
                        label="Direct link to publication"
                        placeholder="Enter URL to publication"
                        value={props.tender?.directLinkToPublication}
                        onChanged={(value: string) => props.patch([{
                            op: EOP.Replace,
                            value: value,
                            path: '/directLinkToPublication'
                        }], props.tenderId)}
                        disabled={!hasPermission(props.permissions)}
                    />
                </Box>
                { props.tender?.directLinkToPublication ?
                    <LinkText href={props.tender?.directLinkToPublication} target="_blank">Open link in browser</LinkText>
                    :
                    <NoLinkText>Open link in browser</NoLinkText>
                }
            </Flex>
        </div>
    );
};
