import React, { useState } from 'react';
import Dialog, { DialogType } from 'ibis-design-system/lib/components/core/Dialog';
import Button from 'ibis-design-system/lib/components/core/Button';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import { selectedEntity } from '../../Utils/selectedEntity';
import { isProject } from '../../Utils/isProject';
import Flex from 'ibis-design-system/lib/components/core/Flex';
import Dropdown from 'ibis-design-system/lib/components/inputFields/Dropdown';
import { useArchivalReasons } from '../../Hooks/GlobalConfigContext';
import Settings from '../../Settings';
import { HttpPost } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import { IArchivalReasonsDto } from '../../Api/Dtos/IArchivalReasonsDto';

export function ArchiveDialog({ isOpen, handleClose, onArchive }: { isOpen: boolean, handleClose: () => void, onArchive: () => void }) {
    const [selectedOption, setSelectedOption] = useState<IArchivalReasonsDto>();
    const selected = selectedEntity();
    const selectedEntities = selected.getSelection();
    const updatedReasons = useArchivalReasons().map(reason => {
        const updatedName = reason.name.replace(/([a-z])([A-Z])/g, '$1 $2');
        return {...reason, name: updatedName};
    });
    const tenderId = selectedEntities?.map((x: any) => x.id);

    const archivalReasonArchive = async (option: IArchivalReasonsDto) => {
        const body = { reason: option.key };
        const url = `${Settings.apiUrls.tenders}/entity/${tenderId}/archivalReason`;
        const response = await HttpPost(url, body);
        return response;
    };

    return (
        <Dialog isOpen={isOpen} handleClose={handleClose} title={'Archive ' + selectedEntities?.map((x:any) => x.loisCode)}>
            <Flex>Are you sure you want to archive "{isProject() ? selectedEntities?.map((x: any) => x.projectTitle) : selectedEntities?.map((x: any) => x.assignmentTitle)}"?</Flex>
            <Box bg="white">
                <Dropdown
                    label="Reason for Archiving"
                    placeholder="Choose a reason"
                    options={updatedReasons}
                    multiSelect={false}
                    value={selectedOption ? selectedOption.text : ''}
                    onChanged={(option: any) => setSelectedOption(option)}
                />
            </Box>
            <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', top:'57px'}}>
                <div className="button-container">
                    <Button
                        variant="primary"
                        onClick={async () => {
                            onArchive();
                            handleClose();
                            await archivalReasonArchive(selectedOption!);
                        }}
                    >
                        Archive
                    </Button>
                </div>
                <Button style={{ margin: 20 }} onClick={handleClose}>Cancel</Button>
            </div>
        </Dialog>
    );
}
