import { updateExportList } from "../Api/TenderRequests";

export const UpdateExportList = (selectedEntities: any, updateEntities: any) => {
    const { selectedEntitiesAreAllInExportList, allUserContextNotSelected } = (selectedEntities || []).reduce((acc: any, entity: any) => {
          acc.selectedEntitiesAreAllInExportList = acc.selectedEntitiesAreAllInExportList && entity.userContext?.isSelected === true;
          acc.allUserContextNotSelected = acc.allUserContextNotSelected && entity.userContext?.isSelected === false;
          return acc;
        },
        { selectedEntitiesAreAllInExportList: true, allUserContextNotSelected: true }
    );
      
    const isAllFalseOrTrue = allUserContextNotSelected || selectedEntitiesAreAllInExportList;

    const { selectedArray, notSelectedArray } = (selectedEntities || []).reduce((acc: any, entity: any) => {
        if (entity.userContext?.isSelected === true) {
            acc.selectedArray.push(entity);
        } else {
            acc.notSelectedArray.push(entity);
            if (!isAllFalseOrTrue) updateExportList(entity.id, entity.userContext.isSelected);
        }
        return acc;
        },
        { selectedArray: [], notSelectedArray: [] }
    );

    if (isAllFalseOrTrue) selectedEntities.forEach((entity: any) => updateExportList(entity.id, entity.userContext.isSelected));

    const updatedEntities = isAllFalseOrTrue ? selectedArray : [...notSelectedArray, ...selectedArray];

    updateEntities(updatedEntities);

    return updatedEntities;
};