import React, {useState} from "react";
import {IBaseProps} from "../../Interfaces/IBaseProps";
import {SemiBold} from "../SemiBold";
import Flex from 'ibis-design-system/lib/components/core/Flex';
import Text from 'ibis-design-system/lib/components/Atoms/Text';
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';

const Range = styled.input`
    height: 24px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    background-color: #f3f2f1;
  
  :focus {
    outline: none;
  }
  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: ${(props: any) => props.danger ? "#A80000" : "#C8C8C8"};
    border-radius: 2px;
  }
  ::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid ${(props: any) => props.danger ? "#A80000" : "#666666"};
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
  :focus::-webkit-slider-runnable-track {
    background: ${(props: any) => props.danger ? "#A80000" : "#C8C8C8"};
  }
`

interface IRangeInputProps extends IBaseProps {
    label?: string;
    value?: number;
    onChange?: (value: number) => void;
    danger?: boolean;
    errorMessage?: string;
    disabled?: boolean;
}

export const RangeInput: React.FunctionComponent<IRangeInputProps> = (props) => {

    const [value, setValue] = useState<number>(props.value ? props.value : 0);

    const updateValue = () => {
        if (!props.onChange) return;
        props.onChange(Number(value));
    }

    return (
        <div style={props.style} className={props.className}>
            {props.label && <SemiBold>{props.label}</SemiBold>}

            <Flex alignItems="center">
                <Range danger={props.danger} type="range" value={value} onChange={(e: any) => props.disabled ? undefined : setValue(e.target.value)} onMouseUp={(e: any) => props.disabled ? undefined : updateValue()} />
                <span style={{marginLeft: 12, width: 25, marginBottom: 4, color: "#333333"}}><Text>{value}</Text></span>
            </Flex>

            {props.danger &&
                <div style={{marginTop: -20, color: "#A80000"}}>
                    <Text>{props.errorMessage}</Text>
                </div>
            }
        </div>
    );
};
