import React, {useState} from "react";
import Box from 'ibis-design-system/lib/components/core/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';
import Button from 'ibis-design-system/lib/components/core/Button';
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import TimeLine from "ibis-design-system/lib/components/core/TimeLine";
import Dropdown from "ibis-design-system/lib/components/inputFields/Dropdown";
import {GreyBox} from "../GreyBox";
import {AddExternalStage, AddInternalStage, AddProjectStage, removeFromExportList} from "../../Api/TenderRequests";
import {useExternalStages, useInternalStages, useProjectStages} from "../../Hooks/GlobalConfigContext";
import {IStageDto} from "../../Api/Dtos/IStageDto";
import {EPermission} from "../../Api/Dtos/EPermission";
import { selectedEntity } from "../../Utils/selectedEntity";
import { isProject } from "../../Utils/isProject";
import { convertToHumanReadable } from "../../Utils/convertToHumanReadableText";
import { convertExternalStages, convertInternalStages, convertProjectStages } from "../../Constants";
import moment from "moment";

const PSpanW = styled.span`
  width: 261px;
`

interface IAddProgressProps {
    tenderId: string;
    permissions: EPermission[];
    item: any;
}
// TODO: make this component more generic, reason for this first approach is beceause of a hurry to go live.
export const AddProgress: React.FunctionComponent<IAddProgressProps> = (props) => {
    const getInternalStages = useInternalStages();
    const getExternalStages = useExternalStages();
    const projectStages = useProjectStages();
    const [selectedDropdownOption, setSelectedDropdownOption] = useState<string>();
    const [selectedStage, setSelectedStage] = useState<number | undefined>();
    const [isAdding, setIsAdding] = useState(false);

    const selected = selectedEntity();
    const selectedEntities = selected.getSelection();
    // todo: ensure to get projectId from project itself and not via selectedEntities.map()
    const projectId = selectedEntities?.map((x:any) => x.id)
    const internalStages = selectedEntities?.map((x:any) => x.internalStages).flat();
    const externalStages = selectedEntities?.map((x:any) => x.externalStages).flat();
    const timelineProjectStages = selectedEntities?.map((x: any) => x.stages).flat();

    const formattedInternalStages = internalStages!.map(obj => ({
        label: convertInternalStages(obj?.stage), 
        date: obj?.date,
        variant: "top",
        alwaysVisible: false
    }));

    const formattedExternalStages = externalStages!.map(obj => ({
        label: convertExternalStages(obj?.stage), 
        date: obj?.date,
        variant: "bottom",
        alwaysVisible: false
    }));

    const formattedProjectStages = timelineProjectStages!.map(obj => ({
        label: convertProjectStages(obj?.stage), 
        date: obj?.date,
        variant: "top",
        alwaysVisible: false
    }))
    
    
    const [formattedInternalStage, setFormattedInternalStage] = useState(formattedInternalStages);
    const [formattedExternalStage, setFormattedExternalStage] = useState(formattedExternalStages);
    const [formattedProjectStage, setFormattedProjectStage] = useState(formattedProjectStages);
    
    // todo: put this in a usestate
    let dropdownOptions: IStageDto[] = [];
    
    const allTenderStages = [...formattedInternalStage, ...formattedExternalStage];

    const isProjectStages = selectedDropdownOption === "projectStages";
    const isInternalStages = selectedDropdownOption === "internalStages";
    const isExternalStages = selectedDropdownOption === "externalStages";
    
    if (isProjectStages) {
        dropdownOptions = projectStages.map(item => {
          return {
            ...item,
            name: convertToHumanReadable(item.name)
          };
        });
      } else if (isInternalStages) {
        dropdownOptions = getInternalStages.map(item => {
          return {
            ...item,
            name: convertToHumanReadable(item.name)
          };
        });
      } else if (isExternalStages) {
        dropdownOptions = getExternalStages.map(item => {
          return {
            ...item,
            name: convertToHumanReadable(item.name)
          };
        });
      }

    const handleDropdownChange = (selectedOption: number) => {
        setSelectedStage(selectedOption);
    };

    const onClickAddTenderStages = () => {
        const newStage = {
            label: isInternalStages ? convertInternalStages(selectedStage ?? 0) : convertExternalStages(selectedStage ?? 0),
            date: moment().toISOString(),
            variant: isInternalStages ? "top" : "bottom",
            alwaysVisible: false
        };
          
        if (isInternalStages) {
            setFormattedInternalStage(prevStages => [...prevStages, newStage]);
            AddInternalStage(props.tenderId, selectedStage ?? 0);
        } else if (isExternalStages) {
            setFormattedExternalStage(prevStages => [...prevStages, newStage]);
            AddExternalStage(props.tenderId, selectedStage ?? 0);
        }
        setIsAdding(false);
    };

    const onClickAddProjectStages = () => {
        const newProjectStage = {
            label: convertProjectStages(selectedStage ?? 0),
            date: moment().toISOString(),
            variant: "top",
            alwaysVisible: false
        };
        setFormattedProjectStage(prevStages => [...prevStages, newProjectStage]);
        AddProjectStage(String(projectId), selectedStage ?? 0);
        if (selectedStage !== undefined && props.item.userContext?.isSelected && selectedStage < 6) removeFromExportList(props.item.id);
        setIsAdding(false);
    };

    const cancelProgress = () => {
        setIsAdding(false);
        setSelectedStage(undefined);
    }

    return(
        <Box>
            {!isProject() 
            ? <TimeLine events={allTenderStages} topLabel='Internal progress' bottomLabel='External status'/> 
            : <TimeLine events={formattedProjectStage} topLabel='Project stage' />
            }
        <Flex marginTop="16px">
            {isProject() ? (
            <Button
                variant="commandBar"
                icon="Add"
                onClick={() => {
                    setSelectedDropdownOption("projectStages");
                    setIsAdding(true);
                }}
            >
                Set new project stage
            </Button>
            ) : (
            <Flex>
                <Button
                variant="commandBar"
                icon="Add"
                onClick={() => {
                    setSelectedDropdownOption("internalStages");
                    setIsAdding(true)
                }}
                >
                Set new internal progress state
                </Button>
                <span style={{ marginLeft: 25 }}>
                <Button
                    variant="commandBar"
                    icon="Add"
                    onClick={() => {
                        setSelectedDropdownOption("externalStages");
                        setIsAdding(true)
                    }}
                >
                    Set new external status
                </Button>
                </span>
            </Flex>
            )}
        </Flex>
        {isAdding && (
            <GreyBox>
                <Flex>
                    <PSpanW>
                        <Dropdown 
                            label="Process/stage"
                            options={dropdownOptions}
                            multiSelect={false} 
                            onChanged={(value: any) => handleDropdownChange(value.key)} 
                        />
                    </PSpanW>
                </Flex>
                <Flex mt={3} mb={1}>
                    { !isProject() ?
                        <Button 
                            fullWidth 
                            variant="primary" 
                            onClick={onClickAddTenderStages}
                        >
                            Apply {isInternalStages ? "internal" : "external"} progress
                        </Button>
                        :            
                        <Button 
                            fullWidth 
                            variant="primary" 
                            onClick={onClickAddProjectStages}
                        >
                        Apply {isProjectStages ? "project stage" : ""}
                        </Button> 
                    }
                        <span style={{marginLeft: 8}}><Button onClick={cancelProgress}>Cancel</Button></span>
                    </Flex>
            </GreyBox>
        )}
        </Box>
    )
}
