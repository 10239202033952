import React from "react";
import Flex from "ibis-design-system/lib/components/Atoms/Flex";
import {Icon} from "office-ui-fabric-react";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IFoundation} from "../../Interfaces/IFoundation";
import Button from 'ibis-design-system/lib/components/core/Button';

const Banner = styled.div`
  padding: 10px 28px 10px 14px;
  background-color: ${(props: IFoundation) => props.theme.colors.neutralQuaternaryAlt};
  display: flex;
  justify-content: space-between;
`

interface ISetShowMergeBannerProps {
    onShowMerge: () => void;
    onCancel: () => void;
}

export const SetShowMergeBanner: React.FunctionComponent<ISetShowMergeBannerProps> = (props) => {
    return (
        <Banner>
            <Flex alignItems="center">
                <Icon iconName="Info" />
                <Flex ml={1}>Duplicates found, please review merge proposal.</Flex>
            </Flex>

            <Flex alignItems="center">
                <Button onClick={props.onShowMerge}>Show possible duplicates</Button>
                <Icon style={{marginLeft: 8, cursor: "pointer"}} iconName="ChromeClose" onClick={props.onCancel} />
            </Flex>
        </Banner>
    );
};
