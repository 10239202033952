import React, {useState} from "react";
import {GreyBox} from "../../GreyBox";
import Dropdown from 'ibis-design-system/lib/components/inputFields/Dropdown';
import TextInput from 'ibis-design-system/lib/components/inputFields/TextInput';
import Flex from 'ibis-design-system/lib/components/core/Flex';
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import Button from "ibis-design-system/lib/components/core/Button";
import {IMinimalProject, ProjectPicker} from "./ProjectPicker";
import {IProjectConnectionCreateDto, IProjectConnectionDto} from "../../../Api/Dtos/IConnectionDto";
import {request} from "../../../Utils/request";
import {createProjectConnectionData, getMinimalProject} from "../../../Api/TenderRequests";
import {useProjectConnectionType} from "../../../Hooks/GlobalConfigContext";
import {IProject} from "../../../Api/Dtos/IProjectDto";
import {isProject} from "../../../Utils/isProject";

// TODO move this to the settings app, from where it can be fetched and exposed using React Context.

const Sdiv = styled.span`
  width: 123px;
`
const Mdiv = styled.span`
  width: 216px;
  margin-left: 20px;
`
const Ldiv = styled.span`
  width: 320px;
  margin-left: 20px;
`

const INIT_CONNECTION: IProjectConnectionCreateDto ={
    id: "",
    projectId: "",
    note: "",
}

export interface IOption {key: string; text: string; title: string; disabled: boolean; value: any}
export interface IMultiselectOption {key: string; text: string; selected: boolean; title: string; disabled: boolean; value: any}
interface IAddConnectionProps {
    projectId: string;
    tenderId: string;
    // Triggered when a user clicks cancel. Used to close the form.
    cancel: () => void;
    // Triggered when a new connection has been created. Used to close the form.
    onAdded: () => void;
    project: IProject,
}
export const AddConnection: React.FunctionComponent<IAddConnectionProps> = (props) => {

    const projectConnectionTypes = useProjectConnectionType();
    const [newConnection, setNewConnection] = useState<IProjectConnectionCreateDto>(INIT_CONNECTION);

    const findChanged = (changed: IOption): void => {
        const tmpConnection = {...newConnection};
        tmpConnection.type = projectConnectionTypes.find( type => type.id === changed.key);
        return setNewConnection(tmpConnection);
    }

    const updateLinkedTenders = (projects: string[] | undefined) => {
        const tmpNewConnection = {...newConnection};
        if (!projects || !projects.length) {
            tmpNewConnection.id = "";
        } else {
            tmpNewConnection.id = projects[0];
        }
        setNewConnection(tmpNewConnection);
    };

    const updateNote = (value: string): void => {
        const tmpNewConnection = {...newConnection};
        tmpNewConnection.note = value;
        setNewConnection(tmpNewConnection);
    }

    // Placeholder until the real request replaces it.
    const getProjects = async (searchInput: string): Promise<IMinimalProject[]> => {
        if (!searchInput) { return []; }
        const {result, error} = await request<{result: IMinimalProject[]}>(getMinimalProject(searchInput, {take: 7, skip: 0}));
        if (!result || !result.result || error) {

            console.log("An error occurred attempting to fetch minimal projects");

            return [];

        }
        return result.result;

    };

    const createNewConnection = async (): Promise<void> => {
        if (!newConnection.id || !newConnection.type || !newConnection.type.label) { return; }

        const tmpNewConnecion = {...newConnection};
        tmpNewConnecion.projectId = props.projectId;
        const { result, error } = await request<IProjectConnectionDto | undefined>(createProjectConnectionData(isProject() ? props.project.linkedTender as string : props.tenderId, tmpNewConnecion));
        if (!result || error) {
            console.error(`Something went wrong attempting to create a new Connection for project with ID ${newConnection.projectId}`);
            return;
        }

        props.onAdded();
    };

    return (
        <GreyBox>
            <Flex>
                <Sdiv>
                    <Dropdown
                        label="Type"
                        options={projectConnectionTypes}
                        onChanged={ (change: IOption) => findChanged(change)}
                        multiSelect={false}
                        selected={[newConnection.type ? newConnection.type.id : ""]}
                    />
                </Sdiv>
                <Mdiv>
                    <ProjectPicker
                        label="Project"
                        fetchProjects={getProjects}
                        onChange={(projects: string[] | undefined) => updateLinkedTenders(projects)}
                    />
                </Mdiv>
                <Ldiv>
                    <TextInput
                        label="Note"
                        placeholder="Relational note"
                        onChange={(e: string) => updateNote(e)}
                    />
                </Ldiv>
            </Flex>

            <Flex mt={3} mb={1}>
                <Button variant="primary" onClick={() => createNewConnection()}>
                    Add connection
                </Button>
                <span style={{marginLeft: 8}}><Button onClick={props.cancel}>Cancel</Button></span>
            </Flex>
        </GreyBox>
    );
};
