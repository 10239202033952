/**
 * The Permissions in this enum match the permissions described in the documentation: https://vt-ibis002.domain.deltares.nl/05-backend-concepts/01-permissions.html#shared-object-permissions
 * In case the documentation is no longer online, it's the following project in devops: https://ibistools.visualstudio.com/ibistools-documentation
 */
export enum EPermission {
    Create = "create",
    Read = "read",
    Contribute = "contribute",
    Update = "update",
    Archive = "archive",
    SetPermissions = "set-permissions",
    SetPermissionsMode = "set-permissions-mode",
    TestDisabled = "test-disabled"
}