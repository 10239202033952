import React, {useEffect, useMemo, useState} from "react";
import Map from 'ibis-design-system/lib/components/map/Map';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import Text from 'ibis-design-system/lib/components/Atoms/Text';
import TimeLine from 'ibis-design-system/lib/components/core/TimeLine';
import {DescriptionBlock, IAssignmentDescriptionBlockProps} from "../Components/AssignmentDescriptionBlock";
import {SemiBold} from "../Components/SemiBold";
import {Tiles} from "../Components/OverviewTab/Tiles";
import {fetchUsers, getContacts} from "../Api/TenderRequests";
import {getFinanciersFromProject} from "../Utils/getFinanciersFromProject";
import {PartyOverview} from "../Components/OverviewTab/PartyOverview";
import {ActivityRoot} from "../Components/OverviewTab/Activities/ActivityRoot";
import {IPersonDto} from "../Api/Dtos/IPersonDto";
import {dividePeopleByRole, IPeopleInRoles} from "../Utils/dividePeopleByRole";
import {PersonCard} from "../Components/PersonCard";
import {ITenderOverviewDto} from "../Api/Dtos/ITenderOverviewDto";
import GeoJson from 'ibis-design-system/lib/components/map/GeoJson';
import Settings from "../Settings";
import {HttpGet} from "ibis-design-system/lib/HelperFunctions/HttpHelper";
import Marker from 'ibis-design-system/lib/components/map/Marker';
import {isProject} from "../Utils/isProject";
import { Persona, PersonaSize } from "office-ui-fabric-react";
import { request } from "../Utils/request";
import { getBudgetPartiesDeltares, getBudgetPartiesFromProject } from "../Utils/getBudgetPartiesFromProject";
import { selectedEntity } from "../Utils/selectedEntity";
import { convertExternalStages, convertInternalStages, convertProjectStages } from "../Constants";
import { IProject } from "../Api/Dtos/IProjectDto";
import { PeopleList } from "../Components/PeopleList";
import { EPersonRole } from "../Api/Dtos/IAddPersonDto";

interface IOverviewProps {
    item: ITenderOverviewDto;
    overflowActive: any;
    type: any;
    setMessage: any;
    patch: any;
}

export const Overview: React.FunctionComponent<IOverviewProps> = (props) => {

    const [people, setPeople] = useState<IPeopleInRoles>();
    const [cityLocation, setCityLocation] = useState({ lat: 51.9857621, lng: 4.3806002 })
    const [created, setCreated] = useState<any>([]);
    const [modified, setModified] = useState<any>([]);

    const selected = selectedEntity();
    const selectedEntities = selected.getSelection();
    const internalStages = selectedEntities?.map((x:any) => x.internalStages).flat();
    const externalStages = selectedEntities?.map((x:any) => x.externalStages).flat();
    const timelineProjectStages = selectedEntities?.map((x: any) => x.stages).flat();

    const createdOn = new Date(props.item.creationDate ?? 0).toLocaleDateString('nl-NL');
    const lastModifiedOn = new Date(props.item.lastModificationDate ?? 0).toLocaleDateString('nl-NL');

    useEffect(() => {
        getUserData();
    }, []);

    const getUserData = async () => {

        if (props.item.createdBy) {
            const user = await request(await fetchUsers(props.item.createdBy));
            if (user.result && !user.error) setCreated(user.result);
        }

        if (props.item.lastModifiedBy) {
            const user = await request(await fetchUsers(props.item.lastModifiedBy));
            if (user.result && !user.error) setModified(user.result);
        }
    }
    
    const project = props.item.linkedProject;

    const financiers = useMemo(() => getFinanciersFromProject(project), [project]);
    const budgetParties = useMemo(() => getBudgetPartiesFromProject(project), [project]);
    const deltaresBudgetPraty = useMemo(() => getBudgetPartiesDeltares(project), [project]);
    const getDeltaresRole = deltaresBudgetPraty?.map(x => x.role);

    useEffect(() => {
        getContacts(isProject()? props.item.linkedTender! : props.item.id).then((result: IPersonDto[]) => {
            const peopleByRole = dividePeopleByRole(result);
            setPeople(peopleByRole);
        }).catch(e => console.log(e));
    }, [props.item.id]);

    useEffect(() => {
        if (!props.item.locations || !props.item.locations.length) return;
        const location = props.item.locations[0];
        if (!location.cityId) return;
        HttpGet(`${Settings.apiUrls.globalConfig}/cities/${location.cityId}`).then((response: any) => {
            if (response && response.longitude) {
                setCityLocation({lat: response.latitude, lng: response.longitude})
            }
        });
    }, []);

    const memoizedMap = useMemo(() => {
        if (!props.item.locations || !props.item.locations.length) return <Box height="200px" width="100%" style={{ position: 'relative' }}><Map /></Box>;
        const location = props.item.locations[0];

        const locationNameArray = [];
        if (location.country) locationNameArray.push(location.country);
        if (location.city) locationNameArray.push(location.city);

        return (
            <Box height="200px" width="100%" style={{ position: 'relative' }}>
                <Map center={cityLocation}>
                    {cityLocation.lat !== 51.9857621 ? <Marker id="marker" position={cityLocation} /> : null}
                    {location.countryId ? (
                        <GeoJson
                            dataUrl={`${Settings.apiUrls.globalConfig}/countries/${location.countryId}/shape.json`}
                        />
                    ) : null}
                </Map>
                {locationNameArray.length > 0 ? (
                    <Box
                        style={{ position: 'absolute', bottom: '2px', left: '75px' }}
                        px={1}
                        py="4px"
                        bg="rgba(255,255,255,0.75)"
                    >
                        <Text as="span" color="themePrimary">
                            {locationNameArray.join(': ')}
                        </Text>
                    </Box>
                ) : null}
            </Box>
        );
    }, [cityLocation]);

    
    const tenderStages = (internalStages?.map(item => ({
        label: convertInternalStages(item?.stage),
        date: item?.date,
        variant: "top",
        alwaysVisible: false
      })) || []).concat(externalStages?.map(item => ({
        label: convertExternalStages(item?.stage),
        date: item?.date,
        variant: "bottom",
        alwaysVisible: false
      })) || []);

      const projectstages = timelineProjectStages?.map(obj => ({
        label: convertProjectStages(obj?.stage), 
        date: obj?.date,
        variant: "top",
        alwaysVisible: false
    }))      
      
    const createDescriptionBlock = useMemo(() => {

        const descBlockData: IAssignmentDescriptionBlockProps = {
            header: "",
            description: "",
        }

        if (isProject()) {
            descBlockData.description = props.item.narrativeDescription ? props.item.narrativeDescription : "No narrative description yet, please complete the project information.";
            descBlockData.header = "Narrative description";
        } else {
            descBlockData.description = props.item.objectives ? props.item.objectives : "No assignment yet, please complete the tender information.";
            descBlockData.header = "Assignment";
        }

        return (
            <DescriptionBlock
                contentStyle={{padding: "10px 20px"}}
                description={descBlockData.description}
                header={descBlockData.header}
            />
        );
    }, [isProject()]);

    const DateEmployee = new Date(props.item.tenderDeadLine!);

    const topLabel = isProject() ? 'Project stage' : 'Internal progress';
    const bottomLabel = isProject() ? '' : 'External status';

    return (
        <div style={{width: "100%", overflowY: "scroll"}}>

            {/* The map and timeline. */}
            {/* {memoizedMap} */}

            <Box width="100%">
                <TimeLine events={isProject() ? projectstages : tenderStages} topLabel={topLabel} bottomLabel={bottomLabel}/>
            </Box>

            {/* Inner page content. */}
            <Box padding="30px" width="100%">

                {/* Financier, Client, Beneficiary cards + Deltares Role */}
                <Flex marginTop="30px">
                    <div style={{flex: 1}}>
                        <SemiBold>Financier</SemiBold>
                        <Box marginTop="8px" marginLeft="8px">
                            {
                                financiers?.map(fn => <PartyOverview key={fn.companyId} companyId={fn.companyId} financier={true}/>)
                            }
                        </Box>
                    </div>

                    <div style={{flex: 1}}>
                        <SemiBold>Consortium partners</SemiBold>
                        <Box marginTop="8px" marginLeft="8px">
                            {
                                budgetParties?.map(fn => <PartyOverview key={fn.companyId} companyId={fn.companyId} financier={false}/>)
                            }
                        </Box>
                        <SemiBold style={{marginTop: 18}}>Associate/Partner</SemiBold>
                        <Box marginTop="8px" marginLeft="8px">
                            {
                                project?.beneficiary && <PartyOverview companyId={project.beneficiary} />
                            }
                        </Box>
                    </div>

                    <div style={{flex: 1}}>
                        <SemiBold>Deltares' role</SemiBold>
                        <Text style={{marginTop: 0}}>{getDeltaresRole}</Text>
                    </div>
                    
                    { !isProject() ?
                    <div style={{flex: 1}}>
                        <SemiBold>Tender Deadline</SemiBold>
                        <Text style={{marginTop: 0}}>{DateEmployee.toLocaleDateString()}</Text>
                    </div>
                    :
                        ''
                    }

                        { isProject() ?
                            <div style={{flex: 1}}>
                                <SemiBold>Lois code</SemiBold>
                                <Text style={{marginTop: 0}}>{props.item.loisCode}</Text>
                            </div>
                            :
                            <div style={{flex: 1}}>
                                <SemiBold>External reference</SemiBold>
                                <Text style={{marginTop: 0}}>{props.item.externalReferenceCode}</Text>
                            </div>
                        }

                </Flex>

                {/* Regional coordinator, managers, Other contacts - persona's */}
                <Flex marginTop="30px" marginBottom="8px">
                    <div style={{flex: 1}}>
                        <SemiBold>Regional coordinator(s)</SemiBold>
                        <Box marginTop="8px" marginLeft="8px">
                            {people && people.RegionalCoordinator && people.RegionalCoordinator.map((contact: IPersonDto, index: number) => {
                                return <PersonCard style={{marginTop: index > 0 ? 7 : 0}} key={"regional-coordinator" + contact.userReference} contactPersonId={contact.id} userId={contact.userReference}
                                                   tenderId={props.item.id} entityType={props.item.type} userContext={props.item.userContext} />
                            })}
                        </Box>
                    </div>

                    <div style={{flex: 1}}>
                        <SemiBold>Manager(s)</SemiBold>
                        <Box marginTop="8px" marginLeft="8px">
                            {people && people.DepartmentHead && people.DepartmentHead.map((contact: IPersonDto, index: number) => {
                                return <PersonCard style={{marginTop: index > 0 ? 7 : 0}} key={"department-head" + contact.userReference} contactPersonId={contact.id} userId={contact.userReference}
                                                   tenderId={props.item.id} entityType={props.item.type} userContext={props.item.userContext} />
                            })}
                            {people && people.UnitHead && people.UnitHead.map((contact: IPersonDto, index: number) => {
                                return <PersonCard style={{marginTop: index || (people.DepartmentHead && people.DepartmentHead.length) > 0 ? 7 : 0}} key={"unit-head" + contact.userReference} contactPersonId={contact.id} userId={contact.userReference}
                                                   tenderId={props.item.id} entityType={props.item.type} userContext={props.item.userContext} />
                            })}
                        </Box>
                    </div>
                    { isProject()? 
                    <div>
                        <PeopleList
                            label={"Project Teams"}
                            role={EPersonRole.ProjectTeam}
                            externalTeamMembers={project?.externalTeamMembers?.map((x:any) => x)}
                            people={[]}
                            tenderId={props.item.id}
                            entityType={props.item.type}
                            addPerson={() =>""}
                            deletePerson={() => ""}
                            userContext={props.item.userContext}
                            showOptions={false}
                            isOverview={true}
                        />
                    </div>
                    :      
                    <div style={{flex: 1}}>
                        <SemiBold>Tender lead(s)</SemiBold>
                        <Box marginLeft="8px" marginTop="8px">
                            {people && people.TenderLead && people.TenderLead.map((contact: IPersonDto) => {
                                return <PersonCard key={"other-contacts" + contact.userReference} contactPersonId={contact.id} userId={contact.userReference}
                                                   tenderId={props.item.id} entityType={props.item.type} userContext={props.item.userContext} />
                            })}
                        </Box>
                    </div>
                    }
                    { !isProject()??
                    <div style={{flex: 1}}>
                        <SemiBold>Other Contacts</SemiBold>
                        <Box marginLeft="8px" marginTop="8px">
                            {people && people.OtherContacts && people.OtherContacts.map((contact: IPersonDto) => {
                                return <PersonCard key={"other-contacts" + contact.userReference} contactPersonId={contact.id} userId={contact.userReference}
                                                   tenderId={props.item.id} entityType={props.item.type} userContext={props.item.userContext} />
                            })}
                        </Box>
                    </div>
                    }
                </Flex>

                {/* Assignment description block */}
                {createDescriptionBlock}

                {/* Latest activity block */}
                <Box marginTop="30px">
                    <ActivityRoot {...props} />
                </Box>

                {/* Created and last modified by block */}
                <Box style={{marginTop:"30px", display: 'flex', gap: '100px', color: '#adb5bd'}}>
                <Box style={{display: 'flex', color: '#adb5bd'}}>
                    <div>Created on {createdOn} by: </div>
                    <Box marginLeft="8px"> 
                        <Persona size={PersonaSize.size8} text={created.name}/>
                    </Box>
                </Box>
                <Box style={{display: 'flex', color: '#adb5bd'}}>
                    <div>Last modified on {lastModifiedOn} by: </div>
                    <Box marginLeft="8px"> 
                        <Persona size={PersonaSize.size8} text={modified.name}/>
                    </Box>
                </Box>
                </Box>
            </Box>
        </div>
    );
};