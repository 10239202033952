import React from 'react';
import {
    CallIn,
    CallOut,
    MailIn,
    MailOut,
    Note,
    SocialIn,
    SocialOut,
    SupportIn,
    SupportOut,
    VisitIn,
    VisitOut
} from 'ibis-design-system/lib/Images/ActionIcons';
import {EPersonRole} from "./Api/Dtos/IAddPersonDto";
import { isProject } from './Utils/isProject';

export const getCollectionTabs = () => isProject() ? ['Activity', 'Start date'] : ['Activity', 'Deadline'];

export const PUBLIC_PERMISSION_MODE: string = 'Public';
export const SHARED_PERMISSION_MODE: string = 'Shared';
export const PRIVATE_PERMISSION_MODE: string = 'Private';

export const getStateIcon = (state: any): string =>
    ({
        [PUBLIC_PERMISSION_MODE]: 'Globe',
        [SHARED_PERMISSION_MODE]: 'ReminderGroup',
        [PRIVATE_PERMISSION_MODE]: 'ReminderPerson'
    }[state]);

/**
 * Get the current step name associated with a status number for a tender.
 * @param step - string
 * @returns string
 */
export function getStep(step: number): string {
    switch (step) {
        case 0:
            return "Opportunity Identified";
        case 1:
            return "Go No Go By Regional Coordinator";
        case 2:
            return "Go No Go By Department Head";
        case 3:
            return "Lead Identified";
        case 4:
            return "Lead Contact Set";
        case 5:
            return "Proposal Submitted";
        case 6:
            return "Archived";

        default:
            return "Unknown step";
    }
}

export function mapEntityTypeToCollectionType(type: string) {
    if (type === 'tender') return 'tenders';
    return 'projects';
}

export const ACTIVITY_TYPES = [
    { id: 'MailIn', label: 'Mail IN', icon: <MailIn /> },
    { id: 'MailOut', label: 'Mail OUT', icon: <MailOut /> },
    {
        id: 'VisitIn',
        label: 'Visit IN',
        icon: <VisitIn />
    },
    {
        id: 'VisitOut',
        label: 'Visit OUT',
        icon: <VisitOut />
    },
    // {
    //     id: 'SocialNetworkIn',
    //     label: 'Social network IN',
    //     icon: <SocialIn />
    // },
    // {
    //     id: 'SocialNetworkOut',
    //     label: 'Social network OUT',
    //     icon: <SocialOut />
    // },
    {
        id: 'CallIn',
        label: 'Call IN',
        icon: <CallIn />
    },
    {
        id: 'CallOut',
        label: 'Call OUT',
        icon: <CallOut />
    },
    {
        id: 'SupportIn',
        label: 'Support IN',
        icon: <SupportIn />
    },
    {
        id: 'SupportOut',
        label: 'Support OUT',
        icon: <SupportOut />
    },
    { id: 'Note', label: 'Note', icon: <Note /> }
];

export const TIMES = [
    { key: '8:00', text: '8:00' },
    { key: '8:30', text: '8:30' },
    { key: '9:00', text: '9:00' },
    { key: '9:30', text: '9:30' },
    { key: '10:00', text: '10:00' },
    { key: '10:30', text: '10:30' },
    { key: '11:00', text: '11:00' },
    { key: '11:30', text: '11:30' },
    { key: '12:00', text: '12:00' },
    { key: '12:30', text: '12:30' },
    { key: '13:00', text: '13:00' },
    { key: '13:30', text: '13:30' },
    { key: '14:00', text: '14:00' },
    { key: '14:30', text: '14:30' },
    { key: '15:00', text: '15:00' },
    { key: '15:30', text: '15:30' },
    { key: '16:00', text: '16:00' },
    { key: '16:30', text: '16:30' },
    { key: '17:00', text: '17:00' },
    { key: '17:30', text: '17:30' }
];

// The amount of days before a tile on the overview page displays a warning notice on time related tiles.
export const TIME_WARNING = 7;

export const PERMISSIONS = [
    { key: 'NoAccess', label: 'No access', description: 'Can not view' },
    {
        key: 'Observer',
        label: 'Observer',
        description: 'Can only view'
    },
    { key: 'Contributor', label: 'Contributor', description: 'Can add messages and documents' },
    {
        key: 'Editor',
        label: 'Editor',
        description: 'Can edit all information'
    },
    {
        key: 'Owner',
        label: 'Owner',
        description: 'No limitations, can archive'
    }
];

export const getTaskTextByRole = (role: EPersonRole, title: string): string => {
    switch (role) {
        case EPersonRole.RegionalCoordinator:
            return `Please asses tender "${title}" and assign department head or register internal progress.`;
        case EPersonRole.DepartmentHead:
            return `Please asses tender "${title}" and assign Tender lead or register internal progress.`;
        case EPersonRole.TenderLead:
            return `Please asses tender "${title}" and assign Tender lead or register internal progress.`;
        default: return `You have been assigned to a tender "${title}".`
    }
}

export function convertProjectStages(stage: number): string {
    switch (stage) {
        case 1:
            return "Project Draft";
        case 2:
            return "Not Started";
        case 3:
            return "Pending Notification";
        case 4:
            return "Pending Editing";
        case 5:
            return "Pending Approval";
        case 6:
            return "Published";
        case 7:
            return "Excluded From Publishing";
        default:
            return "";
    }
}

export function convertInternalStages(stage: string | number): string {
    switch (stage) {
        case "NotSet":
        case "Not Set":
        case 0:
            return "Not Set";
        case "SentToRegionalManager":
        case "Sent ToRegional Manager":
        case 1:
            return "Sent To Regional Manager";
        case "SentToDepartmentManager":
        case "Sent To Department Manager":
        case 2:
            return "Sent To Department Manager";
        case "TenderLeadAssigned":
        case "Tender Lead Assigned":
        case 3:
            return "Tender Lead Assigned";
        case "PreparingExpressionOfInterest":
        case "Preparing Expression Of Interest":
        case 4:
            return "Preparing Expression Of Interest";
        case "ExpressionOfInterestSubmitted":
        case "Expression Of InterestSubmitted":
        case 5:
            return "Expression Of Interest Submitted";
        case "ShortListed":
        case "Short Listed":
        case 6:
            return "Short Listed";
        case "PreparingProposal":
        case "Preparing Proposal":
        case 7:
            return "Preparing Proposal";
        case "ProposalSubmitted":
        case "Proposal Submitted":
        case 8:
            return "Proposal Submitted";
        case "ContractNegotiations":
        case "Contract Negotiations":
        case 9:
            return "Contract Negotiations";
        case "Archived":
        case 10:
            return "Archived";
        default:
            return "";
    }
}

export function convertExternalStages(stage: string | number): string {
    switch (stage) {
        case "NotSet":
        case "Not Set":
        case 0:
            return "Not Set";
        case "InPipeline":
        case "In Pipeline":
        case 1:
            return "In Pipeline";
        case "ActiveProject":
        case "Active Project":
        case 2:
            return "Active Project";
        case "RequestExpressionOfInterest":
        case "Request Expression Of Interest":
        case 3:
            return "Request Expression Of Interest";
        case "RequestForProposal":
        case "Request For Proposal":
        case 4:
            return "Request For Proposal";
        default:
            return "";
    }
}