import React, {useMemo, useState} from "react";
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Text from 'ibis-design-system/lib/components/Atoms/Text';
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IFoundation} from "../../Interfaces/IFoundation";
import {SemiBold} from "../SemiBold";
import Button from "ibis-design-system/lib/components/core/Button";
import {IBaseProps} from "../../Interfaces/IBaseProps";
import Dropdown from "ibis-design-system/lib/components/inputFields/Dropdown";
import {IDeltaresProgramDto} from "../../Api/Dtos/IDeltaresProgramDto";
import {IDeltaresProgramGoalCreateDto} from "../../Api/Dtos/IDeltaresProgramGoalCreateDto";
import {IOperationalGoalDto} from "../../Api/Dtos/IOperationalGoalDto";
import {usePrograms} from "../../Hooks/GlobalConfigContext";
import {IOption} from "../ProjectTab/Connections/AddConnection";
import {IButtonStyles, IconButton, IContextualMenuProps} from "office-ui-fabric-react";
import {IDeltaresProgramCreateDto} from "../../Api/Dtos/IDeltaresProgramCreateDto";
import {hasPermission} from "../../Utils/hasPermission";
import {EPermission} from "../../Api/Dtos/EPermission";

const Program = styled.div`
  background-color: ${(props: IFoundation) => props.theme.colors.white};
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
`
const customSplitButtonStyles: IButtonStyles = {
    icon: {
        color: "black"
    }
};

interface IDeltaresProgramProps extends IBaseProps {
    tenderId: string;
    program: IDeltaresProgramDto;
    deleteProgram: (programId: string) => void;
    updateProgram: (programId: string, updatedProgram: IDeltaresProgramCreateDto) => void;
    deleteOperationalGoal: (programId: string, goalId: string) => void;
    updateOperationalGoal: (programId: string, goalId: string, updatedGoal: IDeltaresProgramGoalCreateDto) => void;
    addOperationalGoal: (programId: string, newGoal: IOperationalGoalDto) => void;
    permissions: EPermission[];
}

export const DeltaresProgram: React.FunctionComponent<IDeltaresProgramProps> = (props) => {

    const programs = usePrograms();

    const [editingProgram, setEditingProgram] = useState<boolean>(false);
    const [updatedProgram, setUpdatedProgram] = useState<IDeltaresProgramCreateDto>();

    const deltaresProgramName = useMemo(() => {
        if (!programs || !programs.length) return "";
        return programs.find(program => program.id === props.program.deltaresProgram)?.label;
    }, [props.program, programs]);

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'update',
                text: 'Update',
                onClick: () => setEditingProgram(!editingProgram),
            },
            {
                key: 'delete',
                text: 'Delete',
                onClick: () => props.deleteProgram(props.program.id),
            }
        ],
    };

    return (
        <div style={{...props.style, position: "relative"}} className={props.className}>
            <Program>
                {hasPermission(props.permissions) &&
                    <div style={{position: "absolute", top: 0, right: 0}}>
                        <IconButton styles={customSplitButtonStyles} iconProps={{iconName: 'More'}}
                                    menuProps={menuProps} menuIconProps={{style: {display: "none"}}}/>
                    </div>
                }
                <Flex justifyContent="space-between">
                    <Box flex={1}>
                        { editingProgram ?
                            <Dropdown
                                label="Deltares Program"
                                placeholder="Select a Deltares program"
                                value={[props.program?.deltaresProgram]}
                                onChanged={(selected: IOption) => setUpdatedProgram({DeltaresProgram: selected?.key})}
                                multiSelect={false}
                                options={programs}
                            />
                            :
                            <div>
                                <SemiBold>Deltares Program</SemiBold>
                                <Text>{props.program.deltaresProgram ? deltaresProgramName : "Name of program not found"}</Text>
                            </div>
                        }
                    </Box>
                </Flex>

                { editingProgram &&
                    <Flex mt={2}>
                        <Button variant="primary" onClick={() => {
                            if (updatedProgram) props.updateProgram(props.program.id, updatedProgram);
                            setEditingProgram(false);
                        }}>Save</Button>
                        <span style={{marginLeft: 8}}><Button onClick={() => setEditingProgram(false)}>Cancel</Button></span>
                    </Flex>
                }
            </Program>
        </div>
    );
};
