import React, { FC } from 'react';
import Dialog from 'ibis-design-system/lib/components/core/Dialog';
import Button from 'ibis-design-system/lib/components/core/Button';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';

interface Props {
    isOpen: boolean,
    handleClose: Function,
    onExportSingle: Function
    onExportZip: Function
}

export const ExportDialog:FC<Props> = ({ isOpen, handleClose, onExportSingle, onExportZip }) => {
    return (
        <Dialog isOpen={isOpen} handleClose={handleClose} title={'Export to Word'}>
            <Flex>Do you want to export into a zip or a single file?</Flex>
            <Box bg="white">
                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'inherit', alignItems: 'center', width: '309px', gap: '10px', padding: '20px 0 30px 0' }}>
                    <Button variant="primary" onClick={() => { onExportSingle(); handleClose(); }}>
                        Single file
                    </Button>
                    <Button variant="primary" onClick={() => { onExportZip(); handleClose(); }}>
                        Zip file
                    </Button>
                </div>
            </Box>
            <Button onClick={handleClose}>Cancel</Button>
        </Dialog>
    )
}