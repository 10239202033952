import React, {useState} from "react";
import {EPersonRole, IAddPersonDto} from "../Api/Dtos/IAddPersonDto";
import {IPersonDto} from "../Api/Dtos/IPersonDto";
import {Label} from "./Label";
import {PersonCard} from "./PersonCard";
import Button from "ibis-design-system/lib/components/core/Button";
import UserPicker from 'ibis-design-system/lib/components/pickers/UserPicker';
import Box from 'ibis-design-system/lib/components/core/Box';
import Settings from "../Settings";
import {IBaseProps} from "../Interfaces/IBaseProps";
import TextInput from 'ibis-design-system/lib/components/inputFields/TextInput';
import { SemiBold } from "./SemiBold";
import { PatchParams } from "../Interfaces/PatchParams";
import { EOP } from "../Interfaces/IPatchParams";
import { IExternalTeamMemberDto } from "../Api/Dtos/IExternalTeamMemberDto";
import { PersonaSize } from 'ibis-design-system/lib/components/core/Persona';
import PersonaWithCard from "ibis-design-system/lib/components/core/PersonaWithCard";
interface IPeopleListProps extends IBaseProps {
    label: string | undefined;
    role: EPersonRole;
    people: IPersonDto[] | undefined;
    addPerson: (person: IAddPersonDto) => void;
    tenderId: string;
    // Always 'tender' in this application.
    entityType: string;
    deletePerson: (contactPersonId: string, userReference: string) => void;
    // An object with an array with string containing the user rights.
    userContext: any;
    // If set to true, displays options for deleting a user, or updating the role, if a user has the right to update these.
    showOptions?: boolean;
    // Use this to show another function sescription than the role.
    showFunctionDescriptionInput?: boolean;
    // Use this in the project team to get the external user input field.
    isProjectTeam?: boolean
    // To patch the project team external users via .../tenders/{id}
    patch?: PatchParams
    // a list of ExternalTeamMember provided seperately because this does not match the IPeople type
    externalTeamMembers?: any;
    // to check if its overview page
    isOverview?: boolean;
}

/**
 * This component displays a list of people (Deltares users) passed through the props.
 * It provides a wat for new users to be added, using the "addPerson" props function.
 * A way to delete person connections is also provided using the "deletePerson" prop function.
 * In short, people connections can be displayed and managed using this component.
 *
 * When the showOptions props is provided, the persona cards dislayed for existing user connections will display options for adding user rights in relation to the active Entity,
 * if the active user has rights to edit them.
 * @param props
 * @constructor
 */
export const PeopleList: React.FunctionComponent<IPeopleListProps> = (props) => {
    const [adding, setAdding] = useState<boolean>(false);
    const [addedPerson, setAddedPerson] = useState<IAddPersonDto>({userReference: '', tenderId: '', role: 5, functionDescription: undefined});

    const onClickAddPerson = () => {
        props.addPerson(addedPerson);
        setAdding(false);
    };

    return (
        <div style={props.style} className={props.className}>
            <Label>{props.label}</Label>
            {props.people && props.people.length ? props.people.map((person, index) => {
                return (
                    <div style={{marginTop: index > 0 ? 7 : 0}}>
                        <PersonCard
                            key={person.id}
                            contactPersonId={person.id}
                            userId={person.userReference}
                            tenderId={props.tenderId}
                            entityType={props.entityType}
                            deletePerson={props.deletePerson}
                            userContext={props.userContext}
                            showOptions={props.showOptions}
                            functionDescription={person.functionDescription}
                        />
                    </div>
                )
            }) : null}
            <div style={{marginBottom: 7}}></div>
            {props.externalTeamMembers && props.externalTeamMembers ? props.externalTeamMembers.map((person: IExternalTeamMemberDto, index: number) => {
                return (
                    <div style={{marginTop: index > 0 ? 7 : 0}}>
                        <PersonaWithCard
                            text={person.name}
                            secondaryText={person.functionDescription}
                            size={PersonaSize.size40}
                            variant="external"
                            moreOptions={
                                props.deletePerson !== undefined && person?.id ?
                                    [
                                        {
                                            key: 'delete',
                                            text: 'Delete person',
                                            onClick: () => props.patch!([
                                                {
                                                    op: EOP.Remove,
                                                    path: `/linkedProject/externalTeamMembers/${person.id}`
                                                }
                                            ], props.tenderId)
                                        },
                                    ]
                                    : null
                            }
                        />
                    </div>
                )
                }) 
            : null }
            <Box mt={1} style={{ maxWidth: 325 }}>
                {!props.isProjectTeam ? (
                    <DeltaresUserPicker
                        addedPerson={addedPerson}
                        setAddedPerson={setAddedPerson}
                        onClickAddPerson={onClickAddPerson}
                        tenderId={props.tenderId}
                        role={props.role}
                        showFunctionDescriptionInput={props.showFunctionDescriptionInput}
                        adding={adding}
                        setAdding={setAdding}
                        isOverview={props.isOverview}
                    />
                ) : (
                    <ProjectTeamUserPicker
                        addedPerson={addedPerson}
                        setAddedPerson={setAddedPerson}
                        onClickAddPerson={onClickAddPerson}
                        adding={adding}
                        setAdding={setAdding}
                        tenderId={props.tenderId}
                        role={props.role}
                        patch={props.patch}
                    />
                )}
            </Box>
        </div>
    );
};


interface ExternalUserProps {
    addedPerson: any,
    setAddedPerson: Function,
    onClickAddPerson: Function,
    adding: boolean,
    setAdding: Function,
    tenderId: string,
    role: EPersonRole,
    isOverview?: boolean,
    patch?: PatchParams;
}

type externalUser = {
    functionDescription: string,
    name: string,
}

const INITIAL_EXTERNALUSER = {
    functionDescription: '',
    name: ''
}

const ProjectTeamUserPicker: React.FunctionComponent<ExternalUserProps>  = (props) => {
    const {setAddedPerson, addedPerson, onClickAddPerson, adding, setAdding, tenderId, role, patch} = props
    const [makeSelection, setMakeSelection] = useState(false);
    const [externalUser, setExternaluser] = useState<externalUser>(INITIAL_EXTERNALUSER);
    const [typeOfUser, setTypeOfUser] = useState<'deltares' | 'external'>();

    const onAddExternalPerson = () => {
        if (externalUser.name.trim().length === 0 || externalUser.functionDescription.trim().length === 0) {
            setTypeOfUser(undefined);
            setAdding(false);
        } else {
            patch!([
                {
                    op: EOP.Add,
                    value: {
                        name: externalUser.name,
                        functionDescription: externalUser.functionDescription
                    },
                    path: '/linkedProject/externalTeamMembers/-'
                }
            ], tenderId);
            setExternaluser(INITIAL_EXTERNALUSER);
            setTypeOfUser(undefined);
            setAdding(false);
        }
    };

    const onAddDeltaresUser = () => {
        onClickAddPerson();
        setTypeOfUser(undefined);
    }

    const onDeltaresUserSelected = () => {
        setMakeSelection(false);
        setTypeOfUser('deltares');
        setAdding(true);
    }
    const onExternalUserSelected = () => {
        setTypeOfUser('external');
        setMakeSelection(false);
        setAdding(true)
    }

    return (
            <Box mt={1} style={{ maxWidth: 325 }}>
                {adding ? (
                    <>
                        <SemiBold>User</SemiBold>
                        {typeOfUser === 'external' ? (
                            <>
                                <TextInput
                                    placeholder="External team member"
                                    onChange={(user: string) => setExternaluser({...externalUser, name: user })}
                                    />
                                <Box mt={12} />
                                <TextInput
                                    placeholder="Function description"
                                    label="Function title (mandatory)"
                                    onChange={(f: string) => setExternaluser({...externalUser, functionDescription: f })}
                                    />
                                <Button
                                    variant="commandBar"
                                    icon="Add"
                                    onClick={() => onAddExternalPerson()}
                                    >
                                    Add external person or former colleague
                                </Button>
                            </>
                        ) : (
                            <>
                                <UserPicker
                                    max={1}
                                    url={`${Settings.apiUrls.singleSignOn}/users/details/search?take=10&skip=0&term=`}
                                    onChanged={(value: any[]) => {
                                        setAddedPerson({
                                            userReference: value[0].id,
                                            tenderId: tenderId,
                                            role: role,
                                        });
                                    }}
                                />
                                <Box mt={12} />
                                <TextInput
                                    placeholder="Other function description"
                                    label="Function title (optional)"
                                    onChange={(f: string) => setAddedPerson({...addedPerson, functionDescription: f })}
                                    />
                                <Button
                                    variant="commandBar"
                                    icon="Add"
                                    onClick={() => onAddDeltaresUser()}
                                    >
                                    Add deltares person
                                </Button>
                            </>
                        )}
                    </>
                ) : !makeSelection && typeOfUser === undefined ? (
                    <Button
                    variant="commandBar"
                    icon="Add"
                    onClick={() => setMakeSelection(true)}
                    >
                        Add person
                    </Button>
                ) : null}
                {makeSelection ? (
                    <Box>
                        <Button
                            variant="commandBar"
                            icon="Add"
                            onClick={() => onDeltaresUserSelected()}
                            >
                            Add Deltares person
                        </Button>
                        <Button
                            variant="commandBar"
                            icon="Add"
                            onClick={() => onExternalUserSelected()}
                            >
                        Add external person or former colleague
                        </Button>
                    </Box>
                ) : null}
            </Box>
    );
    
}          

interface DeltaresUserProps {
    addedPerson: any,
    setAddedPerson: Function,
    onClickAddPerson: Function
    tenderId: string,
    role: EPersonRole,
    adding: boolean,
    setAdding: Function,
    showFunctionDescriptionInput?: boolean,
    isOverview?: boolean,
}

const DeltaresUserPicker: React.FunctionComponent<DeltaresUserProps>  = (props) => {
    const {addedPerson, setAddedPerson, tenderId, role, onClickAddPerson, showFunctionDescriptionInput, adding, setAdding } = props
    return (
        <>
        { props.isOverview == false ? 
            <Box mt={1} style={{maxWidth: 325}}>
                {adding? 
                    (
                        <>
                            <SemiBold>User</SemiBold>
                            <UserPicker
                                max={1}
                                url={`${Settings.apiUrls.singleSignOn}/users/details/search?take=10&skip=0&term=`}
                                onChanged={(value: any[]) => {
                                    setAddedPerson({
                                        userReference: value[0].id,
                                        tenderId: tenderId,
                                        role: role,
                                    });
                                }}
                                />
                            {showFunctionDescriptionInput ? 
                                <>
                                    <Box mt={12}/>
                                    <TextInput 
                                        placeholder="Other function description"
                                        label='Function title (optional)'
                                        onChange={(f: string) => setAddedPerson({...addedPerson, functionDescription: f})}
                                    />
                                </> 
                            : 
                                null
                            }

                            <Button variant="commandBar" icon="Add" onClick={() => onClickAddPerson()}>Add person</Button>
                        </>
                    ) :
                    <Button variant="commandBar" icon="Add" onClick={() => setAdding(!adding)}>
                        Add person
                    </Button>
                }
            </Box>
        : ""}
        </>
    )
}