import {TenderTabCard} from "./TenderTabCard";
import React from "react";
import {Overview} from "../Containers/Overview";
import {Tender} from "../Containers/Tender";
import {Project} from "../Containers/Project";
import {Finance} from "../Containers/Finance";
// import {Risk} from "../Containers/Risk";
import {Chat} from "./Chat";
import {Documents} from "./Documents";
import {People} from "../Containers/People";
import Activities from "../Containers/Activities";
import {ITenderOverviewDto} from "../Api/Dtos/ITenderOverviewDto";
import {IPatchParams} from "../Interfaces/IPatchParams";
import {PatchParams} from "../Interfaces/PatchParams";
import {IUserContextDto} from "../Api/Dtos/IScanSourceDto";
import { MaconomyTurnover } from "../Containers/MaconomyTurnover";

/**
 * The tab options at the top of the details page after selecting a tender are displayed here.
 * They contain the actual content of a tab.
 *
 * @param overflowActive
 * @param item - The tender passed to the tab.
 * @param type - The entity type, always Tender here.
 * @param setMessage
 * @param patch - An update function provided by the front and backend.
 * @param user - The active user.
 */
export const GetDetailTabs = (overflowActive: boolean, item: ITenderOverviewDto, type: any, setMessage: any, patch: PatchParams, user: IUserContextDto) => {
    const isProject = item.linkedTender !== undefined;
    const id = item.linkedTender ? item.linkedTender : item.id;

    let extendedPatch = patch;
    if (isProject) {
        extendedPatch = (patches: IPatchParams<any, any>[], id?: string, message?: string, entityName?: string) => {
            return patch(patches, id, message ? message : "updated", entityName ? entityName : "project", (resp: any) => {
                resp.id = item.linkedProject.id;
                return resp;
            });
        };
    }

    const tabs = [
        TenderTabCard("Overview", <Overview item={item} overflowActive={overflowActive} type={type} setMessage={setMessage} patch={extendedPatch} />),
        isProject ? TenderTabCard("Project", <Project item={item} patch={extendedPatch} />) : TenderTabCard("Tender", <Tender item={item} patch={extendedPatch} />),
        isProject ? TenderTabCard("Tender", <Tender item={item} patch={extendedPatch} />) : TenderTabCard("Project", <Project item={item} patch={extendedPatch} />),
        TenderTabCard("Finance & partners", <Finance item={item} patch={extendedPatch} userContext={item.userContext} />),
    ];

    if (isProject) {
        tabs.push(TenderTabCard("Maconomy", <MaconomyTurnover item={item} />));
    }

    tabs.push(
        TenderTabCard("Messages", <Chat setMessage={setMessage} id={id} title={item.assignmentTitle ? item.assignmentTitle : ""} userContext={item.userContext}/>, "Message", true, "Messages", overflowActive),
        TenderTabCard("Documents", <Documents setMessage={setMessage} id={id} name={item.assignmentTitle ? item.assignmentTitle : ""} userContext={item.userContext} patch={extendedPatch} item={item}/>, "Folder", true, "Documents", overflowActive),
        TenderTabCard("People", <People tender={item} user={user} patch={extendedPatch} />, "Contact", true, "People", overflowActive)
    );

    return tabs;
}