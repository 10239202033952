import {IActionArrayItem} from "../Interfaces/IActionArrayItem";

/**
 *
 * @param icon
 * @param label - Used to fill the Text, populates the Label and Key attributes by default. If this attribute is changeable, you can pass a separate Key attribute.
 * @param disabled
 * @param onClick
 * @param key - Can pass an optional key value, in case the label isn't unique.
 */
export const createActionArrayItem = (icon: string, label: string, disabled: boolean, onClick?: () => void, key?: string): IActionArrayItem => {
    return {
        icon,
        key: key ? key : label,
        label,
        text: label,
        disabled: disabled,
        onClick,
    }
}