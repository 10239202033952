import React, { useEffect, useState } from "react";
import {DocumentsContainer} from 'ibis-design-system/lib/components/patterns/Documents';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Settings from "../Settings";
import {sendLog} from "../Api/ProjectRequests";
import {HttpPost} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import {ITenderOverviewDto, UserContext} from '../Api/Dtos/ITenderOverviewDto';
import {EPermission} from "../Api/Dtos/EPermission";
import { getDocumentTypes, patchDocumentType } from "../Api/CompanyRequests";
import { PatchParams } from "../Interfaces/PatchParams";

interface IDocumentsProps {
    id: string;
    setMessage: any;
    name: string;
    userContext: UserContext;
    patch: PatchParams;
    item: ITenderOverviewDto;
}

interface DocumentType {
    id: number;
    label: string;
}

export const Documents: React.FunctionComponent<IDocumentsProps> = (props) => {
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
    // The null check is to fix the bug where the userContext array is modified to a null value.
    const isContributor = props.userContext?.permissions?.includes(EPermission.Contribute) === true || props.userContext?.permissions === null;

    function notifyDocument(action: any) {
        let message: string;
        let notifyAction: number;
        if (action === 'add') {
            message = `A document has been added to ${props.name}`;
            notifyAction = 0;
        } else {
            message = `A document has been deleted from ${props.name}`;
            notifyAction = 2;
        }
        // Notify the notification service that a Entity is created
        const notification = {
            entityId: props.id,
            action: notifyAction,
            modifiedField: 'documents',
            message: message,
        };
        // Just send it and then ignore it. Nothing happens when it completes and nothing happens when it fails
        HttpPost(`${Settings.apiUrls.cci}/companies/notifications`, notification);
    }
      
    async function retrieveDocumentTypes(): Promise<DocumentType[] | null> {
        try {
            const documentTypes: string[] = await getDocumentTypes();
            const formattedTypes: DocumentType[] = documentTypes.map((type, index) => ({
                id: index,
                label: type
        }));
            return formattedTypes;
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }

    function convertToHumanReadableText(id: string): string {
        const words = id.split(/(?=[A-Z])/);
        const humanReadable = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return humanReadable;
    }

    useEffect(() => {
        const fetchDocumentTypes = async () => {
            const types: DocumentType[] | null = await retrieveDocumentTypes();
            if (types) {
                const formattedTypes: DocumentType[] = types.map((type, index) => ({
                id: index,
                label: convertToHumanReadableText(type.label)
                }));
                setDocumentTypes(formattedTypes);
            }
        };
        fetchDocumentTypes();
    }, []);

    return (
        <Box width="100%" height="100%" py={2} px={3}>
            <DocumentsContainer
                isPublic
                // prettier-ignore
                url={`${Settings.apiUrls.tenders}/tenders/${props.id}/documents`}
                // prettier-ignore
                fileUploadUrl={`${Settings.apiUrls.fileService}/files`}
                onError={(error: any) => {
                    props.setMessage('error', error.message);
                }}
                onWarning={(error: any) => {
                    props.setMessage('warning', error.message);
                }}
                onSuccess={(response: any, action: any) => notifyDocument(action)}
                onTimeout={(error: any, url: any) => {
                    props.setMessage(
                        'error',
                        `Couldn't reach the server. The developers have been notified.`
                    );
                    sendLog(JSON.stringify(error), `Server timeout on url: "${url}"`);
                }}
                onUnknownError={(error: any, url: any) => {
                    props.setMessage(
                        'error',
                        `An unknown error occurred... Our developers have been notified`
                    );
                    sendLog(JSON.stringify(error), `Unknown error on url: "${url}"`);
                }}
                disabled={!isContributor}
                dropDownOptions={documentTypes}
                showDocumentTypeInput
                patchDocumentType={(documentId: number, documentType: DocumentType) => patchDocumentType(documentId, documentType)}
                ssoApi={Settings.apiUrls.singleSignOn}
            />
        </Box>
    );
};
