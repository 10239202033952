import React, { useMemo } from "react";
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import Text from 'ibis-design-system/lib/components/Atoms/Text';
import { IFoundation } from "../../Interfaces/IFoundation";
import LoadScreen from 'ibis-design-system/lib/components/core/LoadScreen';
import Flex from 'ibis-design-system/lib/components/core/Flex';

interface IExportCardProps {
    name: string;
    //companyLogo?: string;
    //companyName: string;
    //companyLocation: string;
    onClick?: () => void;
    style?: React.CSSProperties;
    exportingList: string[];
}

type CardProps = IFoundation & {isExporting: boolean}

const Card = styled.div<CardProps>`
    background-color: ${(props: CardProps) => props.isExporting ? props.theme.colors.neutralQuaternaryAlt : props.theme.colors.white};
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    cursor: ${(props: CardProps) => props.isExporting ? 'auto' : 'pointer'};
    
    &:hover {
        background-color: ${(props: CardProps) => props.theme.colors.hover};
    }

    &:active {
        background-color: ${(props: CardProps) => props.theme.colors.selected};
    }
`;

export const ExportCard: React.FunctionComponent<IExportCardProps> = (props) => {
    const isExporting = useMemo(() => props.exportingList.includes(props.name), [props.exportingList]);

    const onClick = () => {
        if (isExporting || !props.onClick) return;
        props.onClick();
    }

    return (
        <Card style={props.style} onClick={onClick} isExporting={isExporting}>
            {/*<div style={{marginLeft: 10, marginTop: 5}}>*/}
            {/*    <img src={props.companyLogo} width={32} height={32} alt={"Logo"} />*/}
            {/*</div>*/}
            {/*<div style={{marginTop: 11, marginLeft: 8, paddingBottom: 5}}>*/}
                <div style={{ padding: 10, width: '100%' }}>
                    <Text as='span' fontWeight={'bold'}>{props.name}</Text>
                </div>
                <Flex style={{ flexDirection: 'column', alignItems: 'flex-end', paddingRight: '10px' }}>
                    {/* for individual loading spinner, check if props.name is included in the exporting array, else all exportcards get a loadingspinner  */}
                    {isExporting ? <LoadScreen /> : null}
                </Flex>
                {/*<div>*/}
                {/*    <Text as='span'>{props.companyName}</Text>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <Text as='span'>{props.companyLocation}</Text>*/}
                {/*</div>*/}
            {/*</div>*/}
        </Card>
    );
};
