import React from "react";
import {IBaseProps} from "../../Interfaces/IBaseProps";
import Box from "ibis-design-system/lib/components/Atoms/Box/Box";
import WYSIWYGEditor from "ibis-design-system/lib/components/inputFields/WYSIWYGEditor";
import {IProject} from "../../Api/Dtos/IProjectDto";
import {EPermission} from "../../Api/Dtos/EPermission";
import {hasPermission} from "../../Utils/hasPermission";
import {PatchParams} from "../../Interfaces/PatchParams";
import {WYSIWYG_dataConversion} from "../../Utils/WYSIWYG_dataConversion";
import {EOP} from "../../Interfaces/IPatchParams";

interface IPaymentProps extends IBaseProps {
    tenderId: string;
    project: IProject;
    userPermissions: EPermission[];
    patch: PatchParams;
}

export const Payment: React.FunctionComponent<IPaymentProps> = (props) => {

    return (
        <div style={props.style} className={props.className}>
            <Box>
                <WYSIWYGEditor
                    label="Payment instructions"
                    content={WYSIWYG_dataConversion(props.project.payments)}
                    onUpdate={(val: string) => props.patch([
                            {
                                op: EOP.Replace,
                                path: `/linkedProject/payments`,
                                value: val,
                            },
                        ]
                    )}
                    readOnly={!hasPermission(props.userPermissions)}
                />
            </Box>
        </div>
    );
};
