import React from "react";
import Button from "ibis-design-system/lib/components/core/Button";
import {EOP, IPatchParams} from "../../Interfaces/IPatchParams";
import {ILocationBase, ILocationCreateDto} from "../../Api/Dtos/ITenderOverviewDto";
import {IBaseProps} from "../../Interfaces/IBaseProps";
import { PatchParams } from "../../Interfaces/PatchParams";

interface IAddLocationProps extends IBaseProps {
    patch: PatchParams;
    tenderId: string;
    locationLength: number;
}

const baseLocation: ILocationBase = {
    regionId: "",
    region: "",
    countryId: "",
    country: "",
    cityId: "",
    city: "",
    locality: "",
};

const firstLocation: ILocationCreateDto = {
    ...baseLocation,
    isDppMapLocation: true,
};

const newLocation: ILocationCreateDto = {
    ...baseLocation,
    isDppMapLocation: false,
};

export const AddLocation: React.FunctionComponent<IAddLocationProps> = (props) => {
    const checkIfFirstLocation = (locationLength: number) => {
        if (locationLength === 0) return firstLocation;
        if (locationLength > 0) return newLocation;
    }

    const createLocation = () => {
        props.patch([
            {
                op: EOP.Add,
                path: `/locations/-`,
                value:  checkIfFirstLocation(props.locationLength) 
            }
        ], props.tenderId)
    }

    return (
        <div className={props.className} style={props.style}>
            <Button variant="commandBar" icon="Add" color={"#66666"} onClick={createLocation}>
                Add Location
            </Button>
        </div>
    );
};
