import { ChoiceGroup } from "office-ui-fabric-react";
import { IProject } from "../Api/Dtos/IProjectDto";
import { PatchParams } from "../Interfaces/PatchParams";
import { EOP } from "../Interfaces/IPatchParams";
import { useState } from "react";

interface Props {
    project: IProject;
    patch: PatchParams;
}

const options = [
    { key: '0', text: "Intermittent" },
    { key: '1', text: "Continuous" },
];

export const IsContinousRadioButton: React.FunctionComponent<Props> = (props) => {
    const [selected, setSelected] = useState(props.project.isContinuous ? options[1].key : options[0].key);

    return (
        <ChoiceGroup
            options={options}
            selectedKey={selected}
            onChange={(ev, value) => {
                setSelected(selected === '1' ? '0' : '1');
                props.patch([
                    {
                        op: EOP.Replace,
                        value: value?.key === "1" ? true : false,
                        path: '/linkedProject/isContinuous'
                    }
                ], props.project.linkedTender)
            }}
        />
    );
};