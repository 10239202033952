import React, { FC } from 'react';
import Dialog from 'ibis-design-system/lib/components/core/Dialog';
import Button from 'ibis-design-system/lib/components/core/Button';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';

interface ProjectProps {
    isOpen: boolean,
    handleClose: Function,
    onClickWithLois: Function
    onClickWithoutLois: Function
}

export const AddNewProjectDialog:FC<ProjectProps> = ({ isOpen, handleClose, onClickWithLois, onClickWithoutLois }) => {
    return (
        <Dialog isOpen={isOpen} handleClose={handleClose} title={'Add new project'}>
            <Flex>Do you want to add a new project with or without LOIS code?</Flex>
            <Box bg="white">
                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '170px', gap: '10px', padding: '20px 0 30px 0' }}>
                    <Button variant="primary" onClick={() => { onClickWithLois(); handleClose(); }}>With LOIS code</Button>
                    <Button variant="primary" onClick={() => { onClickWithoutLois(); handleClose(); }}>Without LOIS code</Button>
                </div>
            </Box>
            <Button onClick={handleClose}>Cancel</Button>
        </Dialog>
    )
}