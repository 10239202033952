import { IPersonDto } from "./IPersonDto";

export interface IAddPersonDto extends Omit<IPersonDto, "id"> {}

export enum EPersonRole {
    RegionalCoordinator,
    DepartmentHead,
    UnitHead,
    TenderLead,
    ProjectLead,
    OtherContacts,
    ProjectTeam
}