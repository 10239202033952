import { ChoiceGroup } from "office-ui-fabric-react";

interface Props {
    setSelectedLocation: (value: any) => void;
    locationId: string;
    isDppMapLocation?: boolean;
}

export const UseAsDPPLocationRadioButton: React.FunctionComponent<Props> = (props) => {
    const { setSelectedLocation, locationId, isDppMapLocation } = props;

    return (
        <ChoiceGroup
            options={[{ key: locationId, text: "Use this location for Deltares Profile Projects" }]}
            selectedKey={isDppMapLocation ? locationId : undefined}
            onClick={() => setSelectedLocation(locationId)}
        />
    );
};
