import React, {useEffect, useState} from "react";
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import Text from 'ibis-design-system/lib/components/Atoms/Text';
import Icon from 'ibis-design-system/lib/components/core/Icon';
import {IFoundation} from "../../Interfaces/IFoundation";
import {ExportCard} from "./ExportCard";
import {downloadDocumentZip, downloadMultipleProjectInOneFile, downloadTemplate} from "../../Api/ProjectRequests";
import {useNavigationState} from "ibis-design-system/lib/Providers/NavigationProvider";
import {useTemplateDownloadOptions} from "../../Hooks/GlobalConfigContext";
import { ExportDialog } from "./ExportDialog";
import { request } from "../../Utils/request";

interface IExportMenuProps {
    exportOptionsUrl: string;
    isOpen?: boolean;
    onClose?: () => void;
}

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 11px;
`

const Menu = styled.div`
  display: ${(props: any) => props.isOpen ? 'block' : 'none'};
  position: absolute;
  top: 0;
  overflow-x: scroll;
  right: 0;
  width: 340px;
  height: 98%;
  border: 1px solid #00000026;
  z-index: 3;
  background-color: ${(props: IFoundation) => props.theme.colors.neutralLight};
  padding: 0 5px 15px 0;
`;

export const ExportMenu: React.FunctionComponent<IExportMenuProps> = (props) => {
    const { entities, selection } = useNavigationState();
    const [showDialog, setShowDialog] = useState(false);
    const [selectedTemplateName, setSelectedTemplateName] = useState("");
    const templateDownloadOptions = useTemplateDownloadOptions();
    const [isExporting, setIsExporting] = useState<string[]>([]);

    useEffect(() => {
        if (!props.exportOptionsUrl) return;
    }, [props.exportOptionsUrl]);

    const downloadProject = async (templateName: string, route?: 'single' | 'zip'): Promise<void> => {
      setIsExporting(prevState => [...prevState, templateName]);
      try {
        if (!selection || !selection.length || !entities) return;
  
        let ids = [];
  
        for (const selected of selection) {
            
            if(selection.length >= 2){
                ids.push(entities[selected].id);
                continue;
            }

            await request(downloadTemplate(entities[selected].id, `${templateName}`, entities[selected].projectTitle));
        }
  
        if (ids.length && route === 'zip') {
            await request(downloadDocumentZip(ids, `${templateName}`));
        }
        if (ids.length && route === 'single') {
            await request(downloadMultipleProjectInOneFile(ids, `${templateName}`));
        }
      } catch (error) {
      }
        setIsExporting(prevState => prevState.filter(name => name !== templateName));
    };

    const onExportSingleFile = () => request(downloadProject(selectedTemplateName, 'single'));

    const onExportZip = () => request(downloadProject(selectedTemplateName, 'zip'));

    const onClick = (id: string) => {
        setSelectedTemplateName(id);
        if (selection.length >= 2) setShowDialog(true);
        else request(downloadProject(id, 'single'));
    }

    return (
        <Menu isOpen={props.isOpen}>
            <Top>
                <Text fontWeight={'bold'} style={{fontSize: 21}}>Export to Word</Text>
                <Icon style={{cursor: "pointer"}} name="StatusCircleErrorX" size={'l'} onClick={props.onClose} />
            </Top>

            {templateDownloadOptions.map( (exportCard: { label: string; id: string;  item2?: string;}, index: number) => {
                return (
                    <ExportCard
                        style={{marginTop: index > 0 ? 1 : 0}}
                        name={exportCard.item2!}
                        onClick={() => onClick(exportCard.item2!)}
                        exportingList={isExporting}
                        />
                )})}
                <ExportDialog
                isOpen={showDialog}
                onExportSingle={onExportSingleFile}
                onExportZip={onExportZip}
                handleClose={() => setShowDialog(false)}
                />
        </Menu>
    );
};