import React, { useEffect, useState, useCallback } from 'react';

import { CancelToken } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import { useCacheState } from 'ibis-design-system/lib/Providers/HttpCacheProvider';
import PersonaWithCard from 'ibis-design-system/lib/components/core/PersonaWithCard';

import {
    getPermissionForPersonOnEntity,
    changePermissionForPersonOnEntity,
} from '../../../Api/CompanyRequests';
import { PERMISSIONS } from '../../../Constants';
import { useNavigationState } from 'ibis-design-system/lib/Providers/NavigationProvider';
import {getDeltaresUserDetails} from "../../../Api/ProjectRequests";

function PersonaContainer(props) {
    const { userId, onRemove, entityPersonId, entityId, entityType, canEditPermissions } = props;

    const cache = useCacheState();
    const { navigateToErrorPage } = useNavigationState();

    const [user, setUser] = useState(null);

    useEffect(() => {
        const source = CancelToken.source();
        getDeltaresUserDetails(userId, source.token, cache)
            .then((response) => {
                setUser(response);
            })
            .catch((error) => {
                setUser({ name: 'Error while retrieving user information' });
                navigateToErrorPage({ code: error.code, navigateBack: true });
            });

        //return () => source.cancel();
    }, [userId, cache, navigateToErrorPage]);

    const getCurrentPermissionCallback = useCallback(() => {
        return getPermissionForPersonOnEntity(entityId, entityType, userId);
    }, [entityId, entityType, userId]);

    const changePermissionForPersonOnEntityCallback = useCallback(
        (permissionKey) => {
            changePermissionForPersonOnEntity(entityId, entityType, userId, permissionKey);
        },
        [entityId, entityType, userId]
    );

    if (!user) return <PersonaWithCard p={1} variant="deltares" />;

    return (
        <PersonaWithCard
            variant={user?.isActiveUser ? 'deltares' : 'inactive'}
            p={1}
            text={user.name}
            imageUrl={user.photo}
            secondaryText={user.jobTitle}
            primaryEmail={user.email}
            primaryPhone={user.mobile}
            contactInformation={[
                { accountType: 'Mobile', value: user.mobile },
                { accountType: 'Phone', value: user.phone },
            ]}
            moreOptions={
                onRemove
                    ? [
                          {
                              key: 'delete',
                              text: 'Delete person',
                              onClick: () => onRemove(entityPersonId, user.name),
                          },
                      ]
                    : null
            }
            permissions={
                canEditPermissions
                    ? {
                          label: 'Permission',
                          options: PERMISSIONS,
                          getCurrentPermission: getCurrentPermissionCallback,
                          onChanged: (change) =>
                              changePermissionForPersonOnEntityCallback(change?.key),
                      }
                    : null
            }
        />
    );
}

export default PersonaContainer;
