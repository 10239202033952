import Settings from './Settings';
import {IMenu} from "./Interfaces/IMenu";
import {IMenuItem} from "./Interfaces/IMenuItem";
import {hasPermission} from "./Utils/hasPermission";
import {EPermission} from "./Api/Dtos/EPermission";

const TAKE = 100;

export function getMenuItems(username: string, projects: boolean, permissions: any): Array<IMenu | IMenuItem> {
    if (projects) return projectMenu(username, permissions);
    return tenderMenu(username);
}

const tenderMenu = (username: string): Array<IMenu | IMenuItem> => {
    return [
        {
            id: username,
            name: username,
            expanded: true,
            items: [
                {
                    name: 'Following',
                    id: 'following',
                    request: {
                        url: `${Settings.apiUrls.tenders}/tenders/search`,
                        filters: [{ type: 'following' }],
                        take: TAKE,
                        skip: 0
                    }
                },
                {
                    name: 'My Tenders',
                    id: 'my-tenders',
                    request: {
                        url: `${Settings.apiUrls.tenders}/tenders/search`,
                        skip: 0,
                        filters: [{ type: 'my-tenders' }],
                    }
                }
            ]
        },
        {
            id: 'active-tenders',
            name: 'Active tenders',
            request: {
                url: `${Settings.apiUrls.tenders}/tenders/search`,
                skip: 0,
                filters: [{ type: 'notArchived' }],
            }
        },
        {
            id: 'pending',
            name: 'Pre-announcements',
            request: {
                url: `${Settings.apiUrls.tenders}/tenders/search`,
                filters: [{ type: 'pending'}],
                take: TAKE,
                skip: 0
            }
        },
        {
            id: 'archived',
            name: 'Archived',
            request: {
                url: `${Settings.apiUrls.tenders}/tenders/search`,
                filters: [{ type: 'archived'}],
                take: TAKE,
                skip: 0
            }
        },
    ];
}

const projectMenu = (username: string, permissions: any): Array<IMenu | IMenuItem> => {

    let isOwner = permissions.availableRoles.find( (ar: any) => ar.id === 'owner');
    const menu = [
        {
            id: username,
            name: username,
            expanded: true,
            items: [
                {
                    name: 'Following',
                    id: 'following',
                    request: {
                        url: `${Settings.apiUrls.tenders}/projects/search`,
                        filters: [{ type: 'following' }],
                        take: TAKE,
                        skip: 0
                    }
                },
                {
                    name: 'Export list',
                    id: 'selected',
                    request: {
                        url: `${Settings.apiUrls.projects}/projects/search`,
                        filters: [{ type: 'selected' }],
                        take: TAKE,
                        skip: 0
                    }
                }
            ]
        },
        {
            id: 'projects',
            name: 'Projects',
            expanded: true,
            items: [
                {
                    id: 'published-projects',
                    name: 'Published',
                    request: {
                        url: `${Settings.apiUrls.tenders}/projects/search`,
                        filters: [{type: 'published'}],
                        take: TAKE,
                        skip: 0
                    }
                }
            ]
        },
        {
            id: 'archived',
            name: 'Archived',
            request: {
                url: `${Settings.apiUrls.tenders}/projects/search`,
                filters: [{ type: 'archived'}],
                take: TAKE,
                skip: 0
            }
        },
    ];

    if (isOwner) {
        if (menu[1] && menu[1].items) {
            menu[1].items.push({
                id: 'draft-projects',
                name: 'Drafts',
                request: {
                    url: `${Settings.apiUrls.tenders}/projects/search`,
                    filters: [{type: 'draft'}],
                    take: TAKE,
                    skip: 0,
                },
            });
        }
    }

    return menu;
}

