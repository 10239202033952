/**
 * Pass a string and a length. The string will be cut off from the length, and displayed with ... at the end
 *
 * @param text - The text you wish to shorten.
 * @param length - The amount of characters you wish to display, before cutting off the text.
 *
 * @Example const line = "This is a very long line, which we want to cut off after 10 characters."; const shortened = shortenString(line, 10); // shorten is now: "This is a ..."
 */
export const shortenString = (text: string , length: number): string => {
    if(text == null){ return ''}  
    if (text.length <= length) { return text; }

    return text.substr(0, length) + "...";

}