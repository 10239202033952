import Settings from './Settings';
import {IRequestObject} from "./Interfaces/IRequestObject";
import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import {isProduction} from "./Utils/isProduction";

const TAKE = 50;

/**
 *
 * @param userId - The ID of the user making the request.
 * @param projects - Boolean, If true, the requests fetch projects instead of tenders.
 */
export function getRequests(userId: string, projects: boolean): IRequestObject[] {
    if (projects) return projectRequests(userId);
    return tenderRequests(userId);
}

const tenderRequests = (userId: string): IRequestObject[] => [
    {
        id: 'following',
        request: {
            url: `${Settings.apiUrls.tenders}/tenders/search`,
            filters: [{ type: 'following' }, { type: 'notArchived' }],
            take: TAKE,
        },
    },
    {
        id: 'my-tenders',
        request: {
            url: `${Settings.apiUrls.tenders}/tenders/search`,
            skip: 0,
            take: TAKE,
            filters: [
                { type: 'my-tenders', parameters: [{ name: 'id', value: userId }]},
            ],
        },
    },
    {
        id: 'active-tenders',
        request: {
            url: `${Settings.apiUrls.tenders}/tenders/search`,
            skip: 0,
            take: TAKE,
            filters: [
                { type: 'active'}, {type:'notArchived' },
            ],
        },
    },
    {
        id: 'pending',
        request: {
            url: `${Settings.apiUrls.tenders}/tenders/search`,
            skip: 0,
            take: TAKE,
            filters: [
                { type: 'pending' }, {type:'notArchived'},
            ],
        },
    },
    {
        id: 'archived',
        request: {
            url: `${Settings.apiUrls.tenders}/tenders/search`,
            skip: 0,
            take: TAKE,
            filters: [
                { type: 'archived' },
            ],
        },
    },
];

const projectRequests = (userId: string): IRequestObject[] => [
    {
        id: 'following',
        request: {
            url: `${Settings.apiUrls.tenders}/projects/search`,
            filters: [{ type: 'following' }, { type: 'notArchived' }],
            take: TAKE,
        },
    },
    {
        id: 'selected',
        request: {
            url: `${Settings.apiUrls.projects}/projects/search`,
            filters: [
                {
                    type: 'selected',
                    parameters: [{ name: 'id', value: userId }],
                },
                { type: 'notArchived' },
            ],
            take: TAKE,
        },
    },
    {
        id: 'draft-projects',
        request: {
            url: `${Settings.apiUrls.tenders}/projects/search`,
            skip: 0,
            take: TAKE,
            filters: [
                { type: 'draft' }, { type: 'notArchived' }
            ],
        },
    },
    {
        id: 'published-projects',
        request: {
            url: `${Settings.apiUrls.tenders}/projects/search`,
            skip: 0,
            take: TAKE,
            filters: [
                { type: 'published' }
            ],
        },
    },
    {
        id: 'archived',
        request: {
            url: `${Settings.apiUrls.tenders}/projects/search`,
            skip: 0,
            take: TAKE,
            filters: [
                { type: 'archived' },
            ],
        },
    },
];

export async function getUsers(distinctUsers: any, httpCache: any) {
    const userCalls = [];
    for (let i = 0; i < distinctUsers.length ; i++) {
        const user = distinctUsers[i];
        userCalls.push(
            await getUser(user, httpCache)
                .then((response) => response)
                .catch((error) => {
                    if (!isProduction()) {
                        console.log(error);
                    }
                })
        );
    }

    return Promise.all(userCalls);
}

export async function getUser(id: any, httpCache: any) {
    const url = `${Settings.apiUrls.singleSignOn}/users/${id}/details`;
    const userFromCache = httpCache[url];
    if (userFromCache) {
        return userFromCache;
    } else {
        const user = await HttpGet(url)
            .then((response: any) => {
                httpCache[url] = response;
                return response;
            })
            .catch((error: any) => {
                if (!isProduction()) {
                    console.log(error);
                }
            });
        return user === undefined? '' : user;
    }
}

export function feasibilityToText(feasibility: any) {
    if (feasibility < 25) return 'Very unlikely';
    else if (feasibility >= 25 && feasibility < 50) return 'Unlikely';
    else if (feasibility >= 50 && feasibility < 75) return 'Likely';
    else if (feasibility >= 75 && feasibility < 100) return 'Very likely';
    else if (feasibility === 100) return 'Certain';
    return null;
}
