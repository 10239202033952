import React, { useState, useRef, useEffect } from "react";
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import { CancelToken } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import { deleteMaconomyNumber, doesMaconomyIdExist, getMaconomyListForProject, postMaconomyNumber } from "../Api/TenderRequests";
import { IProject } from "../Api/Dtos/IProjectDto";

interface Props {
  project: IProject;
}

// TODO ensure that the page gets reloaded/ data is fetched again, else the maconomyNumber change is not visible if the users stay on that project
export const MultipleMaconomyNumberInput: React.FC<Props> = (props) => {
  const [maconomyNumbers, setMaconomyNumbers] = useState<string[]>([]);
  const [exists, setExists] = useState(true);
  const [existsInArray, setExistsInArray] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const source = CancelToken.source();

  useEffect(() => {
    const fetchMaconomyNumbers = async () => {
      const maconomyNumbers = await getMaconomyListForProject(props.project.id);
      setMaconomyNumbers(maconomyNumbers);
    };

    fetchMaconomyNumbers();

    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, []);

  const checkMaconomyNumber = async (maconomyNumber: string) => await doesMaconomyIdExist(maconomyNumber, source.token);
  const findActiveMaconomyNumber = () => maconomyNumbers.find(m => m === props.project.maconomyId) ?? maconomyNumbers[0];
  const isInMaconomyArray = (maconomyNumber: string) => maconomyNumbers.includes(maconomyNumber);

  // this 2 lines of code is used to place the active maconomy number at the first index of an array.
  const activeMaconomyNumber = findActiveMaconomyNumber();
  const ActiveMaconomyNumberFirstArray = [activeMaconomyNumber, ...maconomyNumbers.filter(num => num !== activeMaconomyNumber)];

  const handleEnterMaconomyNumber = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const input = event.currentTarget.innerText.trim();
  
      if (!inputRef.current || input === "") return;
  
      const result = await checkMaconomyNumber(input);
      if (isInMaconomyArray(input)) {
        setExistsInArray(true);
        setExists(true);
      }
      else if (result.exists) {
        postMaconomyNumber(input, props.project.id);
        setMaconomyNumbers([...maconomyNumbers, input]);
        inputRef.current.innerText = "";
        setExists(true);
        setExistsInArray(false);
      } 
      if (!result.exists) {
        setExists(false);
        setExistsInArray(false);
      }
    }
  };
  
  const handleDeleteMaconomyNumber = (maconomyNumber: string) => {
    deleteMaconomyNumber(maconomyNumber, props.project.id);
    const updatedMaconomyNumbers = maconomyNumbers.filter((mn) => mn !== maconomyNumber);
    setMaconomyNumbers(updatedMaconomyNumbers);
  };

  return (
    <Container>
      <Label>Maconomy number</Label>
        <InputBox
          ref={inputRef}
          contentEditable={true}
          onKeyDown={handleEnterMaconomyNumber}
          exists={exists}
          placeholder='Enter maconomy number'
        />
        { !exists && <MaconomyNotFound>This maconomy number does not exist.</MaconomyNotFound>}
        { existsInArray && <MaconomyNotFound>This maconomy number already exists for this project.</MaconomyNotFound>}
        <RelatedMaconomy>
          <Label>Related maconomy numbers</Label>
          <MaconomyNumbersContainer>
              <Maconomies>
                {ActiveMaconomyNumberFirstArray.map((maconomyNumber, index) => (
                  <Maconomy key={maconomyNumber} isActive={index === 0}>
                    <MaconomyNumber isActive={index === 0}>{maconomyNumber}</MaconomyNumber>
                    { index !== 0 && <Close onClick={() => handleDeleteMaconomyNumber(maconomyNumber)} isActive={index === 0}>X</Close> }
                  </Maconomy>
                ))}
              </Maconomies>
          </MaconomyNumbersContainer>
        </RelatedMaconomy>
    </Container>
  );
};

const MaconomyNotFound = styled.div`
  color: rgb(164, 38, 44);
  font-size: 12px;
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Label = styled.div`
  font-weight: 500;
`;

const InputBox = styled.div<{ exists: boolean }>`
  width: 261px;
  border-bottom: 1px solid ${(p: { exists: boolean }) => p.exists ? 'rgba(96, 94, 92)' : 'rgb(164, 38, 44)'};
  padding: 10px 8px;
  &:focus {
    border-bottom: 2px solid ${(p: { exists: boolean }) => p.exists ? 'blue' : 'rgb(164, 38, 44)'};
    outline: none;
    padding-bottom: 9px;
  }
  &:empty:not(:focus)::before {
    content: attr(placeholder);
    color: #999999;
  }
`;

const RelatedMaconomy = styled.div`
  padding-top: 10px;
  width: 100%;
`;

const MaconomyNumbersContainer = styled.div`
  padding-top: 10px;
  width: 100%;
`;

const Maconomies = styled.div`
  background-color: white;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const MaconomyNumber = styled.div<{ isActive: boolean}>`
  padding: ${(p: { isActive: boolean }) => (p.isActive ? "4px 8px" : "4px 4px 4px 8px")};
  display: flex;
  border-radius: ${(p: { isActive: boolean }) => (p.isActive ? "20px" : "20px 0 0 20px")};
`;

const Close = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: transparent;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  border: none;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 4px 8px;
  width: 30px;
  height: 100%;
  color: ${(p: { isActive: boolean }) => (p.isActive ? "white" : "rgb(96, 94, 92)")};
  flex: 0 0 auto;
  border-radius: 0 20px 20px 0;
  &:hover {
      background-color: rgba(0, 0, 0, 0.2);
      color: black;
  }

`;

const Maconomy = styled.div<{ isActive: boolean }>`
  display: inline-flex;
  border: 1px solid rgb(96, 94, 92);
  box-sizing: border-box;
  border: 1px solid ${(p: { isActive: boolean }) => (p.isActive ? "transparent" : "rgb(96, 94, 92)")};
  background-color: ${(p: { isActive: boolean }) => (p.isActive ? "#0D38E0" : "transparent")};
  color: ${(p: { isActive: boolean }) => (p.isActive ? "white" : "rgb(96, 94, 92)")};
  border-radius: 20px;
  margin: 4px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgb(96, 94, 92);
    border: 1px solid ${(p: { isActive: boolean }) => (p.isActive ? "#0D38E0" : "rgb(96, 94, 92)")};
    ${MaconomyNumber} {
      background-color: ${(p: { isActive: boolean }) => (p.isActive ? "#0D38E0" : "rgba(0, 0, 0, 0.1)")};
      color: ${(p: { isActive: boolean }) => (p.isActive ? "white" : "rgb(96, 94, 92)")};
    }
    ${Close} {
      background-color: rgba(0, 0, 0, 0.2);
      color: rgb(96, 94, 92);
    }
  }
`;