import React from "react";
import {
    IBasePickerStyleProps, IBasePickerStyles,
    IBasePickerSuggestionsProps,
    IStyleFunctionOrObject, ITag, TagPicker
} from "office-ui-fabric-react";
import {Label} from "../../Label";
import {request} from "../../../Utils/request";

export interface IMinimalProject {
    id: string;
    projectTitle: string;
}

interface IProjectPickerProps {
    /**
     * A function which fetches the tenders that will be displayed as selectable options.
     * Takes a string (Tender title, or LOIS code) as search input, and passes additional options for limiting the amount of search results.
     */
    fetchProjects: (searchInput: string) => Promise<IMinimalProject[]>;
    // When the list of selected tenders changes, this function is fired. It returns a list of all selected tenders. Value will be undefined if no tenders are selected.
    onChange: (projects: string[] | undefined) => void;
    // The label displayed above the input field.
    label?: string;
    // Only styles the overlying div, not the input.
    className?: string;
    // Only styles the overlying div, not the input.
    style?: React.CSSProperties;
}

/**
 * Used to pick a project from a pill input.
 * Returns an array of all picked project IDS when a project is added or removed from the input.
 * @param props - IProjectPickerProps
 * @constructor
 */
export const ProjectPicker: React.FunctionComponent<IProjectPickerProps> = (props) => {

    const getTextFromItem = (item: ITag) => item.name;

    const projectToTags = (projects: IMinimalProject[]): ITag[] => projects.map<ITag>( project => ({key: project.id, name: project.projectTitle }));
    const tagsToProject = (tags: ITag[]): string[] => tags.map<string>( tag => tag.key as string);

    const filterSuggestedTags = async (filterText: string): Promise<ITag[]> => {

        const {result, error} = await request<IMinimalProject[]>(props.fetchProjects(filterText));

        if (!result || !result.length || error) {
            console.error("An error occurred attempting to fetch projects in the Project Picking component.");
            return [];
        }

        const r = projectToTags(result);
        return r;
    };

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested projects',
        noResultsFoundText: 'No projects found',
    };

    const style: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> | undefined = {
        text: {
            borderLeft: "none",
            borderTop: "none",
            borderRight: "none",
        }
    }

    return (
        <div style={props.style} className={props.className}>
            <Label>{props.label}</Label>
            <TagPicker
                styles={style}
                removeButtonAriaLabel="Remove"
                onResolveSuggestions={filterSuggestedTags}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={pickerSuggestionsProps}
                itemLimit={1}
                onChange={(tags: ITag[] | undefined) => tags ? props.onChange(tagsToProject(tags)) : undefined}
            />
        </div>
    );
};

