import React, {useEffect, useState} from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {Connection} from "./Connection";
import {IConnectionDto, IConnectionTypesDto} from "../../../Api/Dtos/IConnectionDto";
import {AddConnection} from "./AddConnection";
import Button from "ibis-design-system/lib/components/core/Button";
import {getConnectionData, removeConnectionData} from "../../../Api/TenderRequests";
import {request} from "../../../Utils/request";
import {EPermission} from "../../../Api/Dtos/EPermission";
import {hasPermission} from "../../../Utils/hasPermission";

// TODO move this to the settings app, from where it can be fetched and exposed using React Context.
export const ConnectionTypes: IConnectionTypesDto = {
    duplicates: {
        label:"Duplicates",
        description:"Tender is published by multiple sources."
    },
    extends: {
        label:"Extends",
        description:"Tender is part of a set of projects or a program."
    },
    risk: {
        label:"Risk/competing",
        description:"Other tender forms a risk or is competing."
    }
};

interface IConnectionsProps extends IBaseProps {
    tenderId: string;
    permissions: EPermission[];
}

export const Connections: React.FunctionComponent<IConnectionsProps> = (props) => {

    const [connections, setConnections] = useState<IConnectionDto[]>([]);
    const [adding, setAdding] = useState<boolean>(false);
    
    useEffect(() => {
        fetchConnections(props.tenderId);

        return () => undefined;
    }, [props.tenderId]);
    
    const fetchConnections = async (tenderId: string): Promise<void> => {
        if (!tenderId) { return; }

        const {result, error} = await request<IConnectionDto[] | undefined>(getConnectionData(tenderId));

        if (!result || error) {
            console.log(`Something went wrong trying to fetch connections for tender with ID: ${tenderId}`);
            return;
        }
        setConnections(result);
    }

    const afterNewConnectionAdded = async (): Promise<void> => {
        setAdding(false);
        fetchConnections(props.tenderId);
    }

    const onRemove = async (connectedTenderId: string): Promise<void> => {
        if (!connectedTenderId) { return; }

        const {result, error} = await request(removeConnectionData(props.tenderId, connectedTenderId));

        if (!result || error) {
            console.error("Something went wrong trying to delete the Connection.");
        }

        fetchConnections(props.tenderId);
    }

    return (
        <div style={props.style} className={props.className}>
            {connections && connections.length ? connections.map( (connection: IConnectionDto, index: number) => {
                return (
                    <div key={connection.id} style={{marginTop: index < connections.length ? 1 : 0}}>
                        <Connection permissions={props.permissions} connection={connection} tenderId={props.tenderId} onRemove={() => onRemove(connection.id)} />
                    </div>
                )
            }) : null}
            { hasPermission(props.permissions) ?
                adding ?
                <AddConnection tenderId={props.tenderId} cancel={() => setAdding(false)} onAdded={() => afterNewConnectionAdded()} />
                :
                <Button variant="commandBar" icon="Add" onClick={() => setAdding(true)}>
                    Add connection
                </Button>
                : null
            }
        </div>
    );
};
