import React, { useEffect, useState } from 'react';
import moment from 'ibis-design-system/lib/HelperFunctions/ThirdParty/moment';

import Text from 'ibis-design-system/lib/components/Atoms/Text';
import Box from 'ibis-design-system/lib/components/core/Box';
import { useNavigationState } from 'ibis-design-system/lib/Providers/NavigationProvider';

import ListItem from 'ibis-design-system/lib/components/Molecules/ListItem';
import PersonaContainer from './PersonaContainer';
import { useCacheState } from 'ibis-design-system/lib/Providers/HttpCacheProvider';

function ActivitiesList(props) {
    const { activities } = props;

    return (
        <Box mb={3}>
            {activities?.map((x) => (
                <Activity key={x.id} {...x} />
            ))}
        </Box>
    );
}

function Activity(props) {
    const { contacts, type, createdBy, date, companyName } = props;
    const { navigateToErrorPage } = useNavigationState();
    const cache = useCacheState();

    const [contactsText, setContactsText] = useState([]);

    useEffect(() => {
        if (!contacts) return;
        const contactString = [];

        contacts.map((user, index) => {
            if (index === 0) {
                user.anonymous ? contactString.push('anonymous') : contactString.push(user.id);
            } else if (index === contacts.length - 1)
                user.anonymous
                    ? contactString.push(' and anonymous')
                    : contactString.push(' and ' + user.id);
            else
                user.anonymous
                    ? contactString.push(', anonymous')
                    : contactString.push(', ' + user.id);
        });

        setContactsText(contactString.join(''));
    }, [cache, contacts, navigateToErrorPage]);

    function ActivityTypes(type) {
        switch (type) {
            case 'MailIn':
                return 'Mail IN from ';
            case 'MailOut':
                return 'Mail OUT to ';
            case 'VisitIn':
                return 'Visit IN by ';
            case 'VisitOut':
                return 'Visit OUT to ';
            case 'SocialNetworkIn':
                return 'Social network IN from ';
            case 'SocialNetworkOut':
                return 'Social network OUT to ';
            case 'CallIn':
                return 'Call IN from ';
            case 'CallOut':
                return 'Call OUT to ';
            case 'SupportIn':
                return 'Support IN by ';
            case 'SupportOut':
                return 'Support OUT from ';
            case 'Note':
                return 'Note: ';
            default:
                return null;
        }
    }

    return (
        <Box>
            <ListItem
                title={ActivityTypes(type) ? ActivityTypes(type) + contactsText : null}
                content={<Box>{companyName}</Box>}
                persona={createdBy ? <PersonaContainer userId={createdBy} /> : null}
                date={
                    <Box style={{ textAlign: 'right' }}>
                        <Text as="span">{moment(date).format('DD-MM-YYYY HH:mm')}</Text>
                    </Box>
                }
            />
        </Box>
    );
}

export default ActivitiesList;
