import React, { useMemo } from "react";
import ItemTemplate from 'ibis-design-system/lib/components/core/ListItemTemplate';
import Box from 'ibis-design-system/lib/components/core/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';
import Icon from 'ibis-design-system/lib/components/core/Icon';
import Text from 'ibis-design-system/lib/components/Atoms/Text';
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';

import {ITenderOverviewDto} from "../Api/Dtos/ITenderOverviewDto";
import moment from "moment";
import {IProject, Stages} from "../Api/Dtos/IProjectDto";
import { ConvertNumberToArchivalReason } from "../Utils/convertNumberToArchivalReason";
import { convertProjectStages } from "../Constants";

interface ITenderMenuCardProps {
    item: ITenderOverviewDto;
    isSelected: any;
    isHeader: any;
    onToggleItem: any;
    onRowClick: any;

}

const Title = styled.h2`
    font-weight: ${(props: any) => props.theme.fontWeights.semiBold};
    color: ${(props: any) =>
    props.highlight ? props.theme.colors.themePrimary : props.theme.colors.neutralPrimary};
    font-size: 14px;
    margin: 0;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    
    span {
      white-space: normal;
    }
`;

export const TenderMenuCard: React.FunctionComponent<ITenderMenuCardProps> = (props) => {
    const deadline = moment(props.item.tenderDeadLine).unix();
    const oneWeekFromNow = moment().add(7, "days").unix();
    const DateEmployee = new Date(props.item.tenderDeadLine!);
    const now = moment().unix();

    const ArchivalReasons = () => {
        if (props.item.archivalReasons?.length) {
            return props.item.archivalReasons?.map((y: any) => ConvertNumberToArchivalReason(y.reason)).slice(-1);
        }
    };

    const title = useMemo(() => {
        if (props.item.assignmentTitle) return props.item.assignmentTitle;
        if (props.item.linkedProject && props.item.linkedProject.projectTitle) return props.item.linkedProject.projectTitle;
    }, [props.item]);

    const Stages = useMemo(() => { 
        if (props.item.internalStages?.length){
        return props.item.internalStages?.map((y:any) => y.stage).slice(-1);
        }
    }, [props.item]);


    return (
        <ItemTemplate isSelected={props.isSelected} onToggle={props.onToggleItem} onClick={props.onRowClick} hasNotifications={props.item?.hasNotifications}>
            <ItemTemplate.SubText>
                <Box>
                    <Flex>
                        {deadline < oneWeekFromNow && deadline >= now ? (
                            <Box flex="0 0 20px" mr="8px">
                                <Icon
                                    name="AlarmClock"
                                    size="xs"
                                    title="Reminder"
                                    aria-label="Reminder"
                                />
                            </Box>
                        ) : null}
                        <Box flex={1} style={{ overflow: 'hidden' }}>
                            <Flex>
                                <Title>
                                <Text as="span" fontWeight="bold" title={title}>
                                    {props.item.loisCode}: {title?.length > 50 ? title.slice(0, 50) + "..." : title}
                                </Text>
                                </Title>
                            </Flex>
                            {/* checks if an entity is archived, archive state === 2 */}
                            {props.item.state === 2 
                            ? 
                            <Box>
                                Archival reason: <Text isSelected={props.isSelected} as="span">{ArchivalReasons()?.toString().replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1')}</Text>
                            </Box>
                            : 
                            <Box>
                                Stage: <Text isSelected={props.isSelected} as="span">{Stages?.toString().replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1')}</Text>
                            </Box>
                            }
                            <Box>
                                Tender deadline: <Text isSelected={props.isSelected} as="span">{DateEmployee.toLocaleDateString("en-GB")}</Text>
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            </ItemTemplate.SubText>
        </ItemTemplate>
    );
};

interface IProjectMenuCard {
    item: IProject | ITenderOverviewDto;
    isSelected: any;
    isHeader: any;
    onToggleItem: any;
    onRowClick: any;

}

export const ProjectMenuCard: React.FunctionComponent<IProjectMenuCard> = (props) => {
    const project = props.item as IProject;
    const stages = project.stages;

    const sortedStages = stages?.sort((a: Stages, b: Stages) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA;
    });

    const mostRecentStage = sortedStages?.length > 0 ? sortedStages[0]?.stage : 1;

    // @ts-ignore
    let data: IProject = props.item.linkedProject ? props.item.linkedProject : props.item;

    return (
        <ItemTemplate isSelected={props.isSelected} onToggle={props.onToggleItem} onClick={props.onRowClick} hasNotifications={false}>
            <ItemTemplate.SubText>
                <Box>
                    <Flex>
                        <Box flex={1} style={{ overflow: 'hidden' }}>
                            <Flex>
                                <Title>
                                    <Text as="span" fontWeight="bold">
                                        {data.maconomyId ? data.maconomyId : "- "}: {data.projectTitle}
                                    </Text>
                                </Title>
                            </Flex>
                            <Box>
                                Stage: <Text isSelected={props.isSelected} as="span">{convertProjectStages(mostRecentStage)}</Text>
                            </Box>
                            {project.userContext?.isSelected ? 
                                <Box>
                                    <Text isSelected={props.isSelected} as="span" style={{fontStyle: 'italic'}}>Added to export list</Text>
                                </Box> 
                            : null}
                        </Box>
                    </Flex>
                </Box>
            </ItemTemplate.SubText>
        </ItemTemplate>
    );
};