import React, {useMemo} from "react";
import WatchAbleScrollContainer from "ibis-design-system/lib/components/watchAbleComponents/WatchAbleScrollContainer";
import Flex from "ibis-design-system/lib/components/Atoms/Flex";
import Editview from "ibis-design-system/lib/components/core/Editview";
import {FinanceGeneral} from "../Components/FinanceTab/FinanceGeneral";
import {Budget} from "../Components/FinanceTab/Budget/Budget";
import {ITenderOverviewDto} from "../Api/Dtos/ITenderOverviewDto";
import {isProject} from "../Utils/isProject";
import {Tax} from "../Components/FinanceTab/Tax";
import {Payment} from "../Components/FinanceTab/Payments";
import {PatchParams} from "../Interfaces/PatchParams";
import DefaultDetailview from 'ibis-design-system/lib/components/core/DefaultDetailview/DefaultDetailview';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';

interface IFinanceProps {
    item: ITenderOverviewDto;
    patch: PatchParams;
    userContext: any;
}

export const Finance: React.FunctionComponent<IFinanceProps> = (props) => {

    const tenderId = useMemo(() => isProject() ? props.item.linkedTender as string : props.item.id, [props.item]);

    const subjects = [
        {
            id: 'budget',
            name: 'Overview Partners, Financier and Budget',
            component: <Budget {...props} userContext={props.userContext} project={props.item.linkedProject} patch={props.patch} />,
            showHeader: true,
        },
        {
            id: 'general',
            name: 'General',
            component: <FinanceGeneral userPermissions={props.userContext.permissions} tenderId={tenderId} project={props.item.linkedProject} patch={props.patch} />,
            showHeader: false,
        },
        {
            id: 'tax',
            name: 'Tax',
            component: <Tax userPermissions={props.userContext.permissions} tenderId={tenderId} patch={props.patch} project={props.item.linkedProject} />,
            showHeader: true,
        },
        {
            id: 'payment',
            name: 'Payments',
            component: <Payment userPermissions={props.userContext.permissions} style={{paddingBottom: 150}} tenderId={tenderId} project={props.item.linkedProject} patch={props.patch} />,
            showHeader: true,
        }
    ];

    if (!props.item || !props.item.linkedProject) {
        if(props.item.secret){
            
            return <Box flex="1 1 auto" p={3} bg="light" ><DefaultDetailview title="You do not have permission" icon="LockSolid"><p></p></DefaultDetailview></Box>
            
        }
        else{
        return <div>loading...</div>
        }
        
    }

    return (
        <div style={{width: "100%"}}>
        <WatchAbleScrollContainer>
            {/* this calculates the max width of the editfield by deducting the width of the navigation (200px) and padding (60px) */}
            <Flex width="calc(100% - 260px)" pl={3}>
            <Editview subjects={subjects} />
            </Flex>
        </WatchAbleScrollContainer>
        </div>
    );
};
