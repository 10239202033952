import React, { useState } from "react";
import LocationPicker from 'ibis-design-system/lib/components/pickers/LocationPicker';
import {EPermission} from "../../Api/Dtos/EPermission";
import {ILocationDto, ITenderOverviewDto} from "../../Api/Dtos/ITenderOverviewDto";
import Settings from "../../Settings";
import {isEmptyOrSpaces} from "ibis-design-system/lib/HelperFunctions/StringHelper";
import {useCacheState} from 'ibis-design-system/lib/Providers/HttpCacheProvider';
import {HttpGet} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import {useCountries, useRegions} from "../../Hooks/GlobalConfigContext";
import {AddLocation} from "./AddLocation";
import {PatchParams} from "../../Interfaces/PatchParams";
import {EOP, IPatchParams} from "../../Interfaces/IPatchParams";
import { isProject } from "../../Utils/isProject";
import { UseAsDPPLocationRadioButton } from "../UseAsDPPLocationRadioButton";
import MessageBar from 'ibis-design-system/lib/components/core/MessageBar';

interface ILocationProps {
    permissions: EPermission[];
    tender: ITenderOverviewDto;
    patch: PatchParams;
    tenderId: string;
    variant: 'region' | 'country' | 'city' | 'dpp'
}

export const Location: React.FunctionComponent<ILocationProps> = (props) => {
    const cache = useCacheState();
    const regions = useRegions();
    const countries = useCountries();
    const [selectedLocation, setSelectedLocation] = useState<string>('');

    async function getCities(value: string, take: number, regionId: string, countryId: string): Promise<any> {
        if (value.length < 3) return;
        let url = `${Settings.apiUrls.globalConfig}/cities/search?take=${take}`;
        if (!isEmptyOrSpaces(value)) url += `&term=${value.toLowerCase()}`;
        // if (regionId) url += `&region=${regionId}`;
        // if (countryId) url += `&country=${countryId}`;

        const citiesFromLocal = cache[url];
        if (citiesFromLocal) return citiesFromLocal;

        return HttpGet(url).then((response: any) => {
            cache[url] = response;
            return response;
        });
    }

    const updateLocation = (location: ILocationDto, locationId: string) => {
        let patches: IPatchParams<any, any>[] = [], tenderId;
        const fields = Object.keys(location);
        
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            
            const value = String(location[field as keyof ILocationDto]);
    
            const patch: IPatchParams<string, string> = {
                op: EOP.Replace,
                path: `locations/${locationId}/${field}`,
                value: value,
            };
    
            patches.push(patch);
        }

        const patchMessage = location['cityId']
            ? `Tender "${props.tender.assignmentTitle}" moved to ${location['city']}, ${location['country']}`
            : location['countryId']
                ? `Tender "${props.tender.assignmentTitle}" moved to ${location['country']}`
                : location['regionId']
                    ? `Tender "${props.tender.assignmentTitle}" moved to ${location['region']}`
                    : `Tender "${props.tender.assignmentTitle}" removed it's location`;

        if (patches.length > 0) props.patch(patches, isProject() ? props.tenderId : props.tender.id, patchMessage);
    }

    const deleteLocation = (locationId: any): void => {
        props.patch([
            {
                op: EOP.Remove,
                path: `locations/${locationId}`
            }
        ], props.tenderId)
    }

    const setAsDPPLocation = (locationId: string, locations: ILocationDto[]) => {
        const updatedLocations = locations.map(location => {
            if (location.id === locationId) {
                return { ...location, isDppMapLocation: true };
            } else {
                return { ...location, isDppMapLocation: false };
            }
        });
    
        setSelectedLocation(locationId);
        
        props.patch(
            updatedLocations.map(updatedLocation => ({
                op: EOP.Replace,
                value: updatedLocation.isDppMapLocation,
                path: `locations/${updatedLocation.id}/isDppMapLocation`,
            })),
            props.tenderId
        );
    };

    const showWarning = (isMapLocation: boolean, lat?: string, long?: string): boolean => {
        return isMapLocation && (lat === undefined || lat === '' || long === undefined || long === '');
    };

    return (
        <div>
            { props.tender.locations?.map( (location, index) => {
                return (
                    <div style={{marginTop: index > 0 ? 15 : 0}} key={location.id}>
                        <LocationPicker
                            variant={props.variant}
                            regionId={location.regionId}
                            region={location.region}
                            countryId={location.countryId}
                            country={location.country}
                            cityId={location.cityId}
                            city={location.city}
                            longitude={location.longitude}
                            latitude={location.latitude}
                            locality={location.locality}
                            onChange={(val: any) => updateLocation(val, location.id)}
                            getCities={getCities}
                            onDelete={() => deleteLocation(location.id)}
                            regions={regions}
                            countries={countries}
                            disabled={false}
                            dppLocationAreaDescription={location.dppLocationAreaDescription}
                            isDppMapLocation={location.isDppMapLocation}
                            dppRadioButtonComponent={
                                isProject() && (
                                    <div style={{paddingBottom: '10px'}}>
                                        <UseAsDPPLocationRadioButton
                                            locationId={location.id}
                                            setSelectedLocation={() => setAsDPPLocation(location.id, props.tender.locations)}
                                            isDppMapLocation={location.isDppMapLocation}
                                            />
                                    </div>
                                )
                            }
                            />
                            {showWarning(location.isDppMapLocation, location.latitude?.toString(), location.longitude?.toString()) && 
                            <MessageBar type='error'>
                               Please provide the Latitude and Longitude to avoid errors on the Deltares Profile Projects website.
                            </MessageBar>}
                    </div>
                )
            })}

            <AddLocation style={{marginTop: 12}} patch={props.patch} tenderId={isProject() ? props.tender.linkedTender as string : props.tender.id} locationLength={props.tender.locations.length} />
        </div>
    );
};
