import React, {useMemo, useState} from "react";
import Flex from "ibis-design-system/lib/components/Atoms/Flex";
import Dropdown from "ibis-design-system/lib/components/inputFields/Dropdown";
import WatchAbleComponent from 'ibis-design-system/lib/components/watchAbleComponents/WatchAbleComponent';
import DatePicker from 'ibis-design-system/lib/components/inputFields/DatePicker';
import Settings from "../../Settings";
import {useCurrencies} from "../../Hooks/GlobalConfigContext";
import moment from "moment";
import {Conversion} from "./Conversion";
import {findActiveCurrency} from "../../Utils/findActiveCurrency";

interface IExchangeRateProps {
    currency: string;
    canEdit: boolean
    currencyConversionDate: string;
    commercialBudget: any;
    updateConversionDate: any;
    updateCurrency: (currencyId: string | undefined) => void;
    conversionData: any;
    setConversionData: Function
    updateConversionData: (data: any) => void;

    
}

export const ExchangeRate: React.FunctionComponent<IExchangeRateProps> = (props) => {

    const currencies = useCurrencies();
    const [currency, setCurrency] = useState<string | undefined>(findActiveCurrency(props.currency, currencies));
    const [currencyConversionDate, setCurrencyConversionDate] = useState<string>(props.currencyConversionDate);
    // const [conversionData, setConversionData] = useState<any>(null);
    const updateConversionData = (data: any) => {
        props.setConversionData(data);
      };
    const customCurrencies = useMemo(() => {
        if (!currencies || currencies.length === 0) return [];
        const additionalItems = [];

        // Find the most common 2 currencies "USD and EUR"
        const eur = currencies.find((c) => c.isO4217 === 'EUR');
        if (eur)
            additionalItems.push({
                id: eur.isO4217,
                label: eur.isO4217,
                value: eur,
                title: eur.label,
            });

        const usd = currencies.find((c) => c.isO4217 === 'USD');
        if (usd)
            additionalItems.push({
                id: usd.isO4217,
                label: usd.isO4217,
                value: usd,
                title: usd.label,
            });

        // If we find them or one of them create a separated layer
        if (additionalItems.length > 0) {
            additionalItems.unshift({ id: 'headerCommon', label: 'Most common', type: 'header' });
            additionalItems.push({ id: 'divider', label: '-', type: 'divider' });
            additionalItems.push({ id: 'headerOther', label: 'Other', type: 'header' });
        }

        // Map the array so that the ISO code is used as value
        const copy = currencies.map((c) => {
            return { id: c.isO4217, label: c.isO4217, value: c, title: c.label };
        });

        //Sort by label
        copy.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

        return additionalItems.concat(copy);
    }, [currencies]);

    const updateConversionDate = (date: string) => {
        const newDate = moment(date).toISOString();
        setCurrencyConversionDate(moment(date).toISOString());
        props.updateConversionDate(newDate)
    };
 
    return (
        <Flex mb={3}>
            <WatchAbleComponent
                id="currency"
                applicationId={Settings.appId}
                mr={2}
                width="130px"
            >
                <Dropdown
                    label="Currency"
                    options={customCurrencies}
                    selected={currency}
                    multiSelect={false}
                    disabled={props.canEdit === false}
                    onChanged={(change: { key: string }) => {
                        setCurrency(change.key);
                        const c = currencies.find( cu => cu.isO4217 === change.key);
                        props.updateCurrency(c && c.id ? c.id : undefined);
                        // If there is no conversionDate yet set the conversionDate to today
                        if (currencyConversionDate === null) {
                           setCurrencyConversionDate(moment().toISOString());
                        }
                    }}
                />
            </WatchAbleComponent>
            {currency && currencies.length > 0 && 'EUR' !== currency ? (
                <>
                    <WatchAbleComponent
                        id="currencyConversionDate"
                        applicationId={Settings.appId}
                        width="130px"
                        mr={2}
                    >
                        <DatePicker
                            label="Exchange rate date"
                            value={currencyConversionDate}
                            disabled={props.canEdit === false}
                            onSelect={(date: any) => updateConversionDate(date)}
                        />
                    </WatchAbleComponent>
                    <Conversion
                        date={currencyConversionDate}
                        currency={currency}
                        amount={props.commercialBudget}
                        updateConversionData={updateConversionData}
                        conversionData={props.conversionData}
                        
                    />
                </>
            ) : null}
        </Flex>
    );
};
