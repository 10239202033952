import React, {useEffect, useState} from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {Connection} from "./Connection";
import {IConnectionTypesDto, IProjectConnectionDto} from "../../../Api/Dtos/IConnectionDto";
import {AddConnection} from "./AddConnection";
import Button from "ibis-design-system/lib/components/core/Button";
import {deleteProjectConnection, getProjectConnectionData} from "../../../Api/TenderRequests";
import {IProject} from "../../../Api/Dtos/IProjectDto";
import {IPatchParams} from "../../../Interfaces/IPatchParams";
import {request} from "../../../Utils/request";
import {isProject} from "../../../Utils/isProject";
import {EPermission} from "../../../Api/Dtos/EPermission";
import { Console } from "console";

// TODO move this to the settings app, from where it can be fetched and exposed using React Context.
export const ConnectionTypes: IConnectionTypesDto = {
    duplicates: {
        label:"Duplicates",
        description:"Tender is published by multiple sources."
    },
    extends: {
        label:"Extends",
        description:"Tender is part of a set of projects or a program."
    },
    risk: {
        label:"Risk/competing",
        description:"Other tender forms a risk or is competing."
    }
};

interface IConnectionsProps extends IBaseProps {
    tenderId: string;
    project: IProject;
    permissions: EPermission[];
}

export const Connections: React.FunctionComponent<IConnectionsProps> = (props) => {

    const [connections, setConnections] = useState<IProjectConnectionDto[]>([]);
    const [adding, setAdding] = useState<boolean>(false);

    useEffect(() => {
        fetchConnections(props.tenderId);
        return () => undefined;
    }, [props.project]);
    
    const fetchConnections = async (tenderId: string): Promise<void> => {
        if (!tenderId) { return; }

        const {result, error} = await request<IProjectConnectionDto[] | undefined>(getProjectConnectionData(isProject() ? props.project.linkedTender as string : tenderId));

        if (!result || error) {
            console.log(`Something went wrong trying to fetch connections for tender with ID: ${tenderId}`);
            return;
        }
        setConnections(result);
    }

    const afterNewConnectionAdded = async (): Promise<void> => {
        setAdding(false);
        fetchConnections(props.tenderId);

    }

    const onRemove = async (connectionId: string, projectId: string): Promise<void> => {
        if (!connectionId) { return; }

        const {result, error} = await request(deleteProjectConnection(projectId, connectionId));

        if (!result || error) {
            console.error("Something went wrong trying to delete the Connection.");
        }
        if (props.tenderId) {
            console.log (connections.map(connection => connection.type.description))
         }

        fetchConnections(props.tenderId);
    }
    
    return (
        <div style={props.style} className={props.className}>
            {connections && connections.length ? connections.map( (connection: IProjectConnectionDto, index: number) => {
                return (
                    <div key={connection.id} style={{marginTop: index < connections.length ? 1 : 0}}>
                        <Connection connection={connection} onRemove={() => onRemove(connection.id, connection.projectId)} />
                        <div>{connection.type.description}</div>
                    </div>
                )
            }) : null}
            { adding ?
                <AddConnection tenderId={props.tenderId} cancel={() => setAdding(false)} onAdded={() => afterNewConnectionAdded()} project={props.project} projectId={props.project.id} />
                :
                <Button variant="commandBar" icon="Add" onClick={() => setAdding(true)}>
                    Add connection
                </Button>
            }
        </div>
    );
};
