import React from "react";
import {IBaseProps} from "../../Interfaces/IBaseProps";
import TextInput from "ibis-design-system/lib/components/inputFields/TextInput";
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import DatePicker from "ibis-design-system/lib/components/inputFields/DatePicker";
import SDGs from "ibis-design-system/lib/components/inputFields/SDGs";
import WYSIWYGEditor from "ibis-design-system/lib/components/inputFields/WYSIWYGEditor";
import Settings from "../../Settings";
import {useDPPKeywords, useKeywords, useNatures, useSdgs} from "../../Hooks/GlobalConfigContext";
import {WYSIWYG_dataConversion} from "../../Utils/WYSIWYG_dataConversion";
import {IUpdateSdgResponse} from "../../Interfaces/IUpdateSdgResponse";
import {IProject} from "../../Api/Dtos/IProjectDto";
import {EPermission} from "../../Api/Dtos/EPermission";
import {hasPermission} from "../../Utils/hasPermission";
import {PatchParams} from "../../Interfaces/PatchParams";
import {EOP} from "../../Interfaces/IPatchParams";
import {KeywordPicker} from "../KeywordPicker";
import { ITenderOverviewDto } from "../../Api/Dtos/ITenderOverviewDto";
import { IFoundation } from "../../Interfaces/IFoundation";
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import { ReadOnlyInput } from "../MaconomyTurnoverTab/ReadOnlyInput";
import { calculateRemainingMonths } from "../../Utils/calculateRemainingMonths";
import { calculateDurationOfMonths } from "../../Utils/calculateDurationOfMonths";
import { IsContinousRadioButton } from "../ContinousRadioButton";
import { IncludeInDPPToggle } from "../IncludeInDPPToggle";
import { Project } from "../../Containers/Project";

interface IProjectGeneralProps extends IBaseProps {
    tenderId: string;
    project: IProject;
    permissions: EPermission[];
    patch: PatchParams;
    tender?: ITenderOverviewDto;
}
const LinkText = styled.a`
    color: ${(props: IFoundation) => props.theme.colors.themePrimary};
    font-size: ${(props: IFoundation) => props.theme.fontSizes.s};
    margin-left: 39px;
    align-self: flex-end;
    margin-bottom: 7px;
    cursor: pointer;
    text-decoration: none;
`
const NoLinkText = styled.span`
  color: ${(props: IFoundation) => props.theme.colors.neutralSecondary};
  font-size: ${(props: IFoundation) => props.theme.fontSizes.s};
  margin-left: 39px;
  align-self: flex-end;
  margin-bottom: 7px;
  cursor: pointer;
`

export const ProjectGeneral: React.FunctionComponent<IProjectGeneralProps> = (props) => {
    const lastLink = props.project.links[props.project.links.length - 1]?.link;
    const keywords = useKeywords()
    const dppKeywords = useDPPKeywords()
    const sdgs = useSdgs();
    const projectLinks = props.project.links;
    console.log(props.project.numberOfStaff);
    return (
        <div className={props.className} style={props.style}>
            <Flex style={{gap: '20px'}}>
                <Box width="421px">
                    <TextInput
                        label="Project title"
                        value={props.project?.projectTitle}
                        onChanged={(value: string) => props.patch([
                            {
                                op: EOP.Replace,
                                value,
                                path: '/linkedProject/projectTitle'
                            }
                        ], props.project.linkedTender)}
                        disabled={!hasPermission(props.permissions)}
                        />
                </Box>
                <Box width="421px">
                    <TextInput
                        label="External reference"
                        value={props.project?.externalReference}
                        onChanged={(value: string) => props.patch([
                            {
                                path: `/linkedProject/externalReference`,
                                value,
                                op: EOP.Replace
                            }
                        ], props.project.linkedTender)}
                        disabled={!hasPermission(props.permissions)}
                    />
                </Box>
            </Flex>

            <Flex mt={3} width="842px" justifyContent="space-between">
                <Box width="129px">
                    <DatePicker
                        label="Start date"
                        onSelect={(value: string) => props.patch([
                            {
                                op: EOP.Replace,
                                value,
                                path: '/linkedProject/startDate'
                            }
                        ], props.project.linkedTender)}
                        value={props.project?.startDate}
                        disabled={!hasPermission(props.permissions)}
                    />
                </Box>
                <Box width="129px" style={{position: "relative", right: "24px"}}>
                    <DatePicker
                        minDate={new Date(props.project?.startDate)}
                        label="End date"
                        onSelect={(value: string) => props.patch([
                            {
                                op: EOP.Replace,
                                value,
                                path: '/linkedProject/endDate'
                            }
                        ], props.project.linkedTender)}
                        value={props.project?.endDate}
                        disabled={!hasPermission(props.permissions)}
                    />
                </Box>
                <Box width="210px" style={{position: "relative", right: "192px"}}>
                    <TextInput
                        width={420 + "!important"}
                        label="No. of staff"
                        value={props.project?.numberOfStaff}
                        onChanged={(value: string) => props.patch([
                            {
                                path: `/linkedProject/numberOfStaff`,
                                value,
                                op: EOP.Replace
                            }
                        ], props.project.linkedTender)}
                        disabled={!hasPermission(props.permissions)}
                    />
                </Box>
            </Flex>

            <Flex mt={3} style={{ gap: '10px'}}>
                <Box style={{width: '200px'}}>
                    <ReadOnlyInput 
                        label="Duration of assignment" 
                            text={`${calculateDurationOfMonths(new Date(props.project.startDate), new Date(props.project.endDate), 'long')}`}
                    />
                </Box>
                <Box style={{width: '200px'}}>
                    <ReadOnlyInput 
                        label="Remaining" 
                            text={`${calculateRemainingMonths(new Date(props.project.startDate), new Date(props.project.endDate), 'long')}`}
                    />
                </Box>
            </Flex>

            <Box mt={3} >
                    <IncludeInDPPToggle project={props.project} patch={props.patch}/>
                    <IsContinousRadioButton project={props.project} patch={props.patch}/>
            </Box>

            <Box mt={3}>
                <WYSIWYGEditor
                    label="Brief description"
                    content={WYSIWYG_dataConversion(props.project.briefDescription)}
                    onUpdate={(value: string) =>  props.patch([
                        {
                            op: EOP.Replace,
                            value,
                            path: '/linkedProject/briefDescription'
                        }
                    ], props.project.linkedTender)}
                />
            </Box>

            <Box mt={3}>
                <WYSIWYGEditor
                    label="Narrative description"
                    content={WYSIWYG_dataConversion(props.project.narrativeDescription)}
                    onUpdate={(value: string) =>  props.patch([
                        {
                            op: EOP.Replace,
                            value,
                            path: '/linkedProject/narrativeDescription'
                        }
                    ], props.project.linkedTender)}
                />
            </Box>

            <Box mt={3}>
                <WYSIWYGEditor
                    label="Actual Services Provided"
                    content={WYSIWYG_dataConversion(props.project.servicesProvided)}
                    onUpdate={(value: string) =>  props.patch([
                        {
                            op: EOP.Replace,
                            value,
                            path: '/linkedProject/servicesProvided'
                        }
                    ], props.project.linkedTender)}
                />
            </Box>
            <Flex mt={3}>
                <Box style={{width: 420}}>
                    <TextInput
                        label="Direct link to publication"
                        placeholder="Enter URL to publication"
                        // always take the last one because the backend expects a list for some reason
                        value={projectLinks[projectLinks.length - 1]?.link}
                        onChanged={(value: string) => {
                            props.patch([{
                              op: EOP.Add,
                              value: {link: value, projectId: props.project.id},
                              path: `/linkedProject/links/-`
                            }], props.project.linkedTender)
                          }}
                        disabled={!hasPermission(props.permissions)}
                    />
                </Box>
                { props.project?.links ?
                    <LinkText href={lastLink} target="_blank">Open link in browser</LinkText>
                    :
                    <NoLinkText>Open link in browser</NoLinkText>
                }
            </Flex>
            <div style={{width: 440, marginTop: 30}}>
                <SDGs
                    availableSDGs={sdgs}
                    pickedSDGs={props.project?.sustainableDevelopmentGoals ? props.project?.sustainableDevelopmentGoals : []}
                    fileServiceUrl={Settings.apiUrls.fileService}
                    listener={(element: string, next: IUpdateSdgResponse) => {
                        props.patch([next], props.project.linkedTender) ;
                    }}
                    field="/linkedProject/sustainableDevelopmentGoals"
                />
            </div>
            <Flex style={{gap: 30}}>  
                <Box mt={2} width="421px">
                    <KeywordPicker
                        label="Keywords"
                        keywords={keywords}
                        selectedKeywords={props.project?.keywords ? props.project.keywords : []}
                        onChange={(patch) => patch? props.patch([patch], props.project.linkedTender) : undefined}
                        field="/linkedProject/keywords"
                        />
                </Box>
                <Box mt={2} width="421px">
                    <KeywordPicker
                        label="Deltares Profile Projects Keywords"
                        keywords={dppKeywords}
                        selectedKeywords={props.project?.dppKeywords ? props.project.dppKeywords : []}
                        onChange={(patch) => patch? props.patch([patch], props.project.linkedTender) : undefined}
                        field="/linkedProject/dppKeywords"
                        />
                </Box>
            </Flex>    
        </div>
    );
};
