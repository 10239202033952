import React from "react";
import {Checkbox, TextField} from "office-ui-fabric-react";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  width: 100%;
  margin-bottom: 13px;
`

interface ISelectableInputProps {
    value?: any;
    selected?: boolean;
    onChange?: () => void;
    label?: string;
}

export const SelectableInput: React.FunctionComponent<ISelectableInputProps> = (props) => {
    return (
        <Row onClick={props.onChange} style={{position: "relative"}}>
            <Checkbox styles={{root: {position: "absolute", top: 10}}} checked={props.selected} disabled={props.value === undefined} />
            <div style={{marginLeft: 25, width: "100%"}}>
                <TextField
                    label={props.label}
                    style={{cursor: "pointer"}}
                    styles={{root: {width: "100%"}}}
                    borderless
                    width={300}
                    placeholder=""
                    value={props.value}
                    contentEditable={false}
                    disabled={!props.selected}
                />
            </div>
        </Row>
    );
};
