import React from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import Flex from 'ibis-design-system/lib/components/core/Flex';
import {IFoundation} from "../../../Interfaces/IFoundation";
import { IProject } from "../../../Api/Dtos/IProjectDto";

const Card = styled.div`
    border-top: ${(props: IFoundation) => `1px solid ${props.theme.colors.neutralQuaternaryAlt}`};
    padding: 0 10px 0 10px;
`;
const Percentage = styled.div`
    color: #00000054;
    font-size: ${(props: IFoundation) => props.theme.fontSizes.xxs};
`
interface IParticipantCardProps extends IBaseProps {
    currency: string;
}

export const TotalHeader: React.FunctionComponent<IParticipantCardProps> = (props) => {

    return (
        <Card style={{position: "relative", ...props.style}} className={props.className}>
            <Flex>
                <Flex style={{flex: 2, alignItems: "center"}} />
                <Flex style={{flex: 2, alignSelf: "flex-end", justifyContent: "flex-end", alignItems: "center", height: 32}}>
                    <Flex style={{flex: 1}}>
                        <Flex style={{flex: 1, alignItems: "center"}}>
                            <div>
                                <div style={{width: 131}}>
                                    <div style={{display: "flex", marginLeft: props.currency === '-' ? 35 : 56}}>
                                        <strong>Value</strong>
                                    </div>
                                </div>
                            </div>
                            <Percentage style={{marginLeft: '10%'}}> </Percentage> 
                        </Flex>
                    </Flex>
                    { String(props.currency).valueOf() !== "EUR" && String(props.currency).valueOf() !== '-' && props.currency !== undefined ?
                    <Flex style={{flex: 1}}>
                        <Flex style={{flex: 1, alignItems: "center"}}>
                            <div>
                                <div style={{width: 131}}>
                                    <div style={{display: "flex"}}>
                                        <strong>Value converted </strong>
                                    </div>
                                </div>
                            </div>
                            <Percentage style={{marginLeft: '10%'}}> </Percentage> 
                        </Flex>
                    </Flex>
                    : <></>}
                    <Flex ml={3} mr={3} stye={{flex: 1}}>
                        <div>
                            <div style={{width: 131}}>
                                <div style={{display: "flex", marginLeft: 3, textAlign: "right"}}>
                                    <div style={{textAlign: "right", width: "100%", marginRight: 38}}>
                                        <strong>Person</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    )
};