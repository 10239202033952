import React, { useEffect, useState } from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import Flex from 'ibis-design-system/lib/components/core/Flex';
import {IFoundation} from "../../../Interfaces/IFoundation";
import { IContextualMenuProps } from "office-ui-fabric-react/lib/components/ContextualMenu";
import { IButtonStyles, IconButton } from "office-ui-fabric-react";
import NumberInput from "ibis-design-system/lib/components/core/TextField";
import {isNumber} from "../../../Utils/isNumber";
import { IProject, ProjectBudgetTotal } from "../../../Api/Dtos/IProjectDto";
import Settings from "../../../Settings";
import {HttpPut} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';

const Card = styled.div`
    border-top: ${(props: IFoundation) => `1px solid ${props.theme.colors.neutralQuaternaryAlt}`};
    padding: 0 10px 0 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;
const Percentage = styled.div`
  color: #00000054;
  font-size: ${(props: IFoundation) => props.theme.fontSizes.xxs};
`
const Text = styled.div``;

const customSplitButtonStyles: IButtonStyles = {
    icon: {
        color: "black"
    }
};
const MenuDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
interface IParticipantCardProps extends IBaseProps {
    project: IProject;
    setProject: Function;
    label: string;
    currency: string;
    currencyAmount?: string;
    personAmount: number;
    currencyPercentage?: number;
    menuProps?: IContextualMenuProps;
    IBudgetTotal?: ProjectBudgetTotal;
    updatePartyTotals?: (newTotals: ProjectBudgetTotal) => void;
    conversionData: any;
    selectedCurrencyLabel:any;
}


export const TotalCard: React.FunctionComponent<IParticipantCardProps> = (props) => {
    const [budgetTotal, setBudgetTotal] = useState<ProjectBudgetTotal>();
    const [editing, setEditing] = useState(false);

    useEffect(() => {
      async function fetchData() {
        try {
            const body = {
              id: budgetTotal!.id,
              valueEUR: budgetTotal!.valueEUR,
              persons: budgetTotal!.persons,
            };
            const response = await HttpPut(`${Settings.apiUrls.projects}/projects/BudgetTotals/${props.project.id}`, body);
          } catch (error) {
            console.error(error);
          }
      }
      
      if (budgetTotal) {
        fetchData();
      }
    }, [budgetTotal]);
    
    const handleChange = (value: string) => {
      if (!isNumber(value)) return;
        setBudgetTotal({ ...budgetTotal!, valueEUR: parseFloat(value)});     
        props.setProject({...props.project, projectBudgetTotal: {...props.project.projectBudgetTotal, valueEUR: parseFloat(value)} }); 
    }  

    const handleChangePersons = (persons: string) => {
      if (isNumber(persons)) {
          setBudgetTotal({ ...budgetTotal!, persons: parseFloat(persons)});
          props.setProject({...props.project, projectBudgetTotal: {...props.project.projectBudgetTotal, persons: parseFloat(persons)} }); 
      }  
    };

      const menuProps: IContextualMenuProps = {
        items: [
          {
            key: 'edit',
            text: 'Edit',
            onClick: () => {
              setEditing(true);
              setBudgetTotal(props.project.projectBudgetTotal);
            }
          },
          {
          key: 'done',
          text: 'Done',
          onClick: () => {
            setEditing(false);
          }
        }
        ],
      };
      const exchangeRate = props.conversionData ? props.conversionData.inverseExchangeRate : null;
      const conversionTotal = exchangeRate === null ? 0 : exchangeRate;
      const totalAmount = props.project?.projectBudgetTotal?.valueEUR * conversionTotal;
    return (
        <Card style={{position: "relative", ...props.style}} className={props.className}>
            <Flex>
                <Flex style={{flex: 2, alignItems: "center"}}>
                    <Label>{props.label}</Label>
                </Flex>
                <Flex style={{flex: 2, alignSelf: "flex-end", justifyContent: "flex-end", alignItems: "center", height: 32}}>
                    <Flex style={{flex: 1}}>
                        <Flex style={{flex: 1, alignItems: "center"}}>
                            <div>
                                <div style={{width: editing ? 151 : 131}}>
                                        <div style={{display: "flex", marginLeft: 3, alignItems: 'center', gap: editing ? 20 : 0}}>
                                            <Text>{props.currency}</Text>
                                            {editing ?
                                        <NumberInput
                                            placeholder="amount"
                                            value={budgetTotal ? budgetTotal.valueEUR.toString() : ""}
                                            onChange={(value: any) => handleChange(value!)}
                                        />
                                        :
                                        <Text style={{marginLeft: 27}}>{props.currencyAmount}</Text>
                                        }
                                        </div>
                                </div>
                            </div>
                            { props.currencyPercentage !== undefined && <Percentage style={{marginLeft: editing ? 'calc(10% - 20px)' :'10%'}}>{props.currencyPercentage.toFixed(2)} %</Percentage> }
                        </Flex>
                    </Flex>
                    {String(props.selectedCurrencyLabel).valueOf() !== "EUR" && String(props.selectedCurrencyLabel).valueOf() !== '-' && props.selectedCurrencyLabel !== undefined ?
                    <Flex style={{flex: 1}}>
                        <Flex style={{flex: 1, alignItems: "center"}}>
                            <div>
                                <div style={{width: 131}}>
                                        <div style={{display: "flex", marginLeft: 3}}>
                                            <Text>{props.selectedCurrencyLabel}</Text>

                                        <Text style={{marginLeft: 27}}>{totalAmount.toFixed(2)}</Text>
                                        </div>
                                </div>
                            </div>
                        </Flex>
                    </Flex>
                    : <></>}
                    <Flex ml={3} mr={3} stye={{flex: 1}}>
                        <div>
                            <div style={{width: 131}}>
                                <div style={{display: "flex", marginLeft: 3, textAlign: "right"}}>
                                    <Text style={{
                                        textAlign: "right",
                                        width: "100%",
                                        marginRight: 38
                                    }}>{editing?
                                        <NumberInput
                                            placeholder="amount"
                                            value={budgetTotal ? budgetTotal.persons.toString() : ""}
                                            onChange={(value: any) => handleChangePersons(value!)}
                                        />
                                        :
                                        <Text style={{marginLeft: 27}}>{props.personAmount}</Text>
                                        }
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </Flex>
                <MenuDiv>
                    <IconButton
                        styles={customSplitButtonStyles}
                        iconProps={{iconName: 'More'}}
                        menuProps={menuProps}
                        menuIconProps={{style: {display: "none"}}}
                    />
                </MenuDiv>
                </Flex>
            </Flex>
        </Card>
    );
};