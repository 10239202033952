/**
 * A very simple wrapper for promises, that returns the result as a given type, in an easy to handle object checkable for errors.
 *
 * @param promise - The promise you would like to execute.
 *
 * @Example const {result, error} = await request<ITenderGeneralDto | undefined>(updateTenderGeneralData(props.tenderId, tender));
 */
export async function request<T>(promise: Promise<T>): Promise<{ result: T | null, error: any }> {
    try {
        const data = await promise;
        return {result: data, error: null}
    }
    catch (e) {
        console.log(e);
        return {result: null, error: e}
    }
}