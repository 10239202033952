import React, {useEffect, useState} from "react";
import Button from "ibis-design-system/lib/components/core/Button";
import Flex from "ibis-design-system/lib/components/Atoms/Flex";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import Box from "ibis-design-system/lib/components/Atoms/Box/Box";
import Dropdown from "ibis-design-system/lib/components/inputFields/Dropdown";
import {IBaseProps} from "../../Interfaces/IBaseProps";
import {IDeltaresProgramCreateDto} from "../../Api/Dtos/IDeltaresProgramCreateDto";
import { usePrograms} from "../../Hooks/GlobalConfigContext";
import {createProgram} from "../../Api/TenderRequests";
import {request} from "../../Utils/request";
import {IDeltaresProgramDto} from "../../Api/Dtos/IDeltaresProgramDto";
import {IOption} from "../ProjectTab/Connections/AddConnection";

const ProgramForm = styled.div`
  background: #0000000C;
  padding: 10px 10px 20px 10px;
  display: ${(props: any) => props.active ? "block" : "none"};
`

interface IAddProgramProps extends IBaseProps {
    projectId: string;
    addProgramme: (newProgram: IDeltaresProgramDto) => void;
}

export const AddProgram: React.FunctionComponent<IAddProgramProps> = (props) => {

    // Determines if the form for adding a program is displayed.
    const programs = usePrograms();
    const [active, setActive] = useState<boolean>(false);
    const [program, setProgram] = useState<IDeltaresProgramCreateDto>({
        DeltaresProgram: "",
    });

    // Placeholder - This will get the Sponsor.
    useEffect(() => {
        if (!props.projectId) { return; }
        // fetchSponsor(props.tenderId);
    }, [props.projectId]);

    // This is disabled until further requirements are gathered.
    // const fetchSponsor = async (tenderId: string): Promise<void> => {
    //     if (tenderId) { return; }
    //
    //     const {result, error} = await request(getProgramSponsor(props.tenderId));
    //
    //     if (error || !result) {
    //         console.error(`Something went wrong attempting to fetch the Program sponsor for Tender with ID ${props.tenderId}`);
    //         return;
    //     }
    //
    //     setProgram( (prevState: IDeltaresProgramCreateDto) => {
    //         const tmp = {...prevState};
    //         tmp.programSponsor = result;
    //         return tmp;
    //     });
    // }

    const selectProgram = (selected: IOption) => {
        program.DeltaresProgram = selected.key;
    }

    const createNewProgram = async (projectId: string, program: IDeltaresProgramCreateDto): Promise<void> => {

        if (!projectId || !program.DeltaresProgram) {
            console.warn("Missing form data, will not attempt to create the new Program.")
            return;
        }

        const {result ,error} = await request<IDeltaresProgramDto | undefined>(createProgram(projectId, program));

        if (!result || error) {
            console.error(`Something went wrong trying to create a new program for Tender with ID: ${projectId}`);
            return;
        }

        props.addProgramme(result);

        setActive(false);
    }

    const cancel = (): void => {

        setProgram( prevState => {
            const tmp = {...prevState};
            tmp.DeltaresProgram = "";
            return tmp;
        });

        setActive(false);
    }

    return (
        <div style={props.style} className={props.className}>
            <Flex mt={3} paddingBottom="20px" style={{ display: active ? "none" : "flex", marginTop: -8}}>
                <Button variant="commandBar" icon="Add" color={"#66666"} onClick={() => setActive(true)}>
                    Add Deltares program
                </Button>
            </Flex>

            <ProgramForm active={active}>
                <Flex>
                    <Box flex={3}>
                        <Dropdown
                            label="Deltares Program"
                            placeholder="Select a Deltares program"
                            value={program.DeltaresProgram}
                            onChanged={(selected: IOption) => selectProgram(selected)}
                            multiSelect={false}
                            options={programs}
                            selected={program.DeltaresProgram}
                        />
                    </Box>

                    <Box flex={2} style={{marginLeft: 30}}>
                        {/*<SemiBold>Program Sponsor</SemiBold>*/}
                        {/*<Persona*/}
                        {/*    style={{paddingTop: 8}}*/}
                        {/*    text="Xander"*/}
                        {/*    secondaryText="Consultant"*/}
                        {/*    imageInitials="XD"*/}
                        {/*    variant="deltares"*/}
                        {/*    size={PersonaSize.size48}*/}
                        {/*/>*/}
                    </Box>
                </Flex>

                <Flex mt={2}>
                    <Button variant="primary" onClick={() => createNewProgram(props.projectId, program)}>Add program</Button>
                    <span style={{marginLeft: 8}}><Button onClick={() => cancel()}>Cancel</Button></span>
                </Flex>
            </ProgramForm>
        </div>
    );
};
