import React from "react";
import {IBaseProps} from "../Interfaces/IBaseProps";
import {InformationBlock} from "./InformationBlock";
import WYSIWYGEditor from "ibis-design-system/lib/components/inputFields/WYSIWYGEditor";
import {WYSIWYG_dataConversion} from "../Utils/WYSIWYG_dataConversion";

export interface IAssignmentDescriptionBlockProps extends IBaseProps{
    description?: string;
    contentStyle?: React.CSSProperties;
    contentClassName?: string;
    header: string;
}

export const DescriptionBlock: React.FunctionComponent<IAssignmentDescriptionBlockProps> = (props) => {
    return (
        <InformationBlock className={props.className} style={props.style} header={props.header}>
            <div style={props.contentStyle} className={props.contentClassName}>
                <WYSIWYGEditor
                    content={WYSIWYG_dataConversion(props.description) || null}
                    readOnly={true}
                />
            </div>
        </InformationBlock>
    );
};