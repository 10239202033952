import { IProject } from "../Api/Dtos/IProjectDto";
import { PatchParams } from "../Interfaces/PatchParams";
import { EOP } from "../Interfaces/IPatchParams";
import { useState } from "react";
import {Toggle} from "office-ui-fabric-react";

interface Props {
    project: IProject;
    patch: PatchParams;
}

export const IncludeInDPPToggle: React.FunctionComponent<Props> = (props) => {
    const [selected, setSelected] = useState(props.project.includeInDpp);

    return (
        <div style={{marginBottom: '30px'}}>
            <Toggle label="Include in Deltares Profile Projects" inlineLabel checked={props.project.includeInDpp} onChange={(ev, value) => {
                setSelected(!selected);
                props.patch([
                    {
                        op: EOP.Replace,
                        value,
                        path: '/linkedProject/includeInDpp'
                    }
                ], props.project.linkedTender)
            }} />
            </div>
    );
};