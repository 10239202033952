import React, { FC } from 'react';
import Dialog from 'ibis-design-system/lib/components/core/Dialog';
import Button from 'ibis-design-system/lib/components/core/Button';

interface TenderProps {
    isOpen: boolean,
    handleClose: Function,
}

export const AddNewTenderDialog:FC<TenderProps> = ({ isOpen, handleClose }) => {
    return (
        <Dialog isOpen={isOpen} handleClose={handleClose}>
            <div style={{display: 'flex', flexDirection: 'column',gap: '40px'}}>
                <div style={{fontWeight: 700, fontSize: '20px'}}>A new tender has been created</div>
                <div style={{display: 'flex', justifyContent: 'center'}}><Button onClick={handleClose} variant="primary">OK</Button></div>
            </div>
        </Dialog>
    )
}