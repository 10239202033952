import {useNavigationState} from "ibis-design-system/lib/Providers/NavigationProvider";
import { ITenderGeneralDto } from "../Api/Dtos/ITenderGeneralDto";
import { ITenderOverviewDto } from "../Api/Dtos/ITenderOverviewDto";

export interface ISelectedEntity {
    // Update first selected entity. Item in callback is currently selected entity.
    update: (item: any) => any;
    getFirst: () => any;
    isProject: boolean;
    getSelection: () => ITenderGeneralDto[] | undefined;
}

/**
 * A React hook used for managing and updating selected entities.
 */
export const selectedEntity = (): ISelectedEntity => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { selection, entities, updateSelectedItem } = useNavigationState();

    /**
     * Update first selected entity.
     * @param callBack - A callback which updates the selected entity.
     */
    const update = (callBack: (item: any) => any): any => {
        let item = getFirst();

        if (!item) return;

        let newItem = callBack({...item});
        updateSelectedItem(newItem);
    }

    /**
     * Gets the first selected entity.
     */
    const getFirst = () => {
        if (!selection || !selection.length) return undefined;
        if(selection === 0){return ''};
        if(selection === null){return ''};
        if (!entities){return ''};
        return { ...entities[selection[0]] };
    }
    const getSelection = (): ITenderGeneralDto[] | undefined => {
        if (!entities || !selection || !selection.length) return undefined;
        const tmpEntities = {...entities};
        let selectedEntities = [];
        for (let i = 0; i < selection.length; i++) {
            selectedEntities.push(tmpEntities[selection[i]]);
        }
        return selectedEntities;
    }
    const isProject = getFirst()?.linkedTender !== undefined;



    return {update, getFirst, isProject, getSelection};
}