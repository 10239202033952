import React, { FC } from "react";
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Flex from 'ibis-design-system/lib/components/core/Flex';
import { ReadOnlyInput } from "./ReadOnlyInput";

export const MaconomyTurnoverTab:FC<{data: any}> = ({data}) => {
    const convertToDate = (fakeDate: string) => {
        let dateParts = fakeDate.split(".");
        let year = parseInt(dateParts[0]);
        let month = parseInt(dateParts[1]) - 1;
        let day = parseInt(dateParts[2]);
        let date = new Date(year, month, day);
        return date;
    }

    return (
        <Flex>
            <Box p={3} style={{display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', width: '100%'}}>
                {data && (
                    <>
                        {data.projectNumber && <ReadOnlyInput label="Project number" text={data.projectNumber} />}
                        {data.projectTitle && <ReadOnlyInput label="Project title" text={data.projectTitle} />}
                        {data.projectmanagerUserid && <ReadOnlyInput label="Project manager" text={data.projectmanagerUserid} />}
                        {data.responsibleUnit && <ReadOnlyInput label="Responsible unit" text={data.responsibleUnit} />}
                        {data.responsibleDepartment && <ReadOnlyInput label="Responsible department" text={data.responsibleDepartment} />}
                        {data.projectRegion && <ReadOnlyInput label="Country" text={data.projectRegion} />}
                        {data.projectRegionMarketTeam && <ReadOnlyInput label="Region" text={data.projectRegionMarketTeam} />}
                        {data.customerNo && <ReadOnlyInput label="Customer number" text={data.customerNo} />}
                        {data.customerName && <ReadOnlyInput label="Customer name" text={data.customerName} />}
                        {data.customerAddress && <ReadOnlyInput label="Customer address" text={data.customerAddress} />}
                        {data.customerPostalcode && <ReadOnlyInput label="Customer postalcode" text={data.customerPostalcode} />}
                        {data.customerCity && <ReadOnlyInput label="Customer city" text={data.customerCity} />}
                        {data.customerCountry && <ReadOnlyInput label="Customer country" text={data.customerCountry} />}
                        {data.mission && <ReadOnlyInput label="Mission" text={data.mission} />}
                        {data.programme && <ReadOnlyInput label="Programme" text={data.programme} />}
                        {data.financier && <ReadOnlyInput label="Financier" text={data.financier} />}
                        {data.creationDate && <ReadOnlyInput label="Creation date" text={convertToDate(data.creationDate).toLocaleDateString()} />}
                        {data.contractBudgetEur && <ReadOnlyInput label="Contract budget eur" text={data.contractBudgetEur} />}
                        {data.contractBudgetSubmittedOn && <ReadOnlyInput label="Contract budget submitted on" text={data.contractBudgetSubmittedOn} />}
                        {data.startDate && <ReadOnlyInput label="Start date" text={convertToDate(data.startDate).toLocaleDateString()} />}
                        {data.estimatedEndingDate && <ReadOnlyInput label="Estimated ending date" text={convertToDate(data.estimatedEndingDate).toLocaleDateString()} />}
                        {data.closedDate && <ReadOnlyInput label="Closed date" text={convertToDate(data.closedDate).toLocaleDateString()} />}
                        {data.closed && <ReadOnlyInput label="Closed" text={data.closed} />}
                        {data.quoteStatus && <ReadOnlyInput label="Quote status" text={data.quoteStatus} />}
                        {data.manMonths && <ReadOnlyInput label="Man months" text={data.manMonths} />}
                    </>
                )}
            </Box>
        </Flex>
    )
}