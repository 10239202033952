import React, { useMemo } from "react";
import {IBaseProps} from "../../Interfaces/IBaseProps";
import TextInput from 'ibis-design-system/lib/components/inputFields/TextInput';
import DatePicker from 'ibis-design-system/lib/components/inputFields/DatePicker';
import WYSIWYGEditor from 'ibis-design-system/lib/components/inputFields/WYSIWYGEditor';
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import {hasPermission} from "../../Utils/hasPermission";
import {EPermission} from "../../Api/Dtos/EPermission";
import {PatchParams} from "../../Interfaces/PatchParams";
import {ITenderOverviewDto} from "../../Api/Dtos/ITenderOverviewDto";
import {EOP} from "../../Interfaces/IPatchParams";
import {WYSIWYG_dataConversion} from "../../Utils/WYSIWYG_dataConversion";
import { SemiBold } from "../SemiBold";
import { PartyOverview } from "../OverviewTab/PartyOverview";
import { getFinanciersFromProject } from "../../Utils/getFinanciersFromProject";

interface ITenderGeneralProps extends IBaseProps {
    // Guid of a tender.
    tender: ITenderOverviewDto;
    permissions: EPermission[];
    companyId: string;
    patch: PatchParams;
}

export const TenderGeneral: React.FunctionComponent<ITenderGeneralProps> = (props) => {
    const project = props.tender.linkedProject;

    const financiers = useMemo(() => getFinanciersFromProject(project), [project]);
    return (
        <div style={props.style} className={props.className}>
            <Flex>
                <span style={{flex: 4}}>
                    <TextInput
                        label="Assignment title"
                        value={props.tender?.assignmentTitle}
                        onChanged={(value: string) => props.patch([
                            {
                                path: `/assignmentTitle`,
                                value,
                                op: EOP.Replace
                            }
                        ], props.tender.linkedProject.linkedTender)}
                        disabled={!hasPermission(props.permissions)}
                    />
                </span>
                <span style={{flex: 3, marginLeft: 20}}>
                    <TextInput
                        label="External reference"
                        value={props.tender?.externalReferenceCode}
                        onChanged={(value: string) => props.patch([
                            {
                                path: `/externalReferenceCode`,
                                value,
                                op: EOP.Replace
                            }
                        ], props.tender.linkedProject.linkedTender)}
                        disabled={!hasPermission(props.permissions)}
                    />
                </span>
            </Flex>

            <Flex mt={3}>
                <span>
                    <DatePicker
                        label="Opportunity found"
                        value={props.tender?.opportunityFound ? props.tender.opportunityFound : ""}
                        onSelect={(value: string) => props.patch([
                            {
                                op: EOP.Replace,
                                value,
                                path: '/opportunityFound'
                            }
                        ], props.tender.linkedProject.linkedTender)}
                        disabled={!hasPermission(props.permissions)}
                    />
                </span>
                <span style={{marginLeft: 20}}>
                    <DatePicker
                        label="Tender deadline"
                        value={props.tender?.tenderDeadLine ? props.tender?.tenderDeadLine : ""}
                        onSelect={(value: string) => props.patch([
                            {
                                op: EOP.Replace,
                                value,
                                path: '/tenderDeadLine'
                            }
                        ], props.tender.linkedProject.linkedTender)}
                        disabled={!hasPermission(props.permissions)}
                    />
                </span>
                <span style={{marginLeft: 20}}>
                    <DatePicker
                        label="Clarification deadline"
                        value={props.tender?.clarificationDeadline ? props.tender?.clarificationDeadline : ""}
                        onSelect={(value: string) => props.patch([
                            {
                                op: EOP.Replace,
                                value,
                                path: '/clarificationDeadline'
                            }
                        ], props.tender.linkedProject.linkedTender)}
                        disabled={!hasPermission(props.permissions)}
                    />
                </span>
                <span style={{marginLeft: 20}}>
                    <DatePicker
                        label="Internal submission deadline"
                        value={props.tender?.internalSubmissionDeadline ? props.tender?.internalSubmissionDeadline : ""}
                        onSelect={(value: string) => props.patch([
                            {
                                op: EOP.Replace,
                                value,
                                path: '/internalSubmissionDeadline'
                            }
                        ], props.tender.linkedProject.linkedTender)}
                        disabled={!hasPermission(props.permissions)}
                    />
                </span>
                    
            </Flex>
            <Flex>
                <Box style={{marginTop: 40}}>
                    <div style={{flex: 1}}>
                        <SemiBold>Financier</SemiBold>
                        <Box marginTop="8px" marginLeft="8px">
                            {financiers?.map(fn => <PartyOverview key={fn.companyId} companyId={fn.companyId} financier={true}/>)}
                        </Box>
                    </div>
                </Box>
            </Flex>

            <Box mt={3}>
                <WYSIWYGEditor
                    label="Objectives"
                    content={WYSIWYG_dataConversion(props.tender.objectives)}
                    onUpdate={(val: string) => props.patch([
                        {
                            op: EOP.Replace,
                            path: `/objectives`,
                            value: val,
                        },
                    ], props.tender.linkedProject.linkedTender)}
                    readOnly={!hasPermission(props.permissions)}
                />
            </Box>
        </div>
    );
};
