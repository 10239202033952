import React, {useMemo} from "react";
import {SelectableInput} from "./SelectableInput";
import {IMergeProjectDto} from "../../Api/Dtos/IMergeProjectsDto";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {SelectableTitle} from "./SelectableTitle";
import {IFoundation} from "../../Interfaces/IFoundation";
import {ListMergeView} from "./ListMergeView";
import {ProjectMergeLocations} from "./ProjectMergeLocations";
import {ProjectMergeTitle} from "./ProjectMergeTitle";
import {currencyToString} from "../../Utils/currencyToString";

const Block = styled.div`
  width: 465px;
  padding: 15px;
  background-color: ${ (props: any) => props.background === "light" ? props.theme.colors.neutralLight : props.theme.colors.neutralQuaternaryAlt };
`

const Source = styled.div`
  font-weight: ${(props: IFoundation) => props.theme.fontWeights.bold};
  margin-bottom: 20px;
`

export enum EMergeProjectSource {
    DTPDS = "dtpds",
    LOIS = "lois",
    MACONOMY = "maconomy"
}

interface IDataBlockProps {
    source: string;
    mergeProject: IMergeProjectDto;
    selectedMergeProjectValues: IMergeProjectDto;
    setSelectedValue: (key: keyof IMergeProjectDto) => void;
    background: "light" | "dark"
    longestProjectTitle: string;
}

export const DataBlock: React.FunctionComponent<IDataBlockProps> = (props) => {

    const keywords = useMemo<string[]>(() => {
        if (!props.mergeProject || !props.mergeProject.keywords) { return []; }
        return props.mergeProject.keywords.map( keyword => keyword.label);
    }, [props.mergeProject]);

    const units = useMemo<string[]>(() => {
        if (!props.mergeProject || !props.mergeProject.units) { return []; }
        return props.mergeProject.units.map( unit => unit.abbreviation + " - " + unit.name);
    }, [props.mergeProject]);

    const departments = useMemo<string[]>(() => {
        if (!props.mergeProject || !props.mergeProject.departments) { return []; }
        return props.mergeProject.departments.map( department => department.abbreviation + " - " + department.name);
    }, [props.mergeProject]);

    const programs = useMemo<string[]>(() => {
        if (!props.mergeProject || !props.mergeProject.program) { return []; }
        return props.mergeProject.program.map( program => program.label);
    }, [props.mergeProject]);

    return (
        <Block background={props.background}>
            <Source>Source: {props.source}</Source>
            <div style={{position: "relative", marginBottom: 19}}>
                <ProjectMergeTitle style={{opacity: 0}} selected>{props.longestProjectTitle}</ProjectMergeTitle>
                <SelectableTitle
                    style={{position: "absolute", top: 0}}
                    value={props.mergeProject.projectTitle}
                    selected={props.mergeProject.projectTitle === props.selectedMergeProjectValues.projectTitle}
                    onChange={() => props.setSelectedValue("projectTitle")}
                />
            </div>
            <SelectableInput
                label="Start date"
                value={props.mergeProject.startDate}
                selected={props.mergeProject.startDate === props.selectedMergeProjectValues.startDate}
                onChange={() => props.setSelectedValue("startDate")}
            />
            <SelectableInput
                label="End date"
                value={props.mergeProject.endDate}
                selected={props.mergeProject.endDate === props.selectedMergeProjectValues.endDate}
                onChange={() => props.setSelectedValue("endDate")}
            />
            <SelectableInput
                label="Deadline"
                value={props.mergeProject.projectDeadline}
                selected={props.mergeProject.projectDeadline === props.selectedMergeProjectValues.projectDeadline}
                onChange={() => props.setSelectedValue("projectDeadline")}
            />
            <SelectableInput
                label="Currency"
                value={currencyToString(props.mergeProject.currency)}
                selected={props.mergeProject.currency === props.selectedMergeProjectValues.currency}
                onChange={() => props.setSelectedValue("currency")}
            />
            <SelectableInput
                label="Client"
                value={props.mergeProject.client?.name}
                selected={props.mergeProject.client !== null && props.mergeProject.client?.name === props.selectedMergeProjectValues.client?.name}
                onChange={() => props.setSelectedValue("client")}
            />

            <ListMergeView label="Keywords" values={keywords} />
            <ListMergeView label="Units" values={units} />
            <ListMergeView label="Departments" values={departments} />
            <ListMergeView label="Programs" values={programs} />

            <ProjectMergeLocations locations={props.mergeProject.location} />
        </Block>
    );
};

// project_title - Done
// client - Done
// start_date - Done
// project_deadline - Done
// end_date - Done
// sdg
// keywords - Done
// locations - Done
// units - Done
// departments - Done
// currency - Done
// programs - Done
