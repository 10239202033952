import React, {useEffect, useState} from "react";
import {doesMaconomyIdExist} from "../Api/TenderRequests";
import TextInput from 'ibis-design-system/lib/components/inputFields/TextInput';
import { CancelToken } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import WatchAbleComponent from 'ibis-design-system/lib/components/watchAbleComponents/WatchAbleComponent';
import Settings from "../Settings";
import { MultipleMaconomyNumberInput } from "./MultipleMaconomyInput";
import { IProject } from "../Api/Dtos/IProjectDto";

interface IMaconomyNumberInputProps {
    maconomyNumber: string;
    onChange: (maconomyNumber: string) => void;
    disabled?: boolean;
    project: IProject;
}

export const MaconomyNumberInput: React.FunctionComponent<IMaconomyNumberInputProps> = (props) => {

    const [maconomyIdExists, setMaconomyIdExists] = useState<boolean>(true);

    useEffect(() => {
        const source = CancelToken.source();
        setMaconomyIdExists(true);
        if (!props.maconomyNumber) {
            setMaconomyIdExists(false);
            return;
        }
        if (props.maconomyNumber.length < 6) {
            setMaconomyIdExists(false);
        } else {
            doesMaconomyIdExist(props.maconomyNumber, source.token)
                .then(response => {
                    setMaconomyIdExists(response.exists);
                })
                .catch(error => {
                    if (error.code === 499) return;
                    console.log(error);
                });
        }

        return () => {
            source.cancel();
        };
    }, [props.maconomyNumber]);

    return (
        <WatchAbleComponent id="finance-maconomyId" applicationId={Settings.appId} style={{zIndex: '1'}}>
            <MultipleMaconomyNumberInput project={props.project} />
        </WatchAbleComponent>
    );
};