import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Header from 'ibis-design-system/lib/components/core/Header/Header';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Button from 'ibis-design-system/lib/components/core/Button';
import Flex from 'ibis-design-system/lib/components/core/Flex';
import MessageBar from 'ibis-design-system/lib/components/core/MessageBar';
import Layout from 'ibis-design-system/lib/components/templates/Layout';
import Menu from 'ibis-design-system/lib/components/core/Menu';
import CollectionHeader from 'ibis-design-system/lib/components/core/CollectionHeader';
import {useUserState, useUserPermissions} from 'ibis-design-system/lib/Providers/LoginProvider';
import Settings from './Settings';
import Search from './Search';
import {getMenuItems} from "./Menu";
import {ApplicationsContext} from 'ibis-design-system/lib/Providers/ApplicationsProvider';
import {useNavigationState} from 'ibis-design-system/lib/Providers/NavigationProvider';
import {getMenuItemById} from 'ibis-design-system/lib/components/core/Menu';
import DetailViewContainer from 'ibis-design-system/lib/Containers/DetailViewContainer';
import {IMenuItem} from "./Interfaces/IMenuItem";
import {IApplication} from "./Interfaces/IApplication";
import {
    getCollectionTabs,
    getStateIcon,
    mapEntityTypeToCollectionType, PRIVATE_PERMISSION_MODE,
    PUBLIC_PERMISSION_MODE,
    SHARED_PERMISSION_MODE
} from "./Constants";
import CollectionContainer from 'ibis-design-system/lib/Containers/CollectionContainer';
import {getRequests} from "./Misc";
import {ProjectMenuCard, TenderMenuCard} from "./Components/TenderMenuCard";
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import ToolbarDesignSystem from 'ibis-design-system/lib/components/Molecules/Toolbar';
import { UserContext } from 'ibis-design-system/lib/Providers/LoginProvider';
import { NavigationContext } from 'ibis-design-system/lib/Providers/NavigationProvider';
import Detailview from 'ibis-design-system/lib/components/core/Detailview';
import useWatchers from 'ibis-design-system/lib/Hooks/useWatchers';
import WatcherProvider from 'ibis-design-system/lib/Providers/WatcherProvider';
import {getDeltaresUserDetails} from "./Api/ProjectRequests";
import {GetDetailTabs} from "./Components/GetDetailTabs";
import {createActionArray} from "./Utils/CreateActionArray";
import {TenderTitle} from "./Components/TenderTitle";
import {startFollowing} from "./Utils/startFollowing";
import {archiveProject, archiveTender, createNewTender, createNewTenderWithoutLois, UnArchiveProject, UnArchiveTender, updatePermissionMode} from "./Api/TenderRequests";
import {request} from "./Utils/request";
import {ITenderOverviewDto} from "./Api/Dtos/ITenderOverviewDto";
import {IProject} from "./Api/Dtos/IProjectDto";
import {selectedEntity} from "./Utils/selectedEntity";
import {IUserContextDto} from "./Api/Dtos/IScanSourceDto";
import { ExportMenu } from './Components/ExportMenu/ExportMenu';
import { ExportJson } from './Components/ExportMenu/ExportMenuJson';
import {downloadJson} from "../src/Api/ProjectRequests";
import { isProject } from './Utils/isProject';
import { ArchiveDialog } from './Components/ExportMenu/ArchiveDialog';
import { OpenUnarchiveDialog } from './Components/ExportMenu/UnArchiveDialog';
import { AddNewTenderDialog } from './Components/AddNewTenderDialog';
import { AddNewProjectDialog } from './Components/AddNewProjectDialog';
import { UpdateExportList } from './Utils/ExportList';



const DetailViewBlock = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

function App() {

    const [filters, setFilters] = useState([]);
    const [panelContent, setPanelContent] = useState(null);
    const [collectionTitle, setCollectionTitle] = useState('');
    const [overflowActive, setOverflowActive] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const { update } = selectedEntity();

    // Determines if the app uses a project view.
    const projects: boolean = useMemo(() => Settings.purpose === "projects", []);
    const documentTitle: string = useMemo(() => projects ? 'Projects' : 'Tenders', [projects]);

    const {
        collectionId,
        navigateToCollection,
        navigateToCollectionNewEntity,
        setDocumentTitleApplication,
        setDocumentTitleCollection,
        collectionTab,
        selection,
        entities,
        updateEntities,
        setEntities,
        setSelection,
        navigateToEntity,
        navigateToErrorPage,
        entityId,
    } = useNavigationState();
    const user = useUserState();
    const permissions = useUserPermissions();
    const [exportMenuIsOpen, setExportMenuIsOpen] = useState<boolean>(false);
    const selected = selectedEntity();
    const selectedEntities = selected.getSelection();
    const requests = useMemo(() => getRequests(user.id, projects), [user.id, projects]);
    const ref = useRef(null);
    const watchers = useWatchers(Settings.appId, getDeltaresUserDetails);
    const [isArchiveDialogOpen, setArchiveDialogOpen] = useState(false);
    const [isUnArchiveDialogOpen, setUnArchiveDialogOpen] = useState(false);
    const openArchiveDialog = () => setArchiveDialogOpen(true);
    const closeArchiveDialog = () => setArchiveDialogOpen(false);
    const openUnArchiveDialog = () => setUnArchiveDialogOpen(true);
    const closeUnArchiveDialog = () => setUnArchiveDialogOpen(false);

    const isFiltered = filters.length > 0;

    const selectAllEntities = () => {
        if (selection.length < entities.length){
            const allSelectedArray = [];
            for (let i = 0; i < entities.length; i++) {
                allSelectedArray.push(i);
            }
            setSelection(allSelectedArray);
        }

        if (selection.length === entities.length){
            setSelection([])
        }
    };

    useEffect(() => {
        const menuItem = getMenuItemById(getMenuItems('', projects, permissions), collectionId);
        if (menuItem) {
            setDocumentTitleCollection(menuItem.name);
            setCollectionTitle(menuItem.name);
        }
        if (setDocumentTitleApplication) setDocumentTitleApplication(documentTitle);

    }, [collectionId, documentTitle]);
 
    const archive = useCallback(() => {
        if (!selectedEntities) return;
        const archivedEntities = selectedEntities.map( (en:any) => {
            en.isArchived = true;
             //make this pretier this is not the way
            if(!isProject()){
            en.isState !== 0  ? archiveTender(en.id)  : UnArchiveTender(en.id) 
            en.isState === 0 ? archiveTender(en.id)  : UnArchiveTender(en.id)
            }
            else{
            en.isState !== 0  ? archiveProject(en.id)  : UnArchiveProject(en.id) 
            en.isState === 0 ? archiveProject(en.id)  : UnArchiveProject(en.id)   
            }

            return en;
            
        }     
        );
        const newEntities = entities.filter((e:ITenderOverviewDto) => !archivedEntities.filter( (a:any) => a.id === e.id).length)    
        setSelection([])
        setEntities(newEntities)
    }, [updateEntities, selected]);

    const updateExportList = () => {
        UpdateExportList(selectedEntities, updateEntities);
        const selectedExportEntities = entities?.filter((en: any) => en?.userContext?.isSelected === true);
        setSelection([]);
        setEntities(selectedExportEntities);
    }

    const actions = useMemo(() => {
        return createActionArray(
            selection,
            entities,
            () => startFollowing(selection, entities, updateEntities, projects),
            () => updateExportList(),
            () => archive(),
            setExportMenuIsOpen,
            downloadJson,
            openArchiveDialog,
            openUnArchiveDialog,
            selectAllEntities,
            isFiltered,
        )
    }, [entities, selection, projects]);
    
    const addNewTender = async (): Promise<void> => {
        const { result, error } = await request(createNewTender());
        if (error || !result) {
            console.log("Something went wrong attempting to create a new tender.");
            return;
        }

        if (projects) {
            result.id = result.linkedProject.id;
            result.linkedTender = result.linkedProject.linkedTender;
        }
        navigateToCollectionNewEntity(projects ? 'draft-projects' : 'active-tenders', 'activity');
        setEntities((x: any) => (x ? [result, ...x] : [result]));
        setSelection([0]);
        navigateToEntity(result.id, projects ? 'project' : 'tender', 'overview');
    }

    const addNewTenderWithOutLOIS = async (): Promise<void> => {
        const { result, error } = await request(createNewTenderWithoutLois());
        if (error || !result) {
            console.log("Something went wrong attempting to create a new tender.");
            return;
        }

        if (projects) {
            result.id = result.linkedProject.id;
            result.linkedTender = result.linkedProject.linkedTender;
        }
        navigateToCollectionNewEntity(projects ? 'draft-projects' : 'active-tenders', 'activity');
        setEntities((x: any) => (x ? [result, ...x] : [result]));
        setSelection([0]);
        navigateToEntity(result.id, projects ? 'project' : 'tender', 'overview');
    }

    function onUpdatePermissionMode(state: string, id: string, oldPermissionMode: string) {
        
        updateEntities([
            {
                id: isProject() ? entities[selection].linkedTender : id,
                permissionMode: state,
            },
        ]);
        updatePermissionMode(state, id).catch((error: any) => {
            updateEntities([
                {
                    id: isProject() ? entities[selection].linkedTender : id,
                    permissionMode: oldPermissionMode,
                },
            ]);
            navigateToErrorPage({ code: error.code, navigateBack: false });
        });
    }

    /**
     * This function makes sure that when we use this application as a project application, the id used to fetch the details object
     * is that of a Tender.
     */
    const getEntityId = (): string | undefined => {
        if (projects) {
            if (entities && entities.length && selection && selection.length && entities[selection[0]] && entities[selection[0]].linkedTender) {
                return entities[selection[0]].linkedTender;
            }
            return undefined;
        }

        return undefined;
    }
    /**
     * This function makes sure that when we use this application as a project application, the id of the main object is set to that
     * of a project, even if what has been fetched is a Tender.
     * @param response
     */
    const customSet = (response: any) => {
        if (!response) return;
        if (!entities) {
            if (projects) {
                response.linkedTender = response.id;
                response.id = entityId;
                setEntities([response]);
                setSelection([0]);
                return;
            }
            setEntities([response]);
            return;
        }
        update((item: any) => {
            response.id = item.id;
            response.isFollowing = item.isFollowing;
            response.userContext.isSelected = item.userContext.isSelected
            response.state = item.state;
            response.projectBudgetsDeltares = item.projectBudgetsDeltares;
            response.projectBudgetTotal = item.projectBudgetTotal;
            return response;
        });
    }

    const onClickAddNewTender = () => {
        addNewTender();
        setShowDialog(true);
    }

    return (
        <Box>
            <Layout>
                <Layout.Header>
                    <ApplicationsContext.Consumer>
                        {(applications: IApplication[]) => (
                            <Header
                                user={user}
                                singleSignOutUrl={Settings.uiUrls.singleSignOn + '/signout'}
                                applicationName={Settings.title}
                                portalUrl={Settings.uiUrls.portal}
                                notificationsApiUrl={Settings.apiUrls.portal}
                                applications={applications}
                                searchComponent={<Search setFilters={setFilters}/>}
                                onToolsButtonClick={setPanelContent}
                            />
                        )}
                    </ApplicationsContext.Consumer>
                </Layout.Header>
                <Layout.PrimaryToolbarButton>
                    { !isProject() &&<ArchiveDialog isOpen={isArchiveDialogOpen} handleClose={closeArchiveDialog} onArchive={archive}  />}
                    { !isProject() &&<OpenUnarchiveDialog isOpen={isUnArchiveDialogOpen} handleClose={closeUnArchiveDialog} onUnArchive={archive}/>} 
                    <ExportMenu isOpen={exportMenuIsOpen} exportOptionsUrl={"test"} onClose={() => setExportMenuIsOpen(false)} />
                    <ExportJson  exportOptionsUrl={entities}  />
                    {isProject() 
                    ? <Button icon="add" variant="primary" onClick={() => setShowDialog(true)}>Add new</Button> 
                    : <Button icon="add" variant="primary" onClick={onClickAddNewTender}>Add new</Button>
                    }
                    {isProject() 
                    ? <AddNewProjectDialog isOpen={showDialog} onClickWithLois={addNewTender} onClickWithoutLois={addNewTenderWithOutLOIS} handleClose={() => setShowDialog(false)}/>
                    : <AddNewTenderDialog isOpen={showDialog} handleClose={() => setShowDialog(false)}/>
                    }
                </Layout.PrimaryToolbarButton>
                <Layout.Toolbar>
                    <ToolbarDesignSystem items={actions} />
                </Layout.Toolbar>
                <Layout.Menu>
                    <Menu
                        id="tenderMenu"
                        items={getMenuItems(user.name, projects, permissions)}
                        selected={collectionId}
                        onLinkClick={(item: IMenuItem) => navigateToCollection(item.id)}
                    />
                </Layout.Menu>
                {/* this collectionId check fixes the deselecting a single selected project/tender to cause a white screen */}
                { collectionId ?
                    <Layout.Collection>
                        {/* The sorting tabs */}
                        <CollectionHeader
                            title={collectionTitle}
                            tabs={getCollectionTabs()}
                            selectedTab={collectionTab}
                            onTabSelected={(tab: string) => {
                                navigateToCollection(collectionId, tab);
                            }}
                        >
                            {/* The list with Tender cards on the left side of the screen. */}
                            <CollectionContainer
                                key={collectionId}
                                collectionId={collectionId}
                                requests={requests}
                                searchFilters={filters}
                                onRenderItem={(item: ITenderOverviewDto, isSelected: () => void, onToggleItem: Function, onRowClick: any) => {
                                    return (
                                        projects ?
                                            <ProjectMenuCard item={item as any as IProject} onToggleItem={onToggleItem} isSelected={isSelected} onRowClick={onRowClick} isHeader={item.departments} />
                                        :
                                            <TenderMenuCard item={item} onToggleItem={onToggleItem} isSelected={isSelected} onRowClick={onRowClick} isHeader={item.departments} />
                                        )
                                }}
                            />
                        </CollectionHeader>
                    </Layout.Collection>
                : null}
                <Layout.Detailview>
                    <DetailViewContainer
                        backendUrl={`${Settings.apiUrls.tenders}`}
                        getItemType={mapEntityTypeToCollectionType}
                        actions={actions}
                        customType={projects ? "tenders" : undefined}
                        customEntity={getEntityId()}
                        customStateSet={projects ? customSet : undefined}
                        renderDetailView={(item: any, type: any, patch: any, setMessage: any, userMessages: any) => {
                            const tenderTitle = <TenderTitle item={item} />;
                            return (
                                <Flex height="100%" width="100%" flexDirection="column">
                                    {userMessages.map((message: any) => (
                                        <MessageBar
                                            key={message.id}
                                            type={message.type}
                                            onDismiss={message.onDismiss}
                                        >
                                            {message.text}
                                        </MessageBar>
                                    ))}
                                    <DetailViewBlock>
                                        <WatcherProvider watchers={watchers}>
                                            <UserContext.Consumer>
                                                {(u: IUserContextDto) => (
                                                    <NavigationContext.Consumer>
                                                        {({ entityTab, navigateToEntity, selection }: any) => (
                                                            <WatcherProvider watchers={watchers}>
                                                                <Detailview
                                                                    forwardedRef={ref}
                                                                    ipadPortrait={overflowActive}
                                                                    title={{
                                                                        gradient: 'none',
                                                                        showCoin: false,
                                                                        text: tenderTitle,
                                                                        subText: null,
                                                                    }}
                                                                    messages={user.userMessages}
                                                                    selectedKey={entityTab}
                                                                    onTabClick={(innerItem: any) => navigateToEntity(item.id, type, innerItem.itemKey, selection[0])}
                                                                    tabs={GetDetailTabs(overflowActive, item, type, setMessage, patch, u)}
                                                                    actions={[
                                                                        {
                                                                            key: 'projectAccessSettingsButton',
                                                                            icon: getStateIcon(item.permissionMode),
                                                                            title: `Shared with: ${item.permissionMode}`,
                                                                            items: [
                                                                                {
                                                                                    key: PUBLIC_PERMISSION_MODE,
                                                                                    text: 'Public (default)',
                                                                                    onClick: () => onUpdatePermissionMode(PUBLIC_PERMISSION_MODE, isProject() ? item.linkedTender : item.id, item.permissionMode),
                                                                                    canCheck: true,
                                                                                    isChecked:
                                                                                        item.permissionMode === PUBLIC_PERMISSION_MODE,
                                                                                    iconProps: {
                                                                                        iconName: getStateIcon(PUBLIC_PERMISSION_MODE),
                                                                                    },
                                                                                },
                                                                                {
                                                                                    key: SHARED_PERMISSION_MODE,
                                                                                    text: 'Selected people',
                                                                                    onClick: () => onUpdatePermissionMode(SHARED_PERMISSION_MODE, isProject() ? item.linkedTender : item.id, item.permissionMode),
                                                                                    disabled:
                                                                                        item.userContext?.permissions?.includes(
                                                                                            'set-permissions-mode'
                                                                                        ) === false,
                                                                                    canCheck: true,
                                                                                    isChecked:
                                                                                        item.permissionMode === SHARED_PERMISSION_MODE,
                                                                                    iconProps: {
                                                                                        iconName: getStateIcon(SHARED_PERMISSION_MODE),
                                                                                    },
                                                                                },
                                                                                {
                                                                                    key: PRIVATE_PERMISSION_MODE,
                                                                                    text: 'Private',
                                                                                    onClick: () => onUpdatePermissionMode(PRIVATE_PERMISSION_MODE, isProject() ? item.linkedTender : item.id, item.permissionMode),
                                                                                    canCheck: true,
                                                                                    disabled:
                                                                                        item.userContext?.permissions?.includes(
                                                                                            'set-permissions-mode'
                                                                                        ) === false,
                                                                                    isChecked:
                                                                                        item.permissionMode === PRIVATE_PERMISSION_MODE,
                                                                                    iconProps: {
                                                                                        iconName: getStateIcon(PRIVATE_PERMISSION_MODE),
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    ]}
                                                                />
                                                            </WatcherProvider>
                                                        )}
                                                    </NavigationContext.Consumer>
                                            )}
                                            </UserContext.Consumer>
                                        </WatcherProvider>
                                    </DetailViewBlock>
                                </Flex>
                            );
                        }}
                    />
                </Layout.Detailview>
                {panelContent ? <Layout.PanelContent>{panelContent}</Layout.PanelContent> : null}
            </Layout>
        </Box>
    );
}
export default App ;
