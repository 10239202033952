import React, { useState, useEffect } from 'react';

import LoadScreen from 'ibis-design-system/lib/components/core/LoadScreen';
import { useNavigationState } from 'ibis-design-system/lib/Providers/NavigationProvider';

import LatestActivitiesList from './ActivitiesList';
import { CancelToken, getLatestActivities } from '../../../Api/CompanyRequests';

function LatestActivitiesContainer(props) {
    const { companyId } = props;

    const { navigateToErrorPage } = useNavigationState();

    const [activities, setActivities] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const source = CancelToken.source();

        setIsLoading(true);
        getLatestActivities(companyId, source.token, 5)
            .then((response) => {
                setActivities(response);
                setIsLoading(false);
            })
            .catch((error) => {
                switch (error.code) {
                    case 499:
                        break;
                    default:
                        setIsLoading(false);
                        navigateToErrorPage({ code: error.code, navigateBack: true });
                        break;
                }
            });

        return () => {};
    }, [companyId, navigateToErrorPage]);

    if (isLoading) {
        return <LoadScreen />;
    }

    return <LatestActivitiesList activities={activities} />;
}

export default LatestActivitiesContainer;
