import React from "react";
import Flex from "ibis-design-system/lib/components/core/Flex";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IBaseProps} from "../../Interfaces/IBaseProps";
import Dropdown from "ibis-design-system/lib/components/inputFields/Dropdown";
import Box from "ibis-design-system/lib/components/Atoms/Box/Box";
import WYSIWYGEditor from "ibis-design-system/lib/components/inputFields/WYSIWYGEditor";
import {useCountries, useLegalFrameworks} from "../../Hooks/GlobalConfigContext";
import {IOption} from "../ProjectTab/Connections/AddConnection";
import {IProject} from "../../Api/Dtos/IProjectDto";
import {EPermission} from "../../Api/Dtos/EPermission";
import {hasPermission} from "../../Utils/hasPermission";
import {PatchParams} from "../../Interfaces/PatchParams";
import {EOP} from "../../Interfaces/IPatchParams";
import {WYSIWYG_dataConversion} from "../../Utils/WYSIWYG_dataConversion";

const SmallBox = styled.div`
  width: 261px;
`

interface ITaxProps extends IBaseProps {
    tenderId: string;
    project: IProject;
    userPermissions: EPermission[];
    patch: PatchParams;
}

export const Tax: React.FunctionComponent<ITaxProps> = (props) => {

    const legalFrameworks = useLegalFrameworks();
    const countries = useCountries();

    return (
        <div style={props.style} className={props.className}>
            <Flex mt={1}>
                {/* TODO get this data from the Global Config application. */}
                <SmallBox>
                    <Dropdown
                        label="Country for taxes"
                        placeholder="Select country"
                        selected={props.project?.countryForTaxes}
                        onChanged={(val: IOption) => props.patch([
                            {
                                op: EOP.Replace,
                                path: '/linkedProject/countryForTaxes',
                                value: val.key,
                            }
                        ], props.project.linkedTender)}
                        multiSelect={false}
                        options={countries}
                        disabled={!hasPermission(props.userPermissions)}
                    />
                </SmallBox>
                <SmallBox style={{marginLeft: 30}}>
                    <Dropdown
                        label="Legal framework"
                        placeholder="Select legal framework"
                        selected={props.project?.legalFramework}
                        onChanged={(val: IOption) => props.patch([
                            {
                                op: EOP.Replace,
                                path: '/linkedProject/legalFramework',
                                value: val.key
                            }
                        ], props.project.linkedTender)}
                        multiSelect={false}
                        options={legalFrameworks}
                        disabled={!hasPermission(props.userPermissions)}
                    />
                </SmallBox>
            </Flex>

            <Box mt={3}>
                <WYSIWYGEditor
                    label="Tax instructions"
                    content={WYSIWYG_dataConversion(props.project.taxInstruction)}
                    onUpdate={(val: string) => props.patch([
                            {
                                op: EOP.Replace,
                                path: `/linkedProject/taxInstruction`,
                                value: val,
                            },
                        ], props.project.linkedTender)}
                    readOnly={!hasPermission(props.userPermissions)}
                />
            </Box>
        </div>
    );
};
