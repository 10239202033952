import { calculateDurationOfMonths } from "./calculateDurationOfMonths";

export const calculateRemainingMonths = (startDate: Date, endDate: Date, format: 'long' | 'short'): string => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  if (startDate > currentDate) {
    return calculateDurationOfMonths(startDate, endDate, format);
  } else if (currentDate > endDate) {
    return "-";
  } else {
    const remainingMonths = (endDate.getFullYear() - currentYear) * 12 + (endDate.getMonth() - currentMonth);
    if (remainingMonths === 1) {
      const remainingDays = endDate.getDate() - currentDate.getDate();
      return remainingDays >= 0 ? format === 'long' ? "1 month" : "1m" : format === 'long' ? "< 1 month" : "< 1m";
    }
    return remainingMonths > 0 ? format === 'long' ? `${remainingMonths} months` : `${remainingMonths}m` : format === 'long' ? "< 1 month" : "< 1m";
  }
};