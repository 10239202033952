import React, { useState, useEffect, FC } from "react";
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Settings from "../Settings";
import { HttpGet } from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import { IBaseProps } from "../Interfaces/IBaseProps";
import { MaconomyTurnoverTab } from "../Components/MaconomyTurnoverTab";
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import LoadScreen from 'ibis-design-system/lib/components/core/LoadScreen';


interface IFinanceGeneralProps extends IBaseProps {
    item: any;
}

export const MaconomyTurnover: FC<IFinanceGeneralProps> = (props) => {
    const [data, setData] = useState<any | null>(null);
    const [loading, setLoading] = useState(true);

    const getMaconomyData = async () => {
        try {
            const response = await HttpGet(`${Settings.apiUrls.tenders}/projects/maconomy/${props.item.linkedProject.maconomyId}`);
            setData(response);
        } catch (error) {
            console.error("Failed to fetch Maconomy data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            await getMaconomyData();
        } catch (error) {
            console.error("Error occurred while fetching Maconomy data:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box style={{ paddingBottom: '50px', width: '100%', height: '100%' }}>
            {loading ? (
                <LoadScreen />
            ) : data ? (
                <MaconomyTurnoverTab data={data} />
            ) : (
                <NoData>No maconomy data found</NoData>
            )}
        </Box>
    );
};

const NoData = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;