import React, { useEffect, useState } from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {IConnectionDto} from "../../../Api/Dtos/IConnectionDto";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IFoundation} from "../../../Interfaces/IFoundation";
import Icon from 'ibis-design-system/lib/components/core/Icon';
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import {shortenString} from "../../../Utils/shortenString";
import {IButtonStyles, IconButton, IContextualMenuProps} from "office-ui-fabric-react";
import {hasPermission} from "../../../Utils/hasPermission";
import {EPermission} from "../../../Api/Dtos/EPermission";
import {useNavigationState} from 'ibis-design-system/lib/Providers/NavigationProvider';
import { IMinimalTender } from "./TenderPicker";
import { getMinimalTender } from "../../../Api/TenderRequests";
import { request } from "../../../Utils/request";
import Settings from "../../../Settings";

const Card = styled.div`
    background-color: ${(props: IFoundation) => props.theme.colors.white};
    padding: 12px 10px;
    
    :hover {
        background-color: ${(props: IFoundation) => props.theme.colors.neutralLight};
        cursor: pointer;
    }
`;
const Code = styled.div`
    color: ${(props: IFoundation) => props.theme.colors.themePrimary};
    font-weight: ${(props: IFoundation) => props.theme.fontWeights.bold};
`;
const Title = styled.div`
    font-weight: ${(props: IFoundation) => props.theme.fontWeights.bold};
    margin-left: 5px;
`;
const Note = styled.div`
    margin-right: 17px;
`;

interface IConnectionProps extends IBaseProps {
    tenderId: string;
    connection: IConnectionDto;
    onRemove: () => void;
    permissions: EPermission[];
}

const selectIcon = (type: string): string => {
    switch(type) {
        case "Duplicates":
            return "RowsGroup";
        case "Extends":
            return "RowsChild";
        case "Risk":
            return "LightningBolt";
        default: return "StatusCircleQuestionMark";
    }
}
const getTenders = async (searchInput: string): Promise<IMinimalTender[]> => {

    if (!searchInput) { return []; }

    const {result, error} = await request<{result: IMinimalTender[]}>(getMinimalTender(searchInput, {take: 7, skip: 0}));

    if (!result || !result.result || error) {
        console.log("An error occurred attempting to fetch minimal tenders");
        return [];
    }

    return result.result;
};


export const Connection: React.FunctionComponent<IConnectionProps> = (props) => {
    const {
      entities,
    } = useNavigationState();
    const customSplitButtonStyles: IButtonStyles = {
      icon: {
        color: "black"
      }
    };
  
    const menuProps: IContextualMenuProps = {
      items: [
        {
          key: 'mergeView',
          text: 'Show in mergeview',
          onClick: () => console.log("Not yet implemented."),
        },
        {
          key: 'unlink',
          text: 'Unlink',
          onClick: () => props.onRemove(),
        }
      ],
    };
    
    const [tenderId, setTenderId] = useState<string>("");
  
    useEffect(() => {
      async function GetTenderId() {
        const tenderResult = await getTenders(props.connection.title);
        const GetTenderId = tenderResult.map((x:any) => x.id);
        setTenderId(GetTenderId[0] || "");
      }
      GetTenderId();
    }, [props.connection.title]);
    
    return (
        <Card>
          <Flex alignItems="flex-start" justifyContent="space-between">
            <Flex>
              <div style={{paddingTop: 2}}>
                <Icon name={selectIcon(props.connection.type.label)} size="xs" />
              </div>
              <Box marginLeft="8px">
                <a href={`${Settings.uiUrls.tenders}/entity/${tenderId}/tender/overview`} target="_blank" style={{"textDecoration": "none"}}>
                  <Flex>
                    <Code>{props.connection.code}</Code>
                    <Title>{props.connection.title}</Title>
                  </Flex>
                </a>
                <div>{props.connection.type.label}</div>
              </Box>
            </Flex>
    
            <Flex>
              <Note title={props.connection.note}>{shortenString(props.connection.note, 22)}</Note>
              { hasPermission(props.permissions) && <IconButton styles={customSplitButtonStyles} iconProps={{ iconName: 'More' }} menuProps={menuProps} menuIconProps={{style: {display: "none"}}} /> }
            </Flex>
          </Flex>
        </Card>
      );
};
