import React from "react";
import {Checkbox} from "office-ui-fabric-react";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {ProjectMergeTitle} from "./ProjectMergeTitle";

const Row = styled.div`
  display: flex;
  //align-items: center;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
`

interface ISelectableInputProps {
    value?: any;
    selected?: boolean;
    onChange?: () => void;
    style?: React.CSSProperties;
}

export const SelectableTitle: React.FunctionComponent<ISelectableInputProps> = (props) => {
    return (
        <Row onClick={props.onChange} style={props.style}>
            <Checkbox styles={{root: {marginTop: 10}}} checked={props.selected} />
            <ProjectMergeTitle selected={props.selected}>{props.value}</ProjectMergeTitle>
        </Row>
    );
};
