import React, {useEffect, useMemo, useState} from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IFoundation} from "../../../Interfaces/IFoundation";
import {ParticipantCard} from "./ParticipantCard";
import {TotalCard} from "./TotalCard";
import {AddParty} from "./AddParty";
import {IPartyDto} from "../../../Api/Dtos/IPartyDto";
import {deleteProjectParty, updateProjectBudgetTotal} from "../../../Api/TenderRequests";
import {request} from "../../../Utils/request";
import moment from "moment";
import {ExchangeRate} from "../ExchangeRate";
import {calculateProjectFinance} from "../../../Utils/calculateProjectFinance";
import {getFinanciersFromProject} from "../../../Utils/getFinanciersFromProject";
import {getNotFinanciersFromProject} from "../../../Utils/getNotFinanicersFromProject";
import {useCurrencies} from "../../../Hooks/GlobalConfigContext";
import {IProject, ProjectBudgetTotal} from "../../../Api/Dtos/IProjectDto";
import {hasPermission} from "../../../Utils/hasPermission";
import {EOP} from "../../../Interfaces/IPatchParams";
import {PatchParams} from "../../../Interfaces/PatchParams";
import { getTotalAssociateFromProject } from "../../../Utils/getTotalAssociate";
import { TotalCardDeltares } from "./TotalCardDeltares";
import { TotalCardAssociate } from "./TotalCardAssociate";
import { formatBudgetWithCommas } from "../../../Utils/formatBudgetWithCommas";
import { TotalHeader } from "./TotalHeader";


const BudgetForm = styled.div`
  background: ${(props: IFoundation) => props.theme.colors.white};
`;

interface IBudgetProps extends IBaseProps {
    // The prject for which you wat to manage finance data.
    project: IProject;
    userContext: any;
    patch: PatchParams;
}


/**
 * Displays budget information about a project.
 * Budget information can only be displayed if a financier has been added to a project.
 *
 * It manages not only Finance information and totals, but also the used currency of a project.
 *
 * @param props
 * @constructor
 */
export const Budget: React.FunctionComponent<IBudgetProps> = (props) => {
    const [project, setProject] = useState(props.project);
    const currencies = useCurrencies();

    const getSelectedCurrencyLabel = () => {
        if (!props.project || !props.project.currency) return "-";
        const cu = currencies.find( c => c.id === props.project.currency);
        if (cu) return cu.isO4217;
        return "-";
    };

    const selectedCurrencyLabel = getSelectedCurrencyLabel();

    const financiers: IPartyDto[] | undefined = getFinanciersFromProject(props.project);

    const notFinanciers: IPartyDto[] | undefined = getNotFinanciersFromProject(props.project);
    
    const withAssociate: IPartyDto[] | undefined = getTotalAssociateFromProject(props.project);

    const totalFinanced = useMemo(() => calculateProjectFinance(props.project, financiers), [financiers]);
    const [conversionData, setConversionData] = useState<any>(null);

    const updateConversionData = (data: any) => {
      setConversionData(data);
    };

    if (!financiers || !totalFinanced || !notFinanciers) {
        return  props.project ? <AddParty style={{marginTop: 10}} projectId={props.project.id} currency={"USD"} /> : <div>No project available</div>
    }
    
    const removeParty = async (partyId: string): Promise<void> => {
        if (!props.project) return;
        const { result, error } = await request(deleteProjectParty(props.project.id, partyId));

        if (error || !result) {
            console.log(`An Error occurred while removing a budget party: ${error}`);
            return;
        }

        triggerUpdate();
    }

    const triggerUpdate = () => {
        props.patch([
            {
                op: EOP.Replace,
                value: null,
                path: 'reload'
            }
        ], props.project.linkedTender)
    }

    const updateParty = async (party:ProjectBudgetTotal): Promise<void> => {
        if (!props.project) return;
        const { result, error } = await request(updateProjectBudgetTotal(props.project.id));

        if (error || !result) {
            console.log(`An Error occurred while updating a budget party: ${error}`);
            return;
        }

        triggerUpdate();
    }

    const onSetProject = (project: IProject) => {

        setProject(project);
        triggerUpdate();
    }
    
    const getTotal = withAssociate?.map((x:any) => x.value)
    const getTotalNumbers = getTotal?.map(num => parseInt(num, 10))
    const sum = getTotalNumbers?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    // The percentage of a financiers contribution to the total project budget. Displayed for each financier.
    const calcValPercentage = (financed: {value: number, persons: number}, party: IPartyDto): number => totalFinanced && totalFinanced.value ? Number((party.value / totalFinanced.value * 100).toFixed(2).substr(0, 3)) : 0;
    // The percentage of a financiers contribution of people to the total amount of available people to a project. Displayed for each financier.
    const calcPersonsPercentage = (financed: {value: number, persons: number}, party: IPartyDto): number => totalFinanced && totalFinanced.persons ? Number((party.persons / totalFinanced.persons * 100).toFixed(2).substr(0, 3)) : 0;


    const totalBudgetProject = project.projectBudgetTotal?.valueEUR;
    const totalBudgetDeltaresProject = project.projectBudgetsDeltares?.valueEUR ?? 0;
    const totalBudgetAssociateProject = (totalBudgetProject ?? 0) - totalBudgetDeltaresProject;
    
    const totalBudgetPersonsProject = project.projectBudgetTotal?.persons ?? 0;
    const totalBudgetDeltaresPersonsProject = project.projectBudgetsDeltares?.persons ?? 0;
    const totalBudgetAssociatePersonsProject = totalBudgetPersonsProject - (totalBudgetDeltaresPersonsProject ?? 0);

    return (
        <div style={{ paddingBottom: '20px'}}>
            <ExchangeRate
                updateConversionData={updateConversionData}
                conversionData={conversionData}
                setConversionData={setConversionData}
                currency={props.project.currency}
                updateCurrency={(currencyId: string | undefined) => props.patch([
                    {
                        op: EOP.Replace,
                        value: currencyId,
                        path: '/linkedProject/currency'
                    }
                ], props.project.linkedTender)}
                canEdit={true}
                commercialBudget={totalFinanced.value}
                currencyConversionDate={props.project.exchangeRateDate ? props.project.exchangeRateDate : moment().toISOString()}
                updateConversionDate={(date: string) => props.patch([
                    {
                        op: EOP.Replace,
                        value: date,
                        path: '/linkedProject/exchangeRateDate'
                    }
                ], props.project.linkedTender)}
            />

            <BudgetForm style={props.style} className={props.className}>
                {financiers && financiers.map( (party: IPartyDto) => {
                    return <ParticipantCard
                        key={party.id + 'financier'}
                        userContext={props.userContext}
                        companyId={party.companyId}
                        currency={selectedCurrencyLabel}
                        currencyAmount={party.value + ""}
                        currencyPercentage={calcValPercentage(totalFinanced, party)}
                        personAmount={party.persons}
                        personPercentage={calcPersonsPercentage(totalFinanced, party)}
                        role={party.role}
                        contactInfo={party.contactInfo}
                        removeParty={ () => removeParty(party.id) }
                        updateParty={ () => updateParty(props.project.projectBudgetTotal) }
                    />
                })}
                {notFinanciers && notFinanciers.map( (party: IPartyDto) => {
                    return <ParticipantCard
                        key={party.id + 'party'}
                        userContext={props.userContext}
                        style={{marginTop: 1}}
                        companyId={party.companyId}
                        currency={selectedCurrencyLabel}
                        currencyAmount={party.value + ""}
                        currencyPercentage={calcValPercentage(totalFinanced, party)}
                        personAmount={party.persons}
                        personPercentage={calcPersonsPercentage(totalFinanced, party)}
                        role={party.role}
                        contactInfo={party.contactInfo}
                        removeParty={ () => removeParty(party.id) }
                        updateParty={ () => updateParty(props.project.projectBudgetTotal) }
                    />
                })}
                {/* This TotalHeader is made exactly the same as TotalCardAssociate to take the same css */}
                <TotalHeader currency={selectedCurrencyLabel}/>
                <TotalCardAssociate
                    project={project}
                    setProject={onSetProject}
                    currency={"EUR"}
                    currencyAmount={formatBudgetWithCommas(totalBudgetAssociateProject)}
                    currencyPercentage={ totalBudgetAssociateProject /  totalBudgetProject * 100 }
                    personAmount={totalBudgetAssociatePersonsProject} label={"Part of Associate(s)"}
                    conversionData={conversionData}
                    selectedCurrencyLabel={selectedCurrencyLabel}

                />
                <TotalCardDeltares
                    project={project}
                    setProject={onSetProject}
                    currency={"EUR"}
                    currencyAmount={formatBudgetWithCommas(totalBudgetDeltaresProject)}
                    currencyPercentage={totalBudgetDeltaresProject /  totalBudgetProject * 100 }
                    personAmount={totalBudgetDeltaresPersonsProject} label={"Part of Deltares"}
                    conversionData={conversionData}
                    selectedCurrencyLabel={selectedCurrencyLabel}

                />
                <TotalCard
                    project={project}
                    setProject={onSetProject}
                    currency={"EUR"}
                    currencyAmount={formatBudgetWithCommas(totalBudgetProject)}
                    currencyPercentage={ 100 }
                    personAmount={totalBudgetPersonsProject} label={"Total"}
                    conversionData={conversionData}
                    selectedCurrencyLabel={selectedCurrencyLabel}
                />

            </BudgetForm>

            { props.project && hasPermission(props.userContext.permissions) ?
                <AddParty style={{marginTop: 10}} projectId={props.project.id} currency={selectedCurrencyLabel} />
                :
                null
            }
        </div>
    );
};