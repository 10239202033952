import React, {useMemo, useState} from "react";
import Flex from 'ibis-design-system/lib/components/core/Flex';
import Box from 'ibis-design-system/lib/components/core/Box';
import Button from "ibis-design-system/lib/components/core/Button";
import { GreyBox } from "../../GreyBox";
import {createProjectParty, getCompanies, getCompany} from "../../../Api/TenderRequests";
import CompanyPicker from "ibis-design-system/lib/components/pickers/CompanyPicker";
import {useNatures} from "../../../Hooks/GlobalConfigContext";
import Dropdown from "ibis-design-system/lib/components/inputFields/Dropdown";
import {IOption} from "../../ProjectTab/Connections/AddConnection";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import {ICreatePartyDto} from "../../../Api/Dtos/ICreatePartyDto";
import {request} from "../../../Utils/request";
import {selectedEntity} from "../../../Utils/selectedEntity";
import {getLinkedProject} from "../../../Utils/getLinkedProjects";
import {useDeltaresRole}from "../../../Hooks/GlobalConfigContext"
import TextInput from 'ibis-design-system/lib/components/inputFields/TextInput';

interface IAddPartyProps extends IBaseProps {
    projectId: string;
    // USD, EURO, etc
    currency: string;
}

interface IRole {id: any, label: any}
let addingFinancier = false;
export const AddParty: React.FunctionComponent<IAddPartyProps> = (props) => {
    const { update } = selectedEntity();
    const getDeltaresRole = useDeltaresRole();
    const DeltaresRoleName = getDeltaresRole.map((x:any) => x.label);
    const DeltaresRoleId = getDeltaresRole.map((x:any) => x.id);

    const options = useMemo<Array<IRole>>(() => {
        return DeltaresRoleId.map((id: string, index: number) => {
          return {
            id: id,
            label: DeltaresRoleName[index],
          };
        });
      }, [DeltaresRoleName, DeltaresRoleId]);
    
    const natures = useNatures();
    const [adding, setAdding] = useState<boolean>(false);
    const [selectedCompany, setSelectedCompany] = useState<any>();
    const [role, setRole] = useState<IRole>( options[0]);
    const [people, setPeople] = useState<number>(0);
    const [value, setValue] = useState<number>(0);
    const [contactInfo, setContactInfo] = useState<string>('');

    const createParty = async (): Promise<void> => {
        if (!selectedCompany || !role || !props.projectId) return;
        const newParty: ICreatePartyDto = {
            companyId: selectedCompany.key,
            persons: people,
            projectId: props.projectId,
            role: role.label,
            value: value,
            contactInfo: contactInfo
        };

        const {result, error} = await request(createProjectParty(newParty));

        if (error) {
            return;
        }

        update((item: any) => {
            if (!getLinkedProject(item)) return;
            const tmpItem = {...item};
            const linkedProject = getLinkedProject(tmpItem);
            if (!linkedProject) return tmpItem;
            if (!linkedProject.budgetParties) linkedProject.budgetParties = [];
            getLinkedProject(tmpItem).budgetParties.push(result);
            return tmpItem;
        });

        resetForm();
        setAdding(false);
    };

    const resetForm = (): void => {
        setSelectedCompany(null);
        setRole(options[0]);
        setPeople(0);
        setValue(0);
    }

    if (!adding) {
        return (
            <Flex style={props.style} className={props.className}>
                <Button variant="commandBar" icon="Add" color={"#66666"} onClick={() => {
                    addingFinancier = false;
                    setAdding(!adding);
                }}>
                    Add consortium partners
                </Button>

                <div style={{marginLeft: 15}}>
                    <Button variant="commandBar" icon="Add" color={"#66666"} onClick={() => {
                        addingFinancier = true;
                        setAdding(!adding);
                    }}>
                        Add financier
                    </Button>
                </div>
            </Flex>
        );
        
    }
    return (
        <GreyBox>
            <Flex>
                <Box style={{flex: 2}}>
                    <Box width={"95%"}>
                        <CompanyPicker
                            label={addingFinancier ? "Financier" : "Consortium partners"}
                            placeholder="Select company"
                            pickLimit={1}
                            selected={[selectedCompany?.id === undefined ? '' : selectedCompany?.id]}
                            natures={natures}
                            getCompany={getCompany}
                            getCompanies={(input: string) => getCompanies(input, null, null)}
                            onChanged={(change: any) => setSelectedCompany(change.value)}
                        />
                        <div style={{marginTop: 20}}>
                            <Dropdown
                                label="Role"
                                options={addingFinancier ? options.filter(op => op.label === "Financier") : options.filter(op => op.label !== "Financier")}
                                onChanged={(change: IOption) => setRole(options.find(opt => opt.label === change.text) as IRole)}
                                multiSelect={false}
                                selected={role?.id}
                            />
                        </div>
                        <div style={{marginTop: 20}}>
                            <TextInput 
                                placeholder="Enter contact information"
                                label='Contact information'
                                onChange={(input: string) => setContactInfo(input)}
                            /> 
                        </div>

                        <Flex mt={3} mb={1}>
                            <Button variant="primary" onClick={() => createParty()}>
                                Add
                            </Button>
                            <span style={{marginLeft: 8}}><Button onClick={() => setAdding(false)}>Cancel</Button></span>
                        </Flex>
                    </Box>
                </Box>
            </Flex>
        </GreyBox>
    );
};