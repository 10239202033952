import React from 'react';

import Box from 'ibis-design-system/lib/components/core/Box';
import Label from 'ibis-design-system/lib/components/core/Label';

import LatestActivitiesContainer from './LatestActivitiesContainer';

function LatestActivities(props) {
    return (
        <Box>
            <Box px={1} py="4px" backgroundColor="rgba(0, 0, 0, 0.05)">
                <Label>Latest activities</Label>
            </Box>
            <LatestActivitiesContainer companyId={props.id} />
        </Box>
    );
}

export default LatestActivities;
