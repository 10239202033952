import {IPartyDto} from "../Api/Dtos/IPartyDto";

export const calculateProjectFinance = (project: any, financiers?: IPartyDto[]) => {
    if (project && project.projectBudgetTotal && !project.projectBudgetTotal.autoUpdateTotals) {
        return { value: project.projectBudgetTotal.value, persons: project.projectBudgetTotal.persons }
    }
    return financiers?.reduce((prev, current) => {
        prev.persons += current.persons;
        prev.value += current.value;
        return prev;
    }, {value: 0, persons: 0})
};

export const calculateTotalNotFinanciers = (notFinanciers?: IPartyDto[]) => notFinanciers?.reduce((prev, current) => {
    prev.persons += current.persons;
    prev.value += current.value;
    return prev;
}, {value: 0, persons: 0});