import React from "react";
import {ITenderTabCard} from "../Interfaces/ITenderTabCard";

export const TenderTabCard = (name: string, component: JSX.Element, icon?: string, iconOnly?: boolean, toolTip?: string, isInOverflow?: boolean): ITenderTabCard => {
    return {
        key: name.toLowerCase(),
        name,
        component,
        icon,
        iconOnly,
        toolTip,
        isInOverflow
    }
}
