import React, {useEffect, useState} from "react";
import {IBaseProps} from "../../../Interfaces/IBaseProps";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import Flex from 'ibis-design-system/lib/components/core/Flex';
import Box from 'ibis-design-system/lib/components/core/Box';
import NumberInput from "ibis-design-system/lib/components/core/TextField";
import {IFoundation} from "../../../Interfaces/IFoundation";
import { SemiBold } from "../../SemiBold";
import {IButtonStyles, IconButton, IContextualMenuProps} from "office-ui-fabric-react";
import {getCompany} from "../../../Api/TenderRequests";
import {request} from "../../../Utils/request";
import {ICompanyDto} from "../../../Api/Dtos/ICompanyDto";
import {Percentage} from "./Percentage";
import {isNumber} from "../../../Utils/isNumber";
import {hasPermission} from "../../../Utils/hasPermission";
import Settings from "../../../Settings";
import { IProject } from "../../../Api/Dtos/IProjectDto";

const Card = styled.div`
  padding: 10px;
  border-top: ${(props: IFoundation) => `1px solid ${props.theme.colors.neutralQuaternaryAlt}`};
`;

const CompanyName = styled.div`
  text-decoration: underline;
  font-weight: bold;
`;
const CountryName = styled.div`
  font-size: ${(props: IFoundation) => props.theme.fontSizes.xxs};
`;

const Text = styled.div``;

const MenuDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  // display: none;
  //
  // ${Card}:hover & {
  //    display: inherit;
  // }
`;

interface IParticipantCardProps extends IBaseProps {
    userContext: any;
    companyId: string;
    role: string;
    contactInfo: string;
    currency: string;
    currencyAmount: string;
    project?: IProject;
    personAmount: number;
    currencyPercentage: number;
    personPercentage: number;
    onCurrencyChange?: (value: number) => void;
    onPersonChange?: (value: number)  => void;
    removeParty: () => void;
    updateParty: () => void;
}

export const ParticipantCard: React.FunctionComponent<IParticipantCardProps> = (props) => {

    const [editing, setEditing] = useState<boolean>(false);
    const [company, setCompany] = useState<ICompanyDto>();

    useEffect(() => {
        fetchCompany(props.companyId);
    }, [props.companyId]);

    const fetchCompany = async (companyId: string) => {
        const {result, error} = await request(getCompany(companyId));

        if (error) return;

        setCompany(result);
    }

    const customSplitButtonStyles: IButtonStyles = {
        icon: {
            color: "black"
        }
    };

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'remove',
                text: 'Remove',
                onClick: () => props.removeParty(),
            }
        ],
    };

    return (
        <Card style={{position: "relative", ...props.style}} className={props.className}>
            <Flex>
                <Box style={{flex: 2, flexDirection: "column"}}>
                    <a href={`${Settings.uiUrls.cci}/entity/${company?.id}/company/overview?`}><CompanyName>{company?.name}</CompanyName></a>
                    <CountryName>{company?.addresses[0]?.country}</CountryName>

                    <Text style={{marginTop: 26, width: "300px"}}><strong>Role: </strong>{props.role}</Text>
                    <Text style={{width: "100%"}}><strong>Contact information: </strong>{props.contactInfo ?? 'No information available'}</Text>
                </Box>
            </Flex>    
            { hasPermission(props.userContext.permissions) &&
                <MenuDiv>
                    <IconButton
                        styles={customSplitButtonStyles}
                        iconProps={{iconName: 'More'}}
                        menuProps={menuProps}
                        menuIconProps={{style: {display: "none"}}}
                    />
                </MenuDiv>
            }
        </Card>
    );
};
