import React, {useEffect, useState} from "react";
import Box from "ibis-design-system/lib/components/Atoms/Box/Box";
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import ProgressSpinner from 'ibis-design-system/lib/components/core/ProgressSpinner';
import Text from 'ibis-design-system/lib/components/Atoms/Text';
import {IFoundation} from "../../Interfaces/IFoundation";
import {getConversionToEuro} from "../../Api/TenderRequests";
import {CancelToken} from 'ibis-design-system/lib/HelperFunctions/HttpHelper';
import {useNavigationState} from 'ibis-design-system/lib/Providers/NavigationProvider';


const CurrencyLabel = styled.span`
    font-size: 18px;
    font-weight: ${(props: IFoundation) => props.theme.fontWeights.semiBold};
`;

function round(amount: number, decimals = 2) {
    return Math.round(amount * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

interface IConversionProps {
    date: string;
    currency: string;
    amount: number;
    updateConversionData: (data: any) => void;
    conversionData: any;
  }
export const Conversion = (props: IConversionProps) => {
    const { date, currency, amount } = props;
    const { entities } = useNavigationState();
    const [conversion, setConversion] = useState<any>(null);
    const [error, setError] = useState<any>(false);
    const [isLoading, setIsLoading] = useState<any>(false);
  
    const budgetTotal = entities.map((lp: any) => lp.linkedProject).map((pb: any) => pb?.projectBudgetTotal?.valueEUR);
    const budgetPerson = entities.map((lp: any) => lp.linkedProject).map((pb: any) => pb?.projectBudgetTotal?.persons);
    
    useEffect(() => {
        const source = CancelToken.source();
    
        setIsLoading(true);
        getConversionToEuro(currency, date, amount, source.token)
          .then((response) => {
            setError(false);
            setConversion(response);
            setIsLoading(false);
    
            // Call the updateConversionData callback with the conversion data
            props.updateConversionData(response);
          })
          .catch((error) => {
            setConversion(null);
            if (error.code === 499) {
              // Canceled
            } else {
              setError(true);
              setIsLoading(false);
            }
          });
    
        // Cleanup function to cancel the API call if the component unmounts
        return () => {
          source.cancel();
        };
      }, [date, currency, amount]);
    
      if (!conversion) return null;
      const { sourceCurrency, targetCurrency, exchangeRate, inverseExchangeRate } = conversion;
    if (isLoading)
        return (
            <Box mt="10px">
                <ProgressSpinner label="Calculating exchange rate..." position="right" />
            </Box>
        );

    if (error) {
        return (
            <Box flex="0 1 260px" bg="rgba(0, 0, 0, 0.05)" mr="2px" p={1}>
                <Box>
                    <CurrencyLabel variant="medium">No rate available</CurrencyLabel>
                </Box>
                <Box>
                    <Text as="span" fontSize="12px">
                        for this date
                    </Text>
                </Box>
            </Box>
        );
    }

    


    
    return (
        <>
            <Box flex="0 1 130px" bg="rgba(0, 0, 0, 0.05)" mr="2px" p={1}>
                <Box>
                    <Text as="span" fontSize="12px">{`${targetCurrency} 1.00 equals`}</Text>
                </Box>
                <Box>
                    <CurrencyLabel>{`${sourceCurrency} ${round(
                        inverseExchangeRate
                    )}`}</CurrencyLabel>
                </Box>
            </Box>
            <Box flex="0 1 130px" bg="rgba(0, 0, 0, 0.05)" p={1}>
                <Box>
                    <Text as="span" fontSize="12px">{`${sourceCurrency} 1.00 equals`}</Text>
                </Box>
                <Box>
                    <CurrencyLabel>{`${targetCurrency} ${round(exchangeRate)}`}</CurrencyLabel>
                </Box>
            </Box>
        </>
    );
}