import {isJson} from "./isJson";

/**
 * Checks passed value. If passed value is valid JSON, it will return the valid JSON.
 * If it is not, the value will be put inside a JSON object that can be used by the WYSIWYG.
 * @param value - The value which will be converted or returned base on a JSON check. This value can be passed to a WYSIWYG.
 * @returns a value which can be used in a WYSIWYG.
 */
export const WYSIWYG_dataConversion = (value: any) => {
    // It's possible that old Objectives are simply a string. In order to convert this to a workable WYSIWYG object, we create an empty one.
    if (!value || !value.length) {
        return JSON.stringify({blocks:[{key:"8pt8k",text:"",type:"unstyled",depth:0,inlineStyleRanges:[],entityRanges:[],data:{}}],entityMap:{}});
    }
    // If we have objectives, and they are not a JSON object, put them into an object.
    if (value && !isJson(value)) {
        return JSON.stringify({blocks:[{key:"8pt8k",text:value,type:"unstyled",depth:0,inlineStyleRanges:[],entityRanges:[],data:{}}],entityMap:{}});
    }

    return value;
}