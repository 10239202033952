import React from "react";
import {Checkbox, Label, TextField} from "office-ui-fabric-react";
import Flex from "ibis-design-system/lib/components/Atoms/Flex";

interface IListMergeViewProps {
    label?: string;
    values: string[];
    // If true, the result view is activated. The TextField looks different and the checkbox isn't visible.
    result?: boolean;
}

export const ListMergeView: React.FunctionComponent<IListMergeViewProps> = (props) => {

    // const labelRender = (index: number, text?: string): any => {
    //     if (index > 0 || !text) { return <span />; }
    //     return <Label style={{color: "black"}}>{text}</Label>
    // }

    return (
        <Flex style={{marginBottom: 13}}>
            {!props.result && <Checkbox checked={props.values.length > 0} disabled={props.values.length === 0} styles={{root: {marginTop: 9}}} /> }
            <div style={{width: "100%"}}>
                <Label style={{color: "black"}}>{props.label}</Label>

                {(!props.values || !props.values.length) &&
                    <TextField
                        contentEditable={false}
                        disabled={true}
                        styles={{field: {color: "black"}}}
                        placeholder={props.result ? `No ${props.label} available for merge.` : ""}
                        borderless
                    />
                }

                {props.values.map( (value: string, index: number) => {
                    return <TextField
                        contentEditable={false}
                        disabled={props.result}
                        value={value}
                        styles={{root: {marginTop: index > 0 ? 2 : 0}, field: {color: "black"}}}
                        borderless
                    />
                })}
            </div>
        </Flex>
    );
};
