import {IPersonDto} from "../Api/Dtos/IPersonDto";
import {EPersonRole} from "../Api/Dtos/IAddPersonDto";

export interface IPeopleInRoles {
    RegionalCoordinator: IPersonDto[],
    DepartmentHead: IPersonDto[],
    UnitHead: IPersonDto[],
    TenderLead: IPersonDto[],
    ProjectLead: IPersonDto[],
    OtherContacts: IPersonDto[],
    ProjectTeam: IPersonDto[],
}

export const dividePeopleByRole = (people: IPersonDto[]): IPeopleInRoles => {

    const newPeopleInRoles: IPeopleInRoles = {
        RegionalCoordinator: [],
        DepartmentHead: [],
        UnitHead: [],
        TenderLead: [],
        ProjectLead: [],
        OtherContacts: [],
        ProjectTeam: [],
    }

    people.forEach((person: IPersonDto) => {
        switch (person.role) {
            case EPersonRole.RegionalCoordinator: return newPeopleInRoles.RegionalCoordinator.push(person);
            case EPersonRole.DepartmentHead: return newPeopleInRoles.DepartmentHead.push(person);
            case EPersonRole.UnitHead: return newPeopleInRoles.UnitHead.push(person);
            case EPersonRole.TenderLead: return newPeopleInRoles.TenderLead.push(person);
            case EPersonRole.ProjectLead: return newPeopleInRoles.ProjectLead.push(person);
            case EPersonRole.OtherContacts: return newPeopleInRoles.OtherContacts.push(person);
            case EPersonRole.ProjectTeam: return newPeopleInRoles.ProjectTeam.push(person);
            default: return;
        }
    });

    return newPeopleInRoles;
}