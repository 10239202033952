import React, {useEffect, useMemo, useState} from "react";
import Flex from 'ibis-design-system/lib/components/Atoms/Flex';
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';
import Dropdown from "ibis-design-system/lib/components/inputFields/Dropdown";
import {DeltaresProgram} from "../DeltaresProgram/DeltaresProgram";
import {AddProgram} from "../DeltaresProgram/AddProgram";
import {
    useDataFacilities,
    useDepartments,
    useExperimentalFacilities,
    useKeyTechnologies,
    useKnowledgeNetworks,
    useMoonshots,
    useSoftwareAndModels,
    useUnits
} from "../../Hooks/GlobalConfigContext";
import {DepartmentPicker} from "../DepartmentPicker";
import {request} from "../../Utils/request";
import {
    deleteDeltaresProgram,
    deleteProgramGoal,
    getPrograms,
    updateDeltaresProgram,
    updateProgramGoal
} from "../../Api/TenderRequests";
import {IDeltaresProgramDto} from "../../Api/Dtos/IDeltaresProgramDto";
import {IDeltaresProgramCreateDto} from "../../Api/Dtos/IDeltaresProgramCreateDto";
import {IDeltaresProgramGoalCreateDto} from "../../Api/Dtos/IDeltaresProgramGoalCreateDto";
import {IOperationalGoalDto} from "../../Api/Dtos/IOperationalGoalDto";
import {IMultiselectOption} from "./Connections/AddConnection";
import {IProject} from "../../Api/Dtos/IProjectDto";
import {EPermission} from "../../Api/Dtos/EPermission";
import {hasPermission} from "../../Utils/hasPermission";
import {IUpdateSdgResponse} from "../../Interfaces/IUpdateSdgResponse";
import {PatchParams} from "../../Interfaces/PatchParams";
import {EOP} from "../../Interfaces/IPatchParams";
import { GenericPicker } from "../GenericPicker";
import { IMoonshots } from "../../Api/Dtos/IMoonshots";
import { ITag } from "office-ui-fabric-react";

interface IDropDowns {
    deltaresKnowledgeNetwork: string | null;
    deltaresKnowledgeNetworks: string[] | null;
    dataFacility: string | null;
    dataFacilities: string[] | null;
    softwareAndModel: string | null;
    softwareAndModels: string[] | null;
    experimentalFacility: string | null;
    experimentalFacilities: string[] | null;
    enablingTechnology: string | null;
    enablingTechnologies: string[] | null;
    units: string[] | null;
    moonshots: IMoonshots[] | null;
    moonshot: string | null;
}

interface IDeltaresOrganisationalInformationProps {
    tenderId: string;
    project: IProject;
    permissions: EPermission[];
    patch: PatchParams;
}

export const DeltaresOrganisationalInformation: React.FunctionComponent<IDeltaresOrganisationalInformationProps> = (props) => {
    const keyTechnologies = useKeyTechnologies();
    const softwareAndModels = useSoftwareAndModels();
    const experimentalFacilities = useExperimentalFacilities();
    const dataFacilities = useDataFacilities();
    const knowledgeNetworks = useKnowledgeNetworks();
    const units = useUnits();
    const moonshots = useMoonshots();
    const departments = useDepartments();
    const [programs, setPrograms] = useState<IDeltaresProgramDto[]>();
    const [dropdowns, setDropdowns] = useState<IDropDowns>();

    useEffect(() => {
        if (!props.project || !props.project.id) return;
        fetchPrograms(props.project.id);

        setDropdowns((prevState: any) => ({
            dataFacility: props.project.dataFacility,
            dataFacilities: props.project.dataFacilities,
            deltaresKnowledgeNetwork: props.project.deltaresKnowledgeNetwork,
            deltaresKnowledgeNetworks: props.project.deltaresKnowledgeNetworks,
            softwareAndModel: props.project.softwareAndModel,
            softwareAndModels: props.project.softwareAndModels,
            enablingTechnology: props.project.enablingTechnology,
            enablingTechnologies: props.project.enablingTechnologies,
            experimentalFacility: props.project.experimentalFacility,
            experimentalFacilities: props.project.experimentalFacilities,
            units: props.project.units,
            moonshots: props.project.moonshots,
            moonshot: props.project.moonshot

        }));
    }, [props.project]);

    const selectedUnits = useMemo(() => {
        // If we don't have a department, let's check if we have a unit we can show.
        if (!props.project.departments || !props.project.departments.length) {
            if (props.project.units) {
                return props.project.units.map(unit => units.find(u => unit === u.id))
            }
        }

        // If we do have a department, let's see what the unit for that department is.
        if (props.project.units && props.project.departments) {
            const deps = departments.filter( dep => props.project.departments.find(dp => dep.id === dp));
            return units.filter( u => deps.find(dep => dep.unitId === u.id));
        }

        return undefined;
    }, [props.project]);

    const fetchPrograms = async (projectId: string): Promise<void> => {
        const { result, error } = await request<IDeltaresProgramDto[]>(getPrograms(projectId));

        if (error || !result) {
            console.log("Something went wrong attempting to fetch deltares programs, or no programs were found.");
            return;
        }

        setPrograms(result);
    };

    const deleteProgram = async (programId: string): Promise<void> => {
        if (!props.project || !programs || !programs.length || !programId) return;

        const {result, error} = await request<IDeltaresProgramDto>(deleteDeltaresProgram(props.project.id, programId));

        if (error || !result) {
            console.log(`Something went wrong attempting to delete program with ID: ${programId}`)
            return;
        }

        setPrograms([...programs].filter( pr => pr.id !== programId));
    }

    const updateProgram = async (programId: string, updatedProgram: IDeltaresProgramCreateDto): Promise<void> => {
        if (!props.project ||  !updatedProgram || !programId || !programs || !programs.length) return;

        const {result, error} = await request<IDeltaresProgramDto>(updateDeltaresProgram(props.project.id, programId, updatedProgram));

        if (error || !result) {
            console.log(`Something went wrong attempting to update program with ID: ${programId}`)
            return;
        }

        const tempPrograms = [...programs];
        tempPrograms?.forEach( (pr, index) => {
            if (pr.id === programId) tempPrograms[index] = result;
        });
        setPrograms(tempPrograms);
    }

    const addOperationalGoal = (programId: string, newGoal: IOperationalGoalDto): void => {
        if (!programId || !newGoal || !programs || !programs.length) return;
        setPrograms([...programs].map(program => {
            if (program.id !== programId) return program;
            if (!program.operationalGoals) program.operationalGoals = [];
            program.operationalGoals.push(newGoal);
            return program;
        }))
    }

    const deleteOperationalGoal = async (programId: string, goalId: string): Promise<void> => {
        if (!props.project || !programs || !programs.length || !programId || !goalId) return;

        const {result, error} = await request<IOperationalGoalDto>(deleteProgramGoal(props.project.id, goalId));

        if (error || !result) {
            console.log(`Something went wrong attempting to delete program with ID: ${programId}`)
            return;
        }

        const newPrograms = programs.map( program => {
            if (program.id !== programId) return program;
            program.operationalGoals = program.operationalGoals?.filter( og => og.id !== goalId);
            return program;
        })

        setPrograms(newPrograms);
    }

    const updateOperationalGoal = async (programId: string, goalId: string, updatedGoal: IDeltaresProgramGoalCreateDto): Promise<void> => {
        if (!props.project || !updatedGoal || !goalId || !programs || !programs.length) return;

        const {result, error} = await request<IOperationalGoalDto>(updateProgramGoal(props.project.id, goalId, updatedGoal));

        if (error || !result) {
            console.log(`Something went wrong attempting to update goal with ID: ${goalId}`)
            return;
        }

        const newPrograms = programs.map( program => {
            if (program.id !== programId) return program;
            program.operationalGoals?.map( og => {
               if (og.id === goalId) return result;
               return og;
            });
            return program;
        });
        setPrograms(newPrograms);
    }

    return (
        <Box>
            <Flex>
                <Box width="261px">
                    <Dropdown
                        label="Unit"
                        placeholder="Select a unit"
                        options={units} 
                        selected={dropdowns && dropdowns.units ? dropdowns.units : undefined}
                        onChanged={(value: IMultiselectOption) => {
                            props.patch([
                                {
                                    op: value.selected ? EOP.Add : EOP.Remove,
                                    value: value.key,
                                    path: value.selected ? '/linkedProject/units/-' : `/linkedProject/units/${value.key}`
                                }
                            ], props.project.linkedTender)}}
                    />
                </Box>

                <Box ml={3} maxWidth="100%">
                    {/*<Dropdown label="Department" placeholder="Select a department" options={globalConfig.departments} />*/}
                    <DepartmentPicker
                        label="Department"
                        onChange={(changed: IUpdateSdgResponse | undefined) => changed ? props.patch([changed], props.project.linkedTender) : undefined}
                        selectedDepartments={props.project.departments ? props.project.departments : []}
                        field="linkedProject/departments"
                    />
                </Box>
            </Flex>

            <Box mt={3}>
                <Dropdown
                    label="Deltares moonshots"
                    placeholder="Select a moonshot"
                    multiSelect={true}
                    options={moonshots}
                    selected={dropdowns && dropdowns?.moonshots?.map((x:any) => x.moonshotId) ? dropdowns?.moonshots?.map((x:any) => x.moonshotId) : undefined}
                    onChanged={(value: IMultiselectOption) => {
                        props.patch([
                            {
                                op: value.selected ? EOP.Add : EOP.Remove,
                                projectId: props.project.id,
                                value: value.key,
                                path: value.selected ? 'linkedProject/moonshots/-' : `linkedProject/moonshots/${value.key}`
                            }
                        ], props.project.linkedTender)
                    }}
                    disabled={!hasPermission(props.permissions)}
                />
            </Box>

            <Box marginTop="30px">
                { programs &&
                    programs.map( program => {
                        return (
                            <div key={program.id} style={{marginTop: 1}}>
                                <DeltaresProgram
                                    tenderId={props.tenderId}
                                    permissions={props.permissions}
                                    program={program}
                                    deleteProgram={deleteProgram}
                                    updateProgram={updateProgram}
                                    deleteOperationalGoal={deleteOperationalGoal}
                                    updateOperationalGoal={updateOperationalGoal}
                                    addOperationalGoal={addOperationalGoal}
                                />
                            </div>
                        )
                    })
                }
                {props.project && hasPermission(props.permissions) ? <AddProgram projectId={props.project.id} style={{marginTop: 15}} addProgramme={(newProgram) => setPrograms(programs ? [...programs, newProgram] : [newProgram])} /> : null}
            </Box>

            {/*
                TODO This whole dropdown section is going to be replaced with PILL inputs.
                The suggestions for these pill inputs will be delivered by Bert and placed in the Settings application.
                Kanban tickets for these lists: 291, 292, 293, 294, 295
             */}
            <Box width="421px" mt={3}>
                <Dropdown
                    label="Deltares key technologies used in project"
                    placeholder="Select an Deltares key technology"
                    multiSelect={true}
                    options={keyTechnologies}
                    selected={dropdowns && dropdowns.enablingTechnologies ? dropdowns.enablingTechnologies : undefined}
                    onChanged={(value: IMultiselectOption) => {
                        props.patch([
                            {
                                op: value.selected ? EOP.Add : EOP.Remove,
                                value: value.key,
                                path: value.selected ? '/linkedProject/enablingTechnologies/-' : `/linkedProject/enablingTechnologies/${value.key}`
                            }
                        ], props.project.linkedTender)
                    }}
                    disabled={!hasPermission(props.permissions)}
                />
            </Box>

            <Box width="421px" mt={3}>
                <Dropdown
                    label="Deltares software and models used in project"
                    placeholder="Select an Deltares software product or model"
                    multiSelect={true}
                    options={softwareAndModels}
                    selected={dropdowns && dropdowns.softwareAndModels ? dropdowns.softwareAndModels : undefined}
                    onChanged={(value: IMultiselectOption) => {
                        props.patch([
                            {
                                op: value.selected ? EOP.Add : EOP.Remove,
                                value: value.key,
                                path: value.selected ? '/linkedProject/softwareAndModels/-' : `/linkedProject/softwareAndModels/${value.key}`
                            }
                        ], props.project.linkedTender)
                    }}
                    disabled={!hasPermission(props.permissions)}
                />
            </Box>
            <Box width="421px" mt={3}>
                <Dropdown
                    label="Deltares experimental facilities used in project"
                    placeholder="Select an Deltares experimental facility"
                    multiSelect={true}
                    options={experimentalFacilities}
                    selected={dropdowns && dropdowns.experimentalFacilities ? dropdowns.experimentalFacilities : undefined}
                    onChanged={(value: IMultiselectOption) => {
                        props.patch([
                            {
                                op: value.selected ? EOP.Add : EOP.Remove,
                                value: value.key,
                                path: value.selected ? '/linkedProject/experimentalFacilities/-' : `/linkedProject/experimentalFacilities/${value.key}`
                            }
                        ], props.project.linkedTender)
                    }}
                    disabled={!hasPermission(props.permissions)}
                />
            </Box>

            <Box width="421px" mt={3}>
                <Dropdown
                    label="Deltares data facilities used in project"
                    placeholder="Select an Deltares experimental facility"
                    multiSelect={true}
                    options={dataFacilities}
                    selected={dropdowns && dropdowns.dataFacilities ? dropdowns.dataFacilities : undefined}
                    onChanged={(value: IMultiselectOption) => {
                        props.patch([
                            {
                                op: value.selected ? EOP.Add : EOP.Remove,
                                value: value.key,
                                path: value.selected ? '/linkedProject/dataFacilities/-' : `/linkedProject/dataFacilities/${value.key}`
                            }
                        ], props.project.linkedTender)
                    }}
                    disabled={!hasPermission(props.permissions)}
                />
            </Box>

            <Box width="421px" mt={3}>
                <Dropdown
                    label="Deltares knowledge networks used in project"
                    placeholder="Select an Deltares knowledge network"
                    multiSelect={true}
                    options={knowledgeNetworks}
                    selected={dropdowns && dropdowns.deltaresKnowledgeNetworks ? dropdowns.deltaresKnowledgeNetworks : undefined}
                    onChanged={(value: IMultiselectOption) => {
                        props.patch([
                            {
                                op: value.selected ? EOP.Add : EOP.Remove,
                                value: value.key,
                                path: value.selected ? '/linkedProject/deltaresKnowledgeNetworks/-' : `/linkedProject/deltaresKnowledgeNetworks/${value.key}`
                            }
                        ], props.project.linkedTender)
                    }}
                    disabled={!hasPermission(props.permissions)}
                />
            </Box>
        </Box>
    );
};
