import React, {useEffect, useState} from "react";
import styled from 'ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components';
import Text from 'ibis-design-system/lib/components/Atoms/Text';
import Icon from 'ibis-design-system/lib/components/core/Icon';
import {IFoundation} from "../../Interfaces/IFoundation";
import {ExportCard} from "./ExportCard";
import {downloadJson} from "../../Api/ProjectRequests";
import {useNavigationState} from "ibis-design-system/lib/Providers/NavigationProvider";
import {useTemplateDownloadOptions} from "../../Hooks/GlobalConfigContext";


interface IExportMenuProps {
    exportOptionsUrl: string;
    isOpen?: boolean;
    onClose?: () => void;
}

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 11px;
`

const Menu = styled.div`
  display: ${(props: any) => props.isOpen ? 'block' : 'none'};
  position: absolute;
  top: 0;
  overflow-x: scroll;
  right: 0;
  width: 340px;
  height: 98%;
  border: 1px solid #00000026;
  z-index: 2;
  background-color: ${(props: IFoundation) => props.theme.colors.neutralLight};
  padding: 0 5px 15px 0;
`;

export const ExportJson: React.FunctionComponent<IExportMenuProps> = (props) => {

    const { entities, selection } = useNavigationState();
    const templateDownloadOptions = useTemplateDownloadOptions();

    useEffect(() => {
        if (!props.exportOptionsUrl) return;
    }, [props.exportOptionsUrl]);

    const downloadProject = async (templateId: string, templateName: string): Promise<void> => {
        if (!selection || !selection.length ||  !entities) return;
        for (const selected of selection) {
            downloadJson(entities[selected].id, '');
        }
    }

    return (
        <Menu isOpen={props.isOpen}>
            <Top>
                <Text fontWeight={'bold'} style={{fontSize: 21}}>Export to Word</Text>
                <Icon style={{cursor: "pointer"}} name="StatusCircleErrorX" size={'l'} onClick={props.onClose} />
            </Top>

        </Menu>
    );
};
