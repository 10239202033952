import {followProject, followTender} from "../Api/TenderRequests";

export const startFollowing = (selection: any, entities: any, updateEntities: any, projects?: boolean) => {
    const itemsToFollow = [];
    for (let i = 0; i < selection.length; i++) {
        const item = { ...entities[selection[i]] };
        if (!item) continue;
        if (projects && item.linkedProject) {
            followProject(item.linkedProject.id, item.isFollowing);
        } else {
            followTender(item.id, item.isFollowing);
        }
        item.isFollowing = !item.isFollowing;
        itemsToFollow.push(item)
    }

    updateEntities(itemsToFollow);
    return itemsToFollow;
}