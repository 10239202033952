import React, {useEffect, useState} from "react";
import WatchAbleScrollContainer from "ibis-design-system/lib/components/watchAbleComponents/WatchAbleScrollContainer";
import Flex from "ibis-design-system/lib/components/Atoms/Flex";
import Editview from "ibis-design-system/lib/components/core/Editview";
import {ProjectGeneral} from "../Components/ProjectTab/ProjectGeneral";
import {MergeModal} from "../Components/ProjectMergeTools/MergeModal";
import {checkProjectMergeStatus} from "../Api/TenderRequests";
import {ITenderOverviewDto} from "../Api/Dtos/ITenderOverviewDto";
import {IPatchParams} from "../Interfaces/IPatchParams";
import {isProject} from "../Utils/isProject";
import {DeltaresOrganisationalInformation} from "../Components/ProjectTab/DeltaresOrganisationalInformation";
import {Connections} from "../Components/ProjectTab/Connections/Connections";
import Location from "../Components/Location";
import DefaultDetailview from 'ibis-design-system/lib/components/core/DefaultDetailview/DefaultDetailview';
import { AddProgress } from "../Components/TenderTab/AddProgress";
import Box from 'ibis-design-system/lib/components/Atoms/Box/Box';

interface IProjectProps {
    item: ITenderOverviewDto;
    patch: (params: IPatchParams<any, any>[], id?: string, message?: any, entityName?: any) => void;
}

export const Project: React.FunctionComponent<IProjectProps> = (props) => {

    const tenderId = isProject() ? props.item.linkedTender as string : props.item.id
    const [showMergeBanner, setShowMergeBanner] = useState<boolean>(false);
    const [maconomyId, setMaconomyId] = useState<string>();

    useEffect(() => {
        if (!props.item || !props.item.linkedProject) return;
        checkProjectMergeStatus(props.item.linkedProject.maconomyId).then( result => {
            if (result && result.available && result.maconomyId) {
                setShowMergeBanner(true);
                setMaconomyId(result.maconomyId);
            }
        }).catch( e => console.log(e));
    }, [props.item.id]);

    const subjects = [
        {
            id: 'general',
            name: 'General',
            component: <ProjectGeneral permissions={props.item.userContext.permissions} tenderId={tenderId} project={props.item.linkedProject} patch={props.patch} />,
            showHeader: false,
        },
        {
            id: 'deltaresOrganisationalInformation',
            name: 'Deltares organisational information',
            component: <DeltaresOrganisationalInformation permissions={props.item.userContext.permissions} tenderId={props.item.id} project={props.item.linkedProject} patch={props.patch} />,
            showHeader: true,
        },
        {
            id: 'location',
            name: 'Location',
            component: <Location permissions={props.item.userContext.permissions} tender={props.item} tenderId={isProject() ? props.item.linkedTender as string : props.item.id} patch={props.patch} variant="country" />,
            showHeader: true,
        },
        {
            id: 'connections',
            name: 'Connections',
            component: <Connections permissions={props.item.userContext.permissions} tenderId={isProject() ? props.item.linkedTender as string : props.item.id} project={props.item.linkedProject} style={{paddingBottom: 150}} />,
            showHeader: true,
        },
    ];

    const subjectsProject = [
        {
            id: 'general',
            name: 'General',
            component: <ProjectGeneral permissions={props.item.userContext.permissions} tenderId={tenderId} project={props.item.linkedProject} patch={props.patch} />,
            showHeader: false,
        },
        {
            id: 'deltaresOrganisationalInformation',
            name: 'Deltares organisational information',
            component: <DeltaresOrganisationalInformation permissions={props.item.userContext.permissions} tenderId={props.item.id} project={props.item.linkedProject} patch={props.patch} />,
            showHeader: true,
        },

        {
            id: 'progress',
            name: 'Progress',
            component: <AddProgress permissions={props.item.userContext.permissions} tenderId={isProject() ? props.item.linkedTender as string : props.item.id} item={props.item} />,
            showHeader: true,
        },
        {
            id: 'location',
            name: 'Location',
            component: <Location permissions={props.item.userContext.permissions}  tender={props.item} tenderId={isProject() ? props.item.linkedTender as string : props.item.id} patch={props.patch} variant="dpp" />,
            showHeader: true,
        },
        {
            id: 'connections',
            name: 'Connections',
            component: <Connections permissions={props.item.userContext.permissions} tenderId={isProject() ? props.item.linkedTender as string : props.item.id} project={props.item.linkedProject} style={{paddingBottom: 150}} />,
            showHeader: true,
        },
    ];
    const subjectsProjects = isProject() ? subjectsProject : subjects;
    if (!props.item || !props.item.linkedProject) {
        if(props.item.secret){
            return <Box flex="1 1 auto" p={3} bg="light" ><DefaultDetailview title="You do not have permission" icon="LockSolid"><p></p></DefaultDetailview></Box>
        }
        else{
        return <div>loading...</div>
        }
    }

    return (
        <div style={{width: "100%"}}>
            <MergeModal tenderId={props.item.id} maconomyId={maconomyId} showMergeBanner={showMergeBanner} onCancelMergeBanner={() => setShowMergeBanner(false)} applyMerge={() => console.log("Applying merge")} />
            <WatchAbleScrollContainer>
            {/* this calculates the max width of the editfield by deducting the width of the navigation (200px) and padding (60px) */}
            <Flex width="calc(100% - 260px)" pl={3}>
                    <Editview subjects={subjectsProjects} />
                </Flex>
            </WatchAbleScrollContainer>
        </div>
    );
};
