import {
    DownloadFileWithPost,
    DownloadFileWithGet,
    DownloadFileWithPostZip,
    HttpGet,
    HttpPost,
} from "ibis-design-system/lib/HelperFunctions/HttpHelper";
import Settings from './../Settings';
import axios from 'axios';

export async function getDeltaresUserDetails(deltaresId: string, cancelToken: any, cache: any) {
    const url = `${Settings.apiUrls.singleSignOn}/users/${deltaresId}/details`;

    if (cache?.[url]) return cache[url];
    else if (cache) {
        cache[url] = await getUserDetails(url, cancelToken, cache);
        return cache[url];
    } else getUserDetails(url, cancelToken);
}

function getUserDetails(url: string, cancelToken: any, cache: any = null) {
    return HttpGet(url, cancelToken)
        .then((response: any) => {
            if (cache) cache[url] = response;
            return response;
        })
        .catch((error: any) => {
            switch (error.code) {
                case 499:
                    // Canceled request
                    break;
                case 400:
                    sendLog(JSON.stringify(error), `Bad request when fetching "${url}"`);
                    break;
                case 500:
                    sendLog(
                        JSON.stringify(error),
                        `Internal server error while retrieving "${url}"`
                    );
                    break;
                case 408:
                case 502:
                case 503:
                case 504:
                    sendLog(JSON.stringify(error), `Connection timeout while fetching "${url}"`);
                    break;
                default:
                    sendLog(
                        JSON.stringify(error),
                        `An unknown error occurred while fetching "${url}"`
                    );
                    break;
            }
        });
}

export async function sendLog(error: any, additionalInfo: any, userId?: any) {
    HttpPost(`${Settings.apiUrls.logService}/log/client-error`, {
        applicationId: Settings.appId,
        error: JSON.stringify(error),
        errorInfo: additionalInfo,
        url: window.location.href,
        userId: userId,
    }).catch((error: any) => Promise.resolve());
}
export const downloadTemplate = async (projectId: string, templateName: string, name:string): Promise<any> => {
    return await DownloadFileWithPost(`${Settings.apiUrls.tenders}/${projectId}/download/${templateName}/${2}`, null, name +", " + `${templateName}`)
}

export const downloadJson = async (projectIds: string[], projectTitle: string): Promise<any> => {
    return await DownloadFileWithPost(`${Settings.apiUrls.tenders}/download/json`, projectIds, projectTitle + 'projects.zip')
}

export const downloadDocumentZip = async (projectIds: string[], templateName: string): Promise<any> => {
    return await DownloadFileWithPostZip(`${Settings.apiUrls.tenders}/download/${templateName}/${2}?zip=true`, projectIds, templateName + ".zip")
}

export const downloadMultipleProjectInOneFile = async (projectIds: string[], templateName: string): Promise<any> => {
    return await DownloadFileWithPost(`${Settings.apiUrls.tenders}/download/${templateName}/${2}?zip=false`, projectIds, templateName + ".docx")
}

// export const transferOwnerShip = async (projectId: string, userId: string): Promise<any> => {
//     return await DownloadFileWithPostZip(`${Settings.apiUrls.tenders}/projects/${projectId}/owner`)
// }

export const isAdmin = async () => {
    return HttpGet(`${Settings.apiUrls.globalConfig}/permissions/access`);
}



