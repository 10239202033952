import React, {useEffect, useMemo, useState} from "react";
import {Icon, Modal} from "office-ui-fabric-react";
import styles from "./mergeStyles.module.css";
import Button from "ibis-design-system/lib/components/core/Button";
import Flex from "ibis-design-system/lib/components/Atoms/Flex";
import {DataBlock, EMergeProjectSource} from "./DataBlock";
import {SelectedValues} from "./SelectedValues";
import {IMergeProjectDto, IMergeProjectsDto} from "../../Api/Dtos/IMergeProjectsDto";
import {IKeywordDto} from "../../Api/Dtos/IKeywordDto";
import {IUnitDto} from "../../Api/Dtos/IUnitDto";
import {IDepartmentDto} from "../../Api/Dtos/IDepartmentDto";
import styled from "ibis-design-system/lib/HelperFunctions/ThirdParty/styled-components";
import {IFoundation} from "../../Interfaces/IFoundation";
import {SetShowMergeBanner} from "./MergeAvailableBanner";
import {applyProjectMerge, getProjectsForMerge} from "../../Api/TenderRequests";
import {IProject} from "../../Api/Dtos/IProjectDto";
import {request} from "../../Utils/request";
import {selectedEntity} from "../../Utils/selectedEntity";

const MergeView = styled.div`
  margin-left: 25px;
  margin-right: 40px;
  margin-top: 19px;
`

const MergeTitle = styled.div`
  font-size: 21px;
  font-weight: ${(props: IFoundation) => props.theme.fontWeights.bold};
  color: ${(props: IFoundation) => props.theme.colors.neutralPrimary};
  margin-bottom: 45px;
  margin-left: 15px;
`

interface IMergeModalProps {
    maconomyId?: string;
    showMergeBanner: boolean;
    applyMerge: () => void;
    onCancelMergeBanner: () => void;
    tenderId: string;
}

export const MergeModal: React.FunctionComponent<IMergeModalProps> = (props) => {

    const { update } = selectedEntity();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedValues, setSelectedValues] = useState<IMergeProjectDto>(); // Should be in order of availability: dtpds / lois / maconomy
    const [allMerged, setAllMerged] = useState<IMergeProjectsDto>();

    useEffect(() => {
        if (!props.maconomyId) return;

        getProjectsForMerge(props.maconomyId).then( result => {
            const tmpAllMerged: IMergeProjectsDto = {dtpds: undefined, lois: undefined, maconomy: undefined};
            result.forEach( (proj: any) => {
                if (proj.projectSource === 1) tmpAllMerged.dtpds = proj;
                if (proj.projectSource === 2) tmpAllMerged.lois = proj;
                if (proj.projectSource === 3) tmpAllMerged.maconomy = proj;
            });

            if (tmpAllMerged.dtpds !== undefined) { setSelectedValues(tmpAllMerged.dtpds) }
            else if (tmpAllMerged.lois !== undefined) { setSelectedValues(tmpAllMerged.lois) }
            else if (tmpAllMerged.maconomy !== undefined) { setSelectedValues(tmpAllMerged.maconomy) }

            setAllMerged(tmpAllMerged);
        });
    }, [props.maconomyId]);

    const mergeListsAndRemoveDuplicates = (obj: any, all: any): any[] => {
        const listWithEverything = [...all, ...obj];
        // @ts-ignore;
        return [...new Map(listWithEverything.map(v => [v.id, v])).values()];
    };

    useEffect(() => {
        if (!allMerged) return;
        // Let's combine all of the available keywords.
        let keywords: IKeywordDto[] = [];
        if (allMerged.dtpds && allMerged.dtpds.keywords) { keywords = mergeListsAndRemoveDuplicates(allMerged.dtpds.keywords, keywords); }
        if (allMerged.lois && allMerged.lois.keywords) { keywords = mergeListsAndRemoveDuplicates(allMerged.lois.keywords, keywords); }
        if (allMerged.maconomy && allMerged.maconomy.keywords) { keywords = mergeListsAndRemoveDuplicates(allMerged.maconomy.keywords, keywords); }

        // Let's combine all of the available units.
        let units: IUnitDto[] = [];
        if (allMerged.dtpds && allMerged.dtpds.units) { units = mergeListsAndRemoveDuplicates(allMerged.dtpds.units, units); }
        if (allMerged.lois && allMerged.lois.units) { units = mergeListsAndRemoveDuplicates(allMerged.lois.units, units); }
        if (allMerged.maconomy && allMerged.maconomy.units) { units = mergeListsAndRemoveDuplicates(allMerged.maconomy.units, units); }

        // Let's combine all of the available departments.
        let departments: IDepartmentDto[] = [];
        if (allMerged.dtpds && allMerged.dtpds.departments) { departments = mergeListsAndRemoveDuplicates(allMerged.dtpds.departments, departments); }
        if (allMerged.lois && allMerged.lois.departments) { departments = mergeListsAndRemoveDuplicates(allMerged.lois.departments, departments); }
        if (allMerged.maconomy && allMerged.maconomy.departments) { departments = mergeListsAndRemoveDuplicates(allMerged.maconomy.departments, departments); }

        // Now let's set all of the combined values to the state.
        const tmpSelectedValues = {...selectedValues};
        tmpSelectedValues.keywords = keywords;
        tmpSelectedValues.units = units;
        tmpSelectedValues.departments = departments;
        setSelectedValues(tmpSelectedValues as any);
    }, [allMerged]);

    const clientAvailable = useMemo(() => {
        let available = false;
        if (!allMerged) return available;
        Object.keys(allMerged).forEach( (key: string) => {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            allMerged[key]?.client !== null && allMerged[key]?.client !== undefined ? available = true : undefined
        });
        return available;
    }, [allMerged]);

    const longestProjectTitle = useMemo(() => {
        if (!allMerged) return "";
        let longestTitle = "";
        Object.keys(allMerged).forEach( (key: string) => {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            const title = allMerged[key]?.projectTitle;
            if (title && title.length > longestTitle.length) longestTitle = title;
        });
        return longestTitle;
    }, [allMerged]);

    const modalStyles = useMemo(() => {
        return {main: {height: "100vh",width: "100vw", margin: 0, padding: 0,  maxHeight: "100vh", maxWidth: "100vw", border: "none", borderRadius: 0}}
    }, []);

    const updateSelectedValue = (source: EMergeProjectSource, key: keyof IMergeProjectDto): void => {
        if (!source || !key) { return; }

        const tmpSelectedValues = {...selectedValues};
        const tmpAllMerged = {...allMerged};

        if (!tmpAllMerged[source]) return;

        tmpSelectedValues[key] = (tmpAllMerged[source] as any)[key] as any;
        setSelectedValues(tmpSelectedValues as any);
    };

    const applyMerge = async (): Promise<void> => {
        const { result, error } = await request<IProject>(applyProjectMerge(props.tenderId, selectedValues));

        if (error || !result) {
            console.log("Something went wrong attempting to merge projects.");
            return;
        }

        update((item: any) => {
            const tmpItem = {...item};
           tmpItem.linkedProject = {...tmpItem.linkedProject, ...result};
           return tmpItem;
        });
        setIsOpen(false);
    }

    return (
        <div style={{width: "100%"}}>
            {props.showMergeBanner && <SetShowMergeBanner onShowMerge={() => setIsOpen(true)} onCancel={props.onCancelMergeBanner} /> }
            <Modal
                titleAriaId={"Project merge window"}
                isOpen={isOpen}
                onDismiss={() => setIsOpen(false)}
                isBlocking={false}
                styles={modalStyles}
                dragOptions={undefined}
            >
                <MergeView>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <MergeTitle>
                            Merging project data
                        </MergeTitle>
                        <Icon iconName="ChromeClose" onClick={() => setIsOpen(false)} style={{cursor: "pointer", marginTop: 10}}/>
                    </div>
                    <div style={{paddingBottom: 100, overflowX: "scroll", display: "flex"}}>
                        { selectedValues && <SelectedValues selectedValues={selectedValues} clientAvailable={clientAvailable} longestProjectTitle={longestProjectTitle} /> }
                        <Flex style={{border: "2px solid #00000026", overflowX: "scroll"}}>
                            {Object.keys(EMergeProjectSource).map((key: any, index: number) => {
                                // @ts-ignore
                                const source = EMergeProjectSource[key];
                                if (!allMerged || !selectedValues || !allMerged[source as keyof IMergeProjectsDto]) return null;
                                return <DataBlock
                                    background={index % 2 !== 0 ? "light" : "dark"}
                                    source={source}
                                    setSelectedValue={(key: keyof IMergeProjectDto) => updateSelectedValue(source, key)}
                                    mergeProject={allMerged[source as keyof IMergeProjectsDto] as any}
                                    selectedMergeProjectValues={selectedValues}
                                    longestProjectTitle={longestProjectTitle}
                                />
                            })}
                        </Flex>
                    </div>
                </MergeView>

                <div className={styles.footer}>
                    <div style={{marginLeft: 40}}>
                        <Button variant="primary" onClick={() => applyMerge()}>Apply merge</Button>
                        <span className={styles.buttonMargin}><Button onClick={() => setIsOpen(false)}>Cancel</Button></span>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
